<template>
  <div class="dropdown button-dropdown">
    <input
      v-model="inputSelection"
      type="text"
      autocomplete="off"
      :placeholder="placeholder"
      :minlength="minlength"
      :maxlength="maxlength"
      :tabindex="tabindex"
      @input="onChange"
      @focus="onFocus"
      @keydown.down="onDown"
      @keydown.up="onUp"
      @keydown.enter="onEnter"
    />
    <ul class="dropdown-menu" :class="{ 'dropdown-show': showDropdown }">
      <li
        v-for="(item, index) in matches"
        :key="index"
        @click="onSuggestionClick(item)"
      >
        <span v-if="item.type === 'header'" class="dropdown-header">
          {{ item.value }}
        </span>
        <a
          v-else-if="item.type === 'item'"
          class="dropdown-item"
          :class="{ 'dropdown-item-active': isActive(index) }"
          href="#"
        >
          {{ item.value }}
        </a>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      // For v-model support
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    tabindex: {
      type: String,
      default: null,
    },
    inputSuggestions: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      canShowDropdown: false,
      inputSelection: null,
      currentSelectionIndex: 0,
    };
  },
  computed: {
    matches() {
      // Find the currentSelectionIndex matches based on the input text. Headers always show if they
      // have content below them. Empty input will show full list.

      let filterItems = this.inputSuggestions.filter((item) => {
        if (this.inputSelection && item.type === "item") {
          let value = item.value;
          return value && value.startsWith(this.inputSelection);
        }

        // If input selection is empty, or other item types just show them (headers and such)
        return true;
      });

      // Filter out any headers that have no content after them
      let length = filterItems.length;
      return filterItems.filter((item, index) => {
        if (item.type === "header") {
          if (index >= length - 1) {
            // Header at the end of the array, nothing after
            return false;
          } else if (filterItems[index + 1].type === "header") {
            // Header with another header following, indicating no real items
            return false;
          }
        }

        return true;
      });
    },
    showDropdown() {
      return this.canShowDropdown === true && this.matches.length != 0;
    },
  },
  methods: {
    onFocus() {
      this.canShowDropdown = true;
      this.currentSelectionIndex = this.getNextCurrentSelectionIndex(-1);
    },
    onChange(event) {
      if (this.canShowDropdown == false) {
        this.canShowDropdown = true;
      }

      this.currentSelectionIndex = this.getNextCurrentSelectionIndex(-1);
      this.inputSelection = event.target.value;
      this.$emit("input", this.inputSelection); // v-model support
    },
    onSuggestionClick(item) {
      if (item.type === "item") {
        // Only allow items to be selected
        this.inputSelection = item.value;
        this.$emit("input", this.inputSelection); // v-model support
        this.canShowDropdown = false;
      }
    },
    isActive(index) {
      return index === this.currentSelectionIndex;
    },
    getNextCurrentSelectionIndex(index) {
      let newIndex = index + 1;
      let matchesLength = this.matches.length;

      if (newIndex < 0) {
        newIndex = 0;
      }

      while (
        newIndex < matchesLength &&
        this.matches[newIndex].type === "header"
      ) {
        newIndex++;
      }

      if (newIndex < matchesLength) {
        return newIndex;
      } else {
        return index;
      }
    },
    getPrevCurrentSelectionIndex(index) {
      let newIndex = index - 1;
      let matchesLength = this.matches.length;

      // Make sure we aren't starting out of bounds
      if (newIndex >= matchesLength) {
        newIndex = matchesLength - 1;
      }

      // Search for the previous item that isn't a header (if available)
      while (newIndex >= 0 && this.matches[newIndex].type === "header") {
        newIndex--;
      }

      if (newIndex >= 0) {
        return newIndex;
      } else {
        return index;
      }
    },
    onUp() {
      this.currentSelectionIndex = this.getPrevCurrentSelectionIndex(
        this.currentSelectionIndex
      );
    },
    onDown() {
      this.currentSelectionIndex = this.getNextCurrentSelectionIndex(
        this.currentSelectionIndex
      );
    },
    onEnter() {
      this.inputSelection = this.matches[this.currentSelectionIndex].value;
      this.$emit("input", this.inputSelection); // v-model support
      this.canShowDropdown = false;
    },
  },
};
</script>
<style lang="scss">
.button-dropdown {
  & .dropdown-menu {
    width: 100%;
  }

  & .dropdown-show {
    display: inline-block;
  }

  & .dropdown-item-active {
    font-weight: bold;
  }
}
</style>
