<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :action-buttons="actionButtons"
      :navigation-buttons-right="navigationButtonsRight"
      :show-last-viewed="false"
      @view-clinic-list-event="navViewClinicList"
      @view-patients-clinic-button-event="navViewPatientClinicList"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    >
    </sub-header>

    <div class="container-fluid main-section clinic-container">
      <div>
        <div
          v-if="clinicResult === null"
          id="clinic-loading"
          class="data-loading-container"
        >
          <span>{{ $t("common.loading") }}</span>
        </div>
        <div v-else-if="clinic" id="clinic-detail-list">
          <clinic-list-table
            :clinics="[clinic]"
            row-click-nav="patient-list"
            :show-row-state="false"
            :sortable="false"
          >
          </clinic-list-table>
        </div>
        <div v-else id="clinic-list-error" class="data-empty-container">
          <span>{{ $t("clinic.errorLoadClinic") }}</span>
        </div>
      </div>
      <div v-if="clinic" class="clinic-data-container">
        <div class="left-container">
          <div class="clinic-status-container">
            <header-container
              :header-text="$t('clinic.detailsHeaderMonitoringStatus')"
            />
            <div id="clinic-active-buttons" class="buttons-container">
              <button
                v-if="isReportsSupported"
                id="clinic-report"
                type="button"
                @click.prevent="downloadClinicReport"
              >
                {{ $t("reports.downloadClinicSummary") }}
              </button>
              <button
                v-if="isReportsSupported"
                id="clinic-patients-report"
                type="button"
                @click.prevent="downloadClinicPatientsReport"
              >
                {{ $t("reports.downloadPatient") }}
              </button>
            </div>
          </div>
          <div class="clinic-information-container">
            <header-container
              :header-text="$t('clinic.detailsHeaderClinicInformation')"
              :button-text="$t('common.buttonEdit')"
              @buttonClick="showEditClinic"
            />
            <div class="clinic-info-container">
              <info-item
                v-for="(item, index) in clinicInfoItems"
                :key="index"
                :item="item"
              >
                <template v-if="item.isHtml || item.isLink" #value>
                  <span v-if="item.isLink">
                    <router-link :to="item.valueLink">
                      {{ item.value }}
                    </router-link>
                  </span>
                  <div v-else>
                    <a
                      :href="clinic.portalurl"
                      target="_blank"
                      rel="noopener noreferrer"
                      >{{ $t("common.link") }}</a
                    >
                    <b-button
                      variant="link"
                      class="button-link ml-2"
                      @click.prevent="copyToClipboard(clinic.portalurl)"
                    >
                      <b-icon-clipboard />
                    </b-button>
                  </div>
                </template>
              </info-item>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="clinic-users-container">
            <header-container
              data-testid="clinic-users-header"
              :header-text="$t('clinic.detailsHeaderClinicalUsers')"
              :button-text="$t('common.buttonAddNewUserPlus')"
              @buttonClick="showAddClinicUser"
            />
            <div
              v-if="clinicUsersResult === null"
              id="clinic-users-loading"
              class="data-loading-container"
            >
              <span>{{ $t("clinicalUser.loading") }}</span>
            </div>
            <div v-else>
              <clinic-user-list-table
                :clinic-id="clinicIdParameter"
                :clinic-users="clinicUsers ? clinicUsers : []"
                :show-inactive="false"
                :sortable="false"
                :max-rows="5"
                @refresh-list="getClinicUsers"
                @row-clicked="showEditClinicUser"
              >
              </clinic-user-list-table>
              <button
                type="button"
                class="button-link"
                @click.prevent="navViewClinicUsersList"
              >
                {{ formattedClinicUsersButton }}
              </button>
            </div>
          </div>

          <div class="clinic-gropus-container">
            <header-container
              :header-text="'User Groups'"
              :button-text="'+ Add New User Group'"
              @buttonClick="showAddUserGroup"
            />
            <user-group-list
              :key="userGroupListKey"
              :clinic-users="clinicUsers"
              hide-header
            />
          </div>

          <div class="clinic-placement-locations-container">
            <header-container
              :header-text="$t('clinic.detailsHeaderAllPlacementLocations')"
              :button-text="$t('common.buttonAddNewLocationPlus')"
              @buttonClick="showAddPatchLocation"
            />
            <div
              v-if="clinicResult === null"
              id="clinic-users-loading"
              class="data-loading-container"
            >
              <span>{{ $t("patchLocation.loading") }}</span>
            </div>
            <div v-else>
              <patch-location-list-table
                :clinic="clinic ? clinic : {}"
                :show-inactive="false"
                :sortable="false"
                :max-rows="5"
                @refresh-list="getClinic"
              >
              </patch-location-list-table>
              <button
                type="button"
                class="button-link"
                @click.prevent="navViewPatchLocationsList"
              >
                {{ formattedPatchLocationsButton }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="clinic">
      <add-edit-clinic-modal
        modal-id="edit-clinic-modal"
        :clinic="clinic"
        @updated="getClinic"
      >
      </add-edit-clinic-modal>

      <add-edit-clinic-user-modal
        modal-id="add-edit-clinic-user-modal"
        :clinic-id="clinic.id"
        :clinic-user-to-edit="clinicUserToEdit"
        @updated="getClinicUsers"
      >
      </add-edit-clinic-user-modal>

      <add-patch-location-modal
        modal-id="add-patch-location-modal"
        :clinic="clinic"
        @added="getClinic"
      >
      </add-patch-location-modal>

      <add-user-group-modal
        modal-id="add-user-group-modal"
        :clinic-id="clinic.id"
        is-modal
        @added="updateUserGroups"
      >
      </add-user-group-modal>
    </div>
  </div>
</template>
<script>
import HeaderContainer from "@/shared/components/Primitives/HeaderContainer.vue";
import InfoItem from "@/shared/components/Primitives/InfoItem.vue";
import { BIconClipboard } from "bootstrap-vue";

export default {
  components: { InfoItem, HeaderContainer, BIconClipboard },
  data: function () {
    return {
      // Subheader
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewClinicListWithBack"),
          id: "view-clinic-list-sub-header-button",
          event: "view-clinic-list-event",
        },
      ],
      navigationButtonsRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("navigation.viewPatients"),
          id: "view-patients-clinic-sub-header-button",
          event: "view-patients-clinic-button-event",
        },
      ],
      countries: null,
      // Clinics Data
      clinicResult: false,
      clinic: null,
      clinicUsersResult: false,
      clinicUsers: null,
      clinicUserToEdit: null,
      patchModes: [],
      userGroupListKey: 0,
    };
  },
  computed: {
    clinicInfoItems() {
      let infoItems = [
        {
          label: this.$t("clinic.detailsLabelName"),
          value: this.clinic.name,
        },
        {
          label: this.$t("clinic.detailsLabelAddress"),
          value: this.clinic.streetaddress,
        },
        {
          label: this.$t("clinic.detailsLabelPostalCode"),
          value: this.clinic.postalcode,
        },
        {
          label: this.$t("clinic.detailsLabelCountry"),
          value: this.countryName,
        },
        {
          label: this.$t("clinic.detailsLabelValidationCode"),
          value: this.clinic.validationcode,
        },
        {
          label: this.$t("clinic.detailsLabelPatchMode"),
          value: this.patchMode,
        },
        {
          label: this.$t("clinic.detailsLabelClinicalPortalUrl"),
          value: this.formattedClinicalPortalUrlHtml,
          isHtml: true,
        },
        {
          label: this.$t("clinic.detailsLabelPotassium"),
          value: this.hasPotassiumFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelPotassiumType"),
          value: this.isOrdinalPotassium(this.clinic)
            ? this.$t("clinic.selectOrdinalTrue")
            : this.$t("clinic.selectOrdinalFalse"),
          condition: this.showOrdinalPotassium,
        },
        {
          label: this.$t("clinic.detailsLabelHeartRate"),
          value: this.hasHeartRateFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelHeartRateAvailability"),
          value: this.hasHrvFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelAuscultation"),
          value: this.hasAuscultationFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelHemoglobin"),
          value: this.hasHgbFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelHematocrit"),
          value: this.hasHctFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelLogistics"),
          value: this.hasLogisticsFeatureSupport(this.clinic)
            ? this.$t("clinic.supported")
            : this.$t("clinic.notSupported"),
        },
        {
          label: this.$t("clinic.detailsLabelNotifications"),
          value: this.$t("clinic.detailsLabelNotificationsLink"),
          isLink: true,
          valueLink: `/clinic-detail/${this.clinic.id}/notification-settings`,
        },
        {
          label: this.$t("clinic.test"),
          value: this.clinic.is_test
            ? this.$t("common.yes")
            : this.$t("common.no"),
        },
      ];
      if (this.isSPO2Supported) {
        infoItems = [
          ...infoItems,
          {
            label: this.$t("clinic.detailsLabelOxygenSaturation"),
            value: this.hasSpo2FeatureSupport(this.clinic)
              ? this.$t("clinic.supported")
              : this.$t("clinic.notSupported"),
          },
        ];
      }
      if (this.showRBV) {
        infoItems = [
          ...infoItems,
          {
            label: this.$t("clinic.detailsLabelRBV"),
            value: this.hasRBVFeatureSupport(this.clinic)
              ? this.$t("clinic.supported")
              : this.$t("clinic.notSupported"),
          },
        ];
      }
      return infoItems;
    },
    showOrdinalPotassium() {
      return !!(
        this.isOrdinalPotassiumSupported && this.clinic?.featurepotassium
      );
    },
    showRBV() {
      return !!this.isRBVSupported;
    },
    countryName() {
      if (!this.countries) return null;
      if (this.clinic?.country_id) {
        const country = this.countries.find(
          (country) => country.id === this.clinic.country_id
        );
        return country.name;
      } else {
        return null;
      }
    },
    patchMode() {
      if (this.patchModes.length) {
        if (this.clinic.patchmode) {
          const pm = this.patchModes.find((pm) => {
            return pm.id === this.clinic.patchmode;
          });
          return this.$t(pm.name);
        } else {
          return "";
        }
      } else {
        return this.$t("common.genericLoading");
      }
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    formattedClinicUsersButton: function () {
      var count = this.clinicUsers ? this.clinicUsers.length : 0;
      return this.$tc("navigation.viewClinicalUsers", count);
    },
    formattedPatchLocationsButton: function () {
      var count =
        this.clinic && this.clinic.patchlocations
          ? this.clinic.patchlocations.length
          : 0;
      return this.$tc("navigation.viewLocations", count);
    },
    formattedBillingContactName: function () {
      if (this.clinic) {
        var elements = [
          this.clinic.billingfirstname,
          this.clinic.billinglastname,
        ];
        var filteredElements = elements.filter(function (e) {
          return e;
        });

        return filteredElements.join(" ");
      }

      return "";
    },
    formattedTechnicalContactName: function () {
      if (this.clinic) {
        var elements = [
          this.clinic.technicalfirstname,
          this.clinic.technicallastname,
        ];
        var filteredElements = elements.filter(function (e) {
          return e;
        });

        return filteredElements.join(" ");
      }
      return "";
    },
    isClinicActive: function () {
      if (this.clinic) {
        return this.clinic.active;
      }

      return false;
    },
  },
  created: function () {
    // Get this clinic information
    this.getCountries();
    this.getClinic();
    this.getClinicUsers();
    this.api.v1PatchmodesGet().then((response) => {
      this.patchModes = response.data["patch_modes"];
    });
  },
  methods: {
    downloadClinicReport() {
      this.api
        .v1ReportClinicsClinicIdGet(this.clinicIdParameter)
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clinic_report.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    downloadClinicPatientsReport() {
      this.api
        .v1ReportPatientsClinicIdGet(this.clinicIdParameter)
        .then((response) => {
          const blob = new Blob([response.data], { type: "text/csv" });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "clinic_patients_report.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    copyToClipboard(url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          console.log("URL copied to clipboard");
        })
        .catch((err) => {
          console.error("Could not copy text: ", err);
        });
    },
    getCountries() {
      this.api.v1CountriesGet().then((response) => {
        this.countries = response.data.countries;
      });
    },
    getClinic: function () {
      if (this.clinicIdParameter < 0) {
        this.$bus.emit(
          "show-general-error",
          this.$t("clinic.errorUnspecified")
        );
      } else {
        this.clinic = null;
        this.clinicResult = null;

        this.api
          .v1ClinicClinicidGet(this.clinicIdParameter)
          .then((response) => {
            this.clinic = response.data.clinic;
            this.clinicResult = true;
          })
          .catch((error) => {
            this.clinicResult = false;

            // Get the error and show it
            this.showApiResponseError(
              error,
              this.$t("clinic.errorLoadDetails")
            );
          });
      }
    },
    getClinicUsers: function () {
      this.clinicUsersResult = null;
      this.clinicUsers = [];

      this.api
        .v1ClinicClinicidUsersGet(this.clinicIdParameter)
        .then((response) => {
          this.clinicUsers = response.data.clinicusers;
          this.clinicUsersResult = true;
        })
        .catch((error) => {
          this.clinicUsersLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(error, this.$t("clinic.errorLoadList"));
        });
    },
    setClinicInactive: function () {
      var eventId = "clinic-" + (this.clinic ? this.clinic.id : "");

      this.showConfirmationPrompt(
        this.$t("clinic.promptInactive"),
        eventId,
        (state) => {
          if (state) {
            // Make the clinic inactive
            this.updateClinicState(false);
          }
        }
      );
    },
    setClinicActive: function () {
      this.updateClinicState(true);
    },
    updateClinicState(state) {
      this.api
        .v1ClinicClinicidPut(this.clinicIdParameter, {
          updateclinic: {
            active: state,
          },
        })
        .then(() => {
          this.getClinic();
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinic.errorUpdateSettings")
          );
        });
    },
    updateUserGroups: function () {
      this.userGroupListKey += 1;
    },
    navViewClinicList: function () {
      this.$router.push({ name: "clinic-list" });
    },
    navViewPatientClinicList: function () {
      this.$router.push({
        name: "patient-list",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    navViewClinicUsersList: function () {
      this.$router.push({
        name: "clinic-user-list",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewPatchLocationsList: function () {
      this.$router.push({
        name: "patch-location-list",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    showEditClinic: function () {
      this.$bvModal.show("edit-clinic-modal");
    },
    showAddClinicUser: function () {
      this.clinicUserToEdit = null;
      this.$bvModal.show("add-edit-clinic-user-modal");
    },
    showEditClinicUser: function (clinicUserId) {
      this.clinicUserToEdit = this.clinicUsers.find(
        (item) => item.id === clinicUserId
      );
      this.$bvModal.show("add-edit-clinic-user-modal");
    },
    showAddPatchLocation: function () {
      this.$bvModal.show("add-patch-location-modal");
    },
    showAddUserGroup: function () {
      this.$bvModal.show("add-user-group-modal");
    },
  },
};
</script>
<style lang="scss">
.clinic-data-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: visible;
  width: 100%;

  > div {
    &:first-child {
      flex: 0 0 400px;
      overflow: auto;
    }

    &:not(:first-child) {
      margin-left: 80px;
      flex: 1 1 auto;
      min-width: 200px;
      overflow: visible;
    }
  }

  @media (max-width: 959px) {
    flex-wrap: nowrap;
    flex-direction: column;

    > div {
      &:first-child {
        flex: 1 1 auto;
        overflow: visible;
      }

      &:not(:first-child) {
        margin-left: 0;
        flex: 1 1 auto;
        overflow: auto;
      }
    }
  }
}

#clinic-active-buttons {
  margin-top: 16px;
  flex-wrap: nowrap;
  justify-content: space-between;

  > *:not(:first-child) {
    margin-left: 14px;
  }

  button {
    min-width: 50px;
    max-width: 200px;
  }
}

.clinic-info-container {
  margin-top: 8px;
}

.after-divider {
  margin-top: 24px;
}

.clinic-info-item-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 8px;
}

.clinic-info-label {
  color: $gray-dark;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;

  text-transform: uppercase;
}

.clinic-info-value {
  color: $gray-dark;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.multi-line-value {
  white-space: pre-wrap;
}
</style>
