<template>
  <div class="chart-header-container">
    <div :id="`chart-header-${chartType}`" class="chart-header">
      <section-header
        :id="`chart-header-label-${chartType}`"
        class="pr-3"
        :header-text="label"
      />
      <slot name="image">
        <img
          v-if="icon"
          :id="`chart-header-icon-${chartType}`"
          class="header-icon"
          :alt="$t('patient.chartHeaderIcon')"
          :src="icon"
        />
      </slot>
      <span
        :id="`chart-header-current-value-${chartType}`"
        class="chart-header-value"
        v-html="currentValue"
      ></span>
      <span :id="`chart-header-units-${chartType}`" :class="chartHeaderUnits">{{
        units
      }}</span>
      <span
        :id="`chart-header-avg-24h-value-${chartType}`"
        class="chart-header-avg-24h-value"
      >
        {{ avg24hValue }}
      </span>
    </div>
  </div>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

const ACTIVE_ICON_PATH = "/icon-active.svg";
const NO_DATA_ICON_PATH = "/icon-no-data.svg";

// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
const round = (num, precision) => {
  const modifier = 10 ** precision;
  return Math.round(num * modifier) / modifier;
};

export default {
  components: {
    SectionHeader,
  },
  props: {
    chartData: {
      type: [String, Object],
    },
    chartType: {
      type: String,
    },
    ordinal: {
      type: Boolean,
      default: false,
    },
    avg24hValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      label: null,
      icon: null,
      currentValue: null,
      units: null,
    };
  },
  computed: {
    chartHeaderUnits() {
      if (["hgb", "hr", "hrv"].includes(this.chartType)) {
        return "chart-header-units-margin";
      } else {
        return "chart-header-units";
      }
    },
  },
  watch: {
    chartData: function () {
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    setTemperatureHeader() {
      if (this.chartData) {
        const rangeUnits = this.chartData.rangeunits;
        const displayUnits = this.chartData.rangedisplayunits
          ? this.chartData.rangedisplayunits
          : rangeUnits;
        this.units = this.getUnits();

        if (
          !this.chartData.data ||
          !this.arrayExistsWithData(this.chartData.data)
        ) {
          this.currentValue = "";
          this.icon = NO_DATA_ICON_PATH;
        } else {
          const lastData = this.getLastNonNullYData(this.chartData.data);
          if (!lastData || !lastData.y) {
            this.currentValue = "";
          } else {
            this.currentValue = this.convertAndFormatTemperature(
              lastData.y,
              rangeUnits,
              displayUnits
            );
            this.icon = ACTIVE_ICON_PATH;
          }
        }
      }
    },
    setHeaderTitle(translateKey) {
      this.label = this.$t(translateKey);
    },
    setPotassiumHeader() {
      if (this.chartData) {
        if (this.ordinal) {
          if (
            !this.chartData.data ||
            !this.arrayExistsWithData(this.chartData.data)
          ) {
            this.currentValue = "";
          } else {
            const lastData = this.getLastNonNullYData(this.chartData.data);
            if (!lastData || !lastData.y) {
              this.currentValue = "";
            } else {
              if (lastData.y.toUpperCase() === "NORMAL") {
                this.currentValue = this.$t("patient.normalPotassium");
              } else if (lastData.y.toUpperCase() === "LOW") {
                this.currentValue = this.$t("patient.lowPotassium");
              } else if (lastData.y.toUpperCase() === "HIGH") {
                this.currentValue = this.$t("patient.highPotassium");
              } else {
                this.currentValue = "";
              }
            }
          }
        } else {
          // using inline style here, because a scoped style won't be applied and I don't want to create a new global style
          // for this one case that only has one attribute
          this.currentValue = `
          <span style="margin-left: 24px; display: inline-block">
            <svg width="16" height="20" style="display: inline-block">
                <rect width="16" height="16" style="fill:rgb(188, 163, 235);" />
            </svg> ${this.$t("patient.helpPotassium")}
          </span>
        `;
        }
      }
    },
    setHGBOrHCTHeader() {
      if (this.chartData) {
        this.units = this.getUnits();

        if (
          !this.chartData.data ||
          !this.arrayExistsWithData(this.chartData.data)
        ) {
          this.currentValue = "";
        } else {
          const lastData = this.getLastNonNullYData(this.chartData.data);
          if (!lastData || !lastData.y) {
            this.currentValue = "";
          } else {
            this.currentValue = round(lastData.y, 0);
          }
        }
      }
    },
    setDefaultHeader() {
      if (this.chartData) {
        this.units = this.getUnits();

        if (
          !this.chartData.data ||
          !this.arrayExistsWithData(this.chartData.data)
        ) {
          this.currentValue = "";
          this.icon = NO_DATA_ICON_PATH;
        } else {
          const lastData = this.getLastNonNullYData(this.chartData.data);
          if (!lastData || !lastData.y) {
            this.currentValue = "";
            this.icon = NO_DATA_ICON_PATH;
          } else {
            this.currentValue = round(lastData.y, 0);
            this.icon = ACTIVE_ICON_PATH;
          }
        }
      }
    },
    getUnits() {
      const rangeUnits = this.chartData.rangeunits;
      const displayUnits = this.chartData.rangedisplayunits
        ? this.chartData.rangedisplayunits
        : rangeUnits;
      let units = null;

      // Get the display units label
      switch (displayUnits) {
        case "fahrenheit":
          units = "°F";
          break;
        case "celsius":
          units = "°C";
          break;
        case "bpm":
          units = "BPM";
          break;
        case "%":
          units = "%";
          break;
        case "g/dL":
          units = "g/dL";
          break;
        case "ms":
          units = "MS";
          break;
        default:
          units = displayUnits;
      }
      return units;
    },
    load() {
      switch (this.chartType) {
        case "temperature":
          this.setHeaderTitle("patient.detailsHeaderSkinTemperature");
          this.setTemperatureHeader();
          break;
        case "hr":
          this.setHeaderTitle("patient.detailsHeaderHeartRate");
          this.setDefaultHeader();
          break;
        case "hrv":
          this.setHeaderTitle("patient.detailsHeaderHeartRateVariability");
          this.setDefaultHeader();
          break;
        case "auscultation":
          this.setHeaderTitle("patient.detailsHeaderAuscultation");
          break;
        case "spo2":
          this.setHeaderTitle("patient.detailsHeaderOxygenSaturation");
          this.setDefaultHeader();
          break;
        case "hct":
          this.setHeaderTitle("patient.detailsHeaderHematocrit");
          this.setHGBOrHCTHeader();
          break;
        case "hgb":
          this.setHeaderTitle("patient.detailsHeaderHemoglobin");
          this.setHGBOrHCTHeader();
          break;
        case "potassium":
          if (this.ordinal) {
            this.setHeaderTitle("patient.detailsHeaderOrdinalPotassium");
          } else {
            this.setHeaderTitle("patient.detailsHeaderPotassium");
          }
          this.setPotassiumHeader();
          break;
        case "availability":
          this.setHeaderTitle("patient.detailsHeaderAvailability");
          break;
        case "rbv":
          this.setHeaderTitle("patient.detailsHeaderRelativeBloodVolume");
          break;
      }
    },
    zoomIn() {
      this.$emit("zoomIn", this.chartType);
    },
    zoomOut() {
      this.$emit("zoomOut", this.chartType);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-header-value {
  margin-left: 8px;
  font-size: 16px;
  font-weight: 600;
}
.chart-header-units {
  font-size: 16px;
  font-weight: 600;
}
.chart-header-units-margin {
  margin-left: 4px;
  font-size: 16px;
  font-weight: 600;
}
.zoom-buttons-container {
  display: flex;
  padding-right: 32px;

  button {
    font-size: 18px;
    min-width: 32px;
    max-width: 32px;
    height: 32px;
    padding: 4px 4px;
    margin-left: 8px;
  }
}
.chart-header-row {
  width: 100%;
}
.chart-header {
  color: $gray-dark;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  display: flex;
  align-items: center;
  line-height: normal;
}
.chart-header-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.chart-header-avg-24h-value {
  margin-left: 16px;
  font-size: 16px;
  font-weight: 600;
}
</style>
