<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtons"
      :action-buttons="actionButtons"
      @view-clinic-details-event="navViewClinicDetails"
      @add-patch-location-button-event="showAddPatchLocation"
    >
      <template #last-viewed>
        {{ formattedPatchLocationCount }}
      </template>
    </sub-header>

    <div class="container-fluid main-section patch-location-list-container">
      <div
        v-if="clinicLoadResult === null"
        id="patch-location-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("patchLocation.loadingClinic") }}</span>
      </div>
      <div
        v-else-if="
          clinic && clinic.patchlocations && clinic.patchlocations.length > 0
        "
        id="patch-location-list"
      >
        <patch-location-list-table
          :clinic="clinic ? clinic : {}"
          @refresh-list="getClinic"
        >
        </patch-location-list-table>
      </div>
      <div v-else id="patch-location-list-error" class="data-empty-container">
        <span>{{ $t("patchLocation.errorNoneFound") }}</span>
      </div>
    </div>

    <div v-if="clinic">
      <add-patch-location-modal
        modal-id="add-patch-location-modal"
        :clinic="clinic"
        @added="getClinic"
      >
      </add-patch-location-modal>
    </div>

    <b-popover
      id="add-patch-location-first-popover"
      ref="add_patch_location_first_popover"
      target="add-patch-location-sub-header-button"
      triggers="blur"
      placement="bottom"
    >
      {{ $t("patchLocation.popoverClickToAdd") }}
    </b-popover>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      // Subheader
      navigationButtons: [
        {
          label: this.$t("navigation.viewClinicDetailsWithBack"),
          id: "view-clinic-details-sub-header-button",
          event: "view-clinic-details-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("action.addLocation"),
          id: "add-patch-location-sub-header-button",
          event: "add-patch-location-button-event",
        },
      ],
      // Clinics Data
      clinicLoadResult: false,
      clinic: null,
    };
  },
  computed: {
    clinicIdParameter: function () {
      // Clinic id is converted to a string in the router, we require it as a integer
      return parseInt(this.$route.params.clinicId, 10);
    },
    formattedPatchLocationCount: function () {
      if (this.clinic && this.clinic.patchlocations) {
        return this.$t("patchLocation.labelTotalLocations", {
          count: this.clinic.patchlocations.length,
        });
      }

      return "";
    },
  },
  mounted: function () {
    // This needs to be in mounted rather than created because the popover
    // might be shown on the result of the next call
    this.getClinic();
  },
  methods: {
    getClinic: function () {
      if (this.clinicIdParameter < 0) {
        this.$bus.emit(
          "show-general-error",
          this.$t("clinic.errorUnspecified")
        );
      } else {
        this.clinics = null;
        this.clinicLoadResult = null;

        this.api
          .v1ClinicClinicidGet(this.clinicIdParameter)
          .then((response) => {
            this.clinic = response.data.clinic;
            this.clinicLoadResult = true;

            // Check to see if the popover should be shown, which is only
            // if there are no clinic users loaded
            if (
              !this.clinic ||
              !this.clinic.patchlocations ||
              (Array.isArray(this.clinic.patchlocations) &&
                this.clinic.patchlocations.length === 0)
            ) {
              this.controlFirstLocationPopover(true);
            } else {
              this.controlFirstLocationPopover(false);
            }
          })
          .catch((error) => {
            this.clinicLoadResult = false;

            // Make sure the popover is closed if it exists
            this.controlFirstLocationPopover(false);

            this.showApiResponseError(
              error,
              this.$t("clinic.errorLoadDetails")
            );
          });
      }
    },
    navViewClinicDetails: function () {
      this.$router.push({
        name: "clinic-detail",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    showAddPatchLocation: function () {
      this.$bvModal.show("add-patch-location-modal");
    },
    controlFirstLocationPopover: function (show) {
      var popover = this.$refs.add_patch_location_first_popover;

      if (popover) {
        popover.$emit(show ? "open" : "close");
      }
    },
  },
};
</script>
