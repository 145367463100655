<template>
  <div>
    <sub-header
      :last-refresh-label="$t('settingsSupport.lastRefreshLabel')"
      :last-refresh-date="lastRefreshDate"
      :action-buttons="actionButtons"
      :show-last-viewed="false"
      :navigation-buttons-right="navigationButtonsSupportRight"
      @refresh="refreshAllInformation"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
      @dashboard-button-event="navViewClinicList"
    >
    </sub-header>

    <div class="main-section container-fluid">
      <b-tabs class="settings" justified>
        <b-tab :title="$t('settingsSupport.tabSupportUsers')" class="mt-4">
          <div class="settings-column-multiple">
            <div>
              <section-header
                :header-text="$t('settingsSupport.headingAddSupportUser')"
              />
              <div class="setting-section">
                <form @submit.prevent="addSupportUser">
                  <div
                    id="support-user-first-name-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-first-name"
                      v-model="inputFields.newSupportUser.firstName"
                      type="text"
                      :placeholder="$t('supportUser.placeholderNewFirstName')"
                      :disabled="!isSupportUserAdmin($store.getters.user)"
                      :maxlength="getInputMaxChar('first-name')"
                    />
                  </div>
                  <div
                    id="support-user-last-name-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-last-name"
                      v-model="inputFields.newSupportUser.lastName"
                      type="text"
                      :placeholder="$t('supportUser.placeholderNewLastName')"
                      :disabled="!isSupportUserAdmin($store.getters.user)"
                      :maxlength="getInputMaxChar('last-name')"
                    />
                  </div>
                  <div
                    id="support-user-email-container"
                    class="input-container-settings"
                  >
                    <input
                      id="user-email"
                      v-model="inputFields.newSupportUser.email"
                      type="text"
                      :placeholder="$t('supportUser.placeholderNewEmail')"
                      :disabled="!isSupportUserAdmin($store.getters.user)"
                      :maxlength="getInputMaxChar('email')"
                    />
                  </div>
                  <div
                    id="support-user-phi-select-container"
                    class="input-container-settings"
                  >
                    <b-form-select
                      id="user-phi-privileges"
                      v-model="inputFields.newSupportUser.phiPrivileges"
                      :options="selects.phiPrivileges"
                      :disabled="!isSupportUserAdmin($store.getters.user)"
                    >
                    </b-form-select>
                  </div>
                  <div
                    id="support-user-add-button-container"
                    class="buttons-container"
                  >
                    <button
                      id="add-new-user-button"
                      type="submit"
                      :disabled="!isAddSupportUserEnabled()"
                    >
                      {{ $t("common.buttonAdd") }}
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div>
              <section-header
                :header-text="$t('settingsSupport.headingAllSupportUser')"
              />
              <div class="setting-section-list">
                <div
                  v-if="supportUsersLoadResult === null"
                  id="support-users-loading"
                  class="data-loading-container"
                >
                  <span>{{ $t("common.loading") }}</span>
                </div>
                <div v-else-if="supportUsers">
                  <div
                    v-for="(supportUser, index) in supportUsers"
                    :key="index"
                    class="tw-flex tw-flex-1 tw-min-w-[250px] tw-pb-4 tw-gap-4"
                  >
                    <div
                      class="tw-w-[250px]"
                      :class="[
                        supportUser.active ? '' : 'support-user-inactive',
                      ]"
                    >
                      <span class="tw-block">{{
                        formatClinicUserName(supportUser)
                      }}</span>
                      <span class="support-user-privileges-label">{{
                        getSupportUserPrivileges(supportUser)
                      }}</span>
                    </div>
                    <div class="tw-w-[300px]">
                      <span v-if="supportUser.lastlogin">
                        last login {{ formatTimestamp(supportUser.lastlogin) }}
                      </span>
                      <span v-else
                        >invite sent
                        {{ formatTimestamp(supportUser.create_ts) }}</span
                      >
                    </div>
                    <div class="tw-inline-flex tw-gap-4">
                      <span v-if="supportUser.active">
                        <button
                          type="button"
                          class="button-link remove-link"
                          :disabled="!isSupportUserAdmin($store.getters.user)"
                          @click.prevent="deactivateSupportUser(supportUser)"
                        >
                          {{ $t("common.buttonDeactivate") }}
                        </button>
                      </span>
                      <span v-else>
                        <button
                          type="button"
                          class="button-link"
                          :disabled="!isSupportUserAdmin($store.getters.user)"
                          @click.prevent="activateSupportUser(supportUser)"
                        >
                          {{ $t("common.buttonActivate") }}
                        </button>
                      </span>
                      <span>
                        <button
                          type="button"
                          class="button-link"
                          :disabled="
                            !canResend($store.getters.user, supportUser)
                          "
                          @click.prevent="resetPasswordSupportUser(supportUser)"
                        >
                          {{
                            supportUser?.lastlogin
                              ? $t("common.buttonResetPassword")
                              : $t("common.buttonResendInvite")
                          }}
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <span>{{ $t("supportUser.errorNone") }}</span>
                </div>
              </div>
            </div>
          </div>
        </b-tab>

        <b-tab :title="$t('settingsSupport.tabAbout')" class="mt-4">
          <div class="settings-column-single-small">
            <section-header
              :header-text="$t('settingsSupport.headingApplicationInformation')"
            />
            <div class="setting-section">
              <info-item
                :item="{
                  label: $t('settingsSupport.labelVersionNumber'),
                  value: formattedVersionNumber,
                }"
              />
              <info-item
                :item="{
                  label: $t('settingsSupport.labelEnvironment'),
                  value: $store.getters?.environment,
                }"
              />
              <section-header
                :header-text="$t('settingsSupport.labelComponents')"
              />
              <info-item
                v-for="(number, name) of $store.getters?.components"
                :key="name"
                :item="{
                  label: formatComponentName(name),
                  value: formatComponentNumber(number),
                }"
              />
              <section-header
                :header-text="$t('settingsSupport.labelFeatures')"
              />
              <info-item
                v-for="(number, name) of $store.getters?.features"
                :key="name"
                :item="{
                  label: name,
                  value: number,
                }"
              />
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import InfoItem from "@/shared/components/Primitives/InfoItem.vue";

export default {
  components: {
    SectionHeader,
    InfoItem,
  },
  data: function () {
    return {
      lastRefreshDate: null,
      navigationButtonsSupportRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("navigation.clinicList"),
          id: "dashboard-sub-header-button",
          event: "dashboard-button-event",
        },
      ],
      selects: {
        phiPrivileges: [
          {
            value: "false",
            text: this.$t("supportUser.selectPhiNotSupported"),
          },
          { value: "true", text: this.$t("supportUser.selectPhiSupported") },
        ],
      },
      supportUsersLoadResult: false,
      supportUsers: [],
      inputFields: {
        newSupportUser: {
          firstName: null,
          lastName: null,
          email: null,
          phiPrivileges: "false",
        },
      },
    };
  },
  computed: {
    formattedVersionNumber: function () {
      const release = this.$store.getters.release;
      if (release) {
        return release;
      }
      return "-";
    },
  },
  mounted() {
    // Refresh all information relevant to the settings to ensure it is at the latest
    this.refreshAllInformation();
  },
  methods: {
    canResend: function (currentUser, supportUser) {
      if (currentUser.id === supportUser.id) return false;
      return this.isSupportUserAdmin(currentUser);
    },
    formatTimestamp(timestamp) {
      let formattedDate = this.dateOrEpochFormat(timestamp, true);
      if (formattedDate) {
        return formattedDate;
      }
    },
    formatComponentNumber(number) {
      const splitNumber = number.split("-");
      splitNumber.shift();
      return splitNumber.join(" ");
    },
    formatComponentName(name) {
      const splitName = name.split("-");
      // Only remove the first part if there are at least two "-" characters
      if (splitName.length > 2) {
        splitName.shift();
      }
      return splitName.join(" ");
    },
    refreshAllInformation() {
      // Update the last refresh date to when the commands were sent
      this.lastRefreshDate = null;

      // Get all updated information
      this.getSupportUsers();
      this.getVersionInformation();
    },
    getSupportUsers: function () {
      this.supportUsersLoadResult = null;
      this.supportUsers = [];

      this.api
        .v1SupportusersGet()
        .then((response) => {
          this.supportUsersLoadResult = true;
          this.supportUsers = response.data.supportusers;

          // Update the last refresh if all commands are successful
          this.updateLastRefresh();
        })
        .catch((error) => {
          this.supportUsersLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("supportUser.errorLoadList")
          );
        });
    },
    updateLastRefresh: function () {
      if (
        this.supportUsersLoadResult === true &&
        this.supportUsersLoadResult === true
      ) {
        // Update the last refresh date if all commands were successful
        this.lastRefreshDate = new Date();
      }
    },
    getSupportUserPrivileges: function (user) {
      var privileges = [];

      if (this.isSupportUserAdmin(user)) {
        privileges.push(this.$t("supportUser.userLabelAdministrator"));
      }

      if (this.hasPhiAccess(user)) {
        privileges.push(this.$t("supportUser.userLabelPhi"));
      }

      return privileges.join(", ");
    },
    isAddSupportUserEnabled: function () {
      if (
        this.validateInputMinChar(
          this.inputFields.newSupportUser.firstName,
          1
        ) &&
        this.validateInputMinChar(
          this.inputFields.newSupportUser.lastName,
          2
        ) &&
        this.validateInputEmail(this.inputFields.newSupportUser.email)
      ) {
        return true;
      }

      return false;
    },
    addSupportUser: function () {
      var clinics = [];
      var currentUser = this.$store.getters.user;

      if (currentUser && currentUser.clinics) {
        clinics = [currentUser.clinics[0]];
      }

      if (this.isAddSupportUserEnabled()) {
        if (this.inputFields.newSupportUser.phiPrivileges === "true") {
          // Create Clinic Admin
          this.api
            .v1PhisupportuserPost({
              createsupportuser: {
                clinics: clinics,
                firstname: this.inputFields.newSupportUser.firstName,
                lastname: this.inputFields.newSupportUser.lastName,
                cognitoemail: this.inputFields.newSupportUser.email,
              },
            })
            .then(() => {
              // Show a general success message
              this.$bus.emit(
                "show-general-success",
                this.$t("supportUser.successAddPhi")
              );

              // Clear all the support user inputs on success.
              this.clearSupportUserInputs();

              // Refresh all information
              this.refreshAllInformation();
            })
            .catch((error) => {
              // Get the error and display it
              this.showApiResponseError(
                error,
                this.$t("supportUser.errorAddPhi")
              );
            });
        } else {
          // Create Clinic user
          this.api
            .v1SupportuserPost({
              createsupportuser: {
                clinics: clinics,
                firstname: this.inputFields.newSupportUser.firstName,
                lastname: this.inputFields.newSupportUser.lastName,
                cognitoemail: this.inputFields.newSupportUser.email,
              },
            })
            .then(() => {
              // Show a general success message
              this.$bus.emit(
                "show-general-success",
                this.$t("supportUser.successAddUser")
              );

              // Clear all the support user inputs on success.
              this.clearSupportUserInputs();

              // Refresh all information
              this.refreshAllInformation();
            })
            .catch((error) => {
              // Get the error and display it
              this.showApiResponseError(
                error,
                this.$t("supportUser.errorAddUser")
              );
            });
        }
      }
    },
    clearSupportUserInputs: function () {
      this.inputFields.newSupportUser.firstName = null;
      this.inputFields.newSupportUser.lastName = null;
      this.inputFields.newSupportUser.email = null;
      this.inputFields.newSupportUser.phiPrivileges = "false";
    },
    deactivateSupportUser: function (user) {
      let userId = user.id;
      var eventId = "support-user-" + userId;
      this.showConfirmationPrompt(
        this.$t("supportUser.promptDeactivate"),
        eventId,
        (state) => {
          if (state) {
            // Make the support user inactive
            this.updateSupportUserState(user, false);
          }
        }
      );
    },
    activateSupportUser: function (user) {
      this.updateSupportUserState(user, true);
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    updateSupportUserState(user, newActiveState) {
      this.api
        .v1ClinicuserClinicuseridPut(user.id, {
          updateclinicuser: {
            active: newActiveState,
          },
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("supportUser.successUpdate")
          );

          // Refresh all information
          this.refreshAllInformation();
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(error, this.$t("supportUser.errorUpdate"));
        });
    },
    resetPasswordSupportUser: function (user) {
      let userId = user.id;

      this.api
        .v1ClinicuserClinicuseridResetpasswordPost(userId)
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("supportUser.successInviteResent")
          );
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("supportUser.errorResendingInvite")
          );
        });
    },
    navViewClinicList: function () {
      this.$router.push({ name: "clinic-list" });
    },
  },
};
</script>
<style lang="scss" scoped>
.settings {
  .buttons-container {
    justify-content: flex-start;
  }

  .remove-link {
    color: $red;
  }
}

.setting-section {
  > * {
    margin-top: 16px;
  }
}

.settings-column-single {
  width: 588px;
}

.settings-column-single-small {
  width: 400px;
}

.settings-column-multiple {
  display: flex;

  > div {
    margin-right: 40px;

    &:first-child {
      flex: 0 1;
    }

    &:not(:first-child) {
      flex: 0 1 auto;
    }
  }
}

.input-container-settings {
  input,
  select {
    width: 280px;
  }
}

.support-user-privileges-label {
  min-height: 14px;

  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.support-user-inactive {
  color: $gray-light;
}

.settings-label-value-container {
  display: flex;
  justify-content: space-between;
}

.settings-label {
  color: $gray-dark;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
}

.settings-value {
  color: $gray-dark;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
}
</style>
