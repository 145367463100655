<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="show">
    <section-header
      :header-text="clinic ? $t('clinic.titleEdit') : $t('clinic.titleAdd')"
    />
    <b-form @submit.prevent="add">
      <div class="form-container">
        <div class="form-column">
          <sub-section-header
            class="text-left mt-4 mb-2"
            :header-text="$t('clinic.headerClinicInfo')"
          />
          <b-row>
            <b-col sm="12">
              <b-form-group class="after-heading clinic-input">
                <b-form-input
                  id="clinic-name"
                  v-model="inputFields.name"
                  :state="validateState('name')"
                  type="text"
                  :placeholder="$t('clinic.placeholderClinicName')"
                  aria-describedby="clinic-name-feedback"
                  :maxlength="getInputMaxChar('clinic-name')"
                  @blur="v$.inputFields.name.$touch()"
                />
                <b-form-invalid-feedback id="clinic-name-feedback">
                  {{ $t("validations.requiredField") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group>
                <b-form-textarea
                  id="clinic-address"
                  v-model="inputFields.address"
                  :state="validateState('address')"
                  aria-describedby="address-feedback"
                  type="text"
                  :placeholder="$t('clinic.placeholderClinicAddress')"
                  :maxlength="getInputMaxChar('address')"
                  rows="4"
                  @blur="v$.inputFields.address.$touch()"
                />
                <b-form-invalid-feedback id="address-feedback">
                  {{ $t("validations.requiredField") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-form-input
                  id="clinic-zip-code"
                  v-model="inputFields.zipCode"
                  :state="validateState('zipCode')"
                  aria-describedby="zipcode-feedback"
                  type="text"
                  :placeholder="$t('clinic.placeholderClinicZipCode')"
                  @blur="v$.inputFields.zipCode.$touch()"
                />
                <b-form-invalid-feedback id="zipcode-feedback">
                  {{ $t("validations.requiredField") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group>
                <b-form-select
                  id="feature-country"
                  v-model="inputFields.country_id"
                  :state="validateState('country_id')"
                  :options="countriesSelect"
                  @blur="v$.inputFields.country_id.$touch()"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <sub-section-header
            class="text-left mt-4 mb-2"
            :header-text="$t('clinic.headerFeatures')"
          />
          <b-row>
            <b-col sm="6">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="potassium-checkbox"
                  v-model="inputFields.featurePotassium"
                >
                  {{ $t("clinic.potassiumSupported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                v-if="
                  isOrdinalPotassiumSupported && inputFields.featurePotassium
                "
                class="ordinal-container"
              >
                <b-form-select
                  id="feature-type-potassium"
                  v-model="inputFields.ordinalPotassium"
                  :state="validateState('ordinalPotassium')"
                  :options="ordinalSelect"
                  @blur="v$.inputFields.ordinalPotassium.$touch()"
                >
                </b-form-select> </b-form-group
            ></b-col>
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="hemoglobin-checkbox"
                  v-model="inputFields.featureHgb"
                >
                  {{ $t("clinic.hemoglobinSupported") }}
                </b-form-checkbox>
              </b-form-group></b-col
            >
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="hematocrit-checkbox"
                  v-model="inputFields.featureHct"
                >
                  {{ $t("clinic.hematocritSupported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="isRBVSupported" sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="rbv-checkbox"
                  v-model="inputFields.feature_rbv"
                >
                  {{ $t("clinic.rbvSupported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col v-if="isSPO2Supported" sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="spo2-checkbox"
                  v-model="inputFields.featurespo2"
                >
                  {{ $t("clinic.spo2Supported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="temperature-checkbox"
                  v-model="inputFields.featureHeartRate"
                >
                  {{ $t("clinic.hrSupported") }}
                </b-form-checkbox>
              </b-form-group></b-col
            >
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="auscultation-checkbox"
                  v-model="inputFields.featureAuscultation"
                >
                  {{ $t("clinic.auscultationSupported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="logistics-checkbox"
                  v-model="inputFields.featureLogistics"
                >
                  {{ $t("clinic.logisticsSupported") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <sub-section-header
            class="text-left mt-4 mb-2"
            :header-text="$t('clinic.advancedHeader')"
          />
          <b-row>
            <b-col sm="12">
              <b-form-group>
                <b-form-select
                  id="feature-patch-modes"
                  v-model="inputFields.patchMode"
                  :options="getPatchModesSelect()"
                >
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col sm="12">
              <b-form-group class="checkbox-container">
                <b-form-checkbox
                  id="is-test-checkbox"
                  v-model="inputFields.is_test"
                >
                  {{ $t("clinic.testCheckbox") }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </div>
      <div id="add-clinic-buttons-container" class="buttons-container">
        <button
          id="add-clinic-add-save-button"
          type="submit"
          :disabled="!isAddEditEnabled || !buttonsEnabled"
        >
          {{ clinic ? $t("common.buttonSave") : $t("common.buttonAdd") }}
        </button>
        <button
          id="add-clinic-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { required } from "@vuelidate/validators";
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";

export default {
  components: {
    SubSectionHeader,
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    clinic: {
      // If a clinic exists it is a edit dialog
      type: Object,
      default: null,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      countries: [],
      patchModes: [],
      ordinalSelect: [
        {
          value: false,
          text: this.$t("clinic.selectOrdinalFalse"),
        },
        {
          value: true,
          text: this.$t("clinic.selectOrdinalTrue"),
        },
      ],
      buttonsEnabled: true,
      inputFields: {
        name: null,
        address: null,
        country_id: null,
        zipCode: null,
        featureHeartRate: true,
        featureAuscultation: true,
        feature_rbv: false,
        featurespo2: false,
        featureHct: true,
        featureHgb: true,
        featureLogistics: true,
        featurePotassium: true,
        ordinalPotassium: false,
        patchMode: "noChange",
        is_test: false,
      },
    };
  },
  validations() {
    return {
      inputFields: {
        name: {
          required,
        },
        address: {
          required,
        },
        zipCode: {},
        country_id: {
          required,
        },
        featureHeartRate: {
          required,
        },
        featureAuscultation: {
          required,
        },
        featureHct: {
          required,
        },
        featureHgb: {
          required,
        },
        featureLogistics: {
          required,
        },
        featurePotassium: {
          required,
        },
        ordinalPotassium: {
          required: this.isOrdinalRequired ? required : false,
        },
        patchMode: {
          required,
        },
      },
    };
  },
  computed: {
    isOrdinalRequired() {
      return (
        this.isOrdinalPotassiumSupported && this.inputFields.featurePotassium
      );
    },
    isAddEditEnabled() {
      const formInvalid = this.v$.$invalid;
      if (formInvalid) return false;

      return true;
    },
    countriesSelect() {
      return [
        {
          value: null,
          text: this.$t("clinic.selectCountry"),
          disabled: true,
        },
        ...this.countries.map((country) => {
          return {
            value: country.id,
            text: country.name,
          };
        }),
      ];
    },
  },
  mounted: function () {
    this.api.v1PatchmodesGet().then((response) => {
      this.patchModes = response.data["patch_modes"];
    });
    this.api.v1CountriesGet().then((response) => {
      this.countries = response.data.countries;
    });
  },
  methods: {
    getPatchModesSelect() {
      return [
        {
          value: null,
          text: this.$t("patchModes.select"),
          disabled: true,
        },
        ...this.patchModes.map((pm) => ({
          value: pm.id,
          text: this.$t(pm.name),
        })),
      ];
    },
    updateClinic: function () {
      this.api
        .v1ClinicClinicidPut(this.clinic.id, {
          updateclinic: {
            clinic: this.clinic.id,
            name: this.inputFields.name,
            streetaddress: this.inputFields.address,
            country_id: this.inputFields.country_id,
            postalcode: this.inputFields.zipCode,
            featureheartrate: this.inputFields.featureHeartRate,
            featureauscultation: this.inputFields.featureAuscultation,
            featurehct: this.inputFields.featureHct,
            featurespo2: this.inputFields.featurespo2,
            feature_rbv: this.inputFields.feature_rbv,
            featurehgb: this.inputFields.featureHgb,
            featurelogistics: this.inputFields.featureLogistics,
            featurepotassium: this.inputFields.featurePotassium,
            ordinal_potassium: this.inputFields.ordinalPotassium,
            patchmode: this.inputFields.patchMode,
            is_test: this.inputFields.is_test,
          },
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinic.successUpdate")
          );

          // Hide the modal
          this.$bvModal.hide(this.modalId);

          // On success send an event
          this.$emit("updated");

          // Renable the buttons
          this.buttonsEnabled = true;
        })
        .catch((error) => {
          // Show the API error
          this.showApiResponseError(error, this.$t("clinic.errorUpdate"));

          // Renable the buttonss
          this.buttonsEnabled = true;
        });
    },
    add: function () {
      if (this.isAddEditEnabled) {
        this.buttonsEnabled = false;

        if (this.clinic) {
          // This is to edit a clinic
          if (this.clinic.patchmode !== this.inputFields.patchMode) {
            this.showConfirmationPrompt(
              this.$t("clinic.confirmPatchModeChange"),
              "patchmode",
              this.updateClinic
            );
          } else {
            this.updateClinic();
          }
        } else {
          // This is to create a clinic
          this.api
            .v1ClinicPost({
              sitename: this.generateSiteName(this.inputFields.name),
              createclinic: {
                name: this.inputFields.name,
                streetaddress: this.inputFields.address,
                country_id: this.inputFields.country_id,
                postalcode: this.inputFields.zipCode,
                featureheartrate: this.inputFields.featureHeartRate,
                featureauscultation: this.inputFields.featureAuscultation,
                featurespo2: this.inputFields.featurespo2,
                featurehct: this.inputFields.featureHct,
                feature_rbv: this.inputFields.feature_rbv,
                featurehgb: this.inputFields.featureHgb,
                featurelogistics: this.inputFields.featureLogistics,
                featurepotassium: this.inputFields.featurePotassium,
                ordinal_potassium: this.inputFields.ordinalPotassium,
                featurehrv: true,
                patchmode: this.inputFields.patchMode,
                is_test: this.inputFields.is_test,
              },
            })
            .then(() => {
              // Show a general success message
              this.$bus.emit(
                "show-general-success",
                this.$t("clinic.successAdd")
              );

              // Hide the modal
              this.$bvModal.hide(this.modalId);

              // On success send an event
              this.$emit("added");

              // Renable the buttons
              this.buttonsEnabled = true;
            })
            .catch((error) => {
              // Get and show the error
              this.showApiResponseError(error, this.$t("clinic.errorAdd"));

              // Renable the buttons
              this.buttonsEnabled = true;
            });
        }
      }
    },
    cancel: function () {
      this.$bvModal.hide(this.modalId);
    },
    show: function () {
      this.v$.$reset();
      // Anytime this is shown, clear or set the input fields. This is so they
      // do not hold state over from everytime they are shown. First step is to
      // clear them all. As there is a bit of race condition that needs to be
      // handled. The clinic prop may not have been propagated yet. So use
      // the next tick to check that.
      this.inputFields.name = null;
      this.inputFields.address = null;
      this.inputFields.zipCode = null;
      this.inputFields.country_id = null;
      this.inputFields.validationCode = null;
      this.inputFields.featureHeartRate = true;
      this.inputFields.featureAuscultation = true;
      this.inputFields.featurePotassium = true;
      this.inputFields.ordinalPotassium = false;
      this.inputFields.featurespo2 = false;
      this.inputFields.feature_rbv = false;
      this.inputFields.featureHct = true;
      this.inputFields.featureHgb = true;
      this.inputFields.featureLogistics = true;
      this.inputFields.patchMode = "noChange";
      this.inputFields.is_test = false;

      // Have to wait until the next tick before we can be sure that the
      // clinic information is set properly. This is a bit of a race condition
      // with the @show event.
      this.$nextTick(function () {
        if (this.clinic) {
          this.inputFields.name = this.clinic.name;
          this.inputFields.address = this.clinic.streetaddress;
          this.inputFields.country_id = this.clinic.country_id;
          this.inputFields.zipCode = this.clinic.postalcode;
          this.inputFields.featureHeartRate = this.clinic.featureheartrate;
          this.inputFields.featureAuscultation =
            this.clinic.featureauscultation;
          this.inputFields.featurespo2 = this.clinic.featurespo2;
          this.inputFields.feature_rbv = this.clinic.feature_rbv;
          this.inputFields.featureHct = this.clinic.featurehct;
          this.inputFields.featureHgb = this.clinic.featurehgb;
          this.inputFields.featurePotassium = this.clinic.featurepotassium;
          this.inputFields.ordinalPotassium = this.clinic.ordinal_potassium;
          this.inputFields.featureLogistics = this.clinic.featurelogistics;
          this.inputFields.patchMode = this.clinic.patchmode
            ? this.clinic.patchmode
            : 1;
          this.inputFields.is_test = this.clinic.is_test;
        }
      });
    },
    generateSiteName: function (name) {
      if (!name) {
        return null;
      }

      // Return only lowercase characters, truncated to 64 characters
      return name
        .toLowerCase()
        .replace(/[^a-z0-9]/g, "")
        .slice(0, 64);
    },
  },
};
</script>

<style scoped lang="scss">
.checkbox-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    margin-bottom: 0;
  }

  select {
    width: auto;
  }
}
.clinic-modal-header {
  margin-bottom: 16px;
}
.clinic-modal-header-container {
  text-align: left;
}
.clinic-modal-subheader-container {
  margin-top: 40px;
  text-align: left;
}
.form-container {
  display: flex;
}
.form-column {
  flex: 1;
  margin: 8px;
}
.clinic-input {
  width: 100%;
}
.ordinal-container {
  padding-left: 16px;
  margin-top: 0px;
}
</style>
