<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="onShow">
    <section-header :header-text="$t('importPatients.title')" />
    <div v-if="state == State.Open">
      <div>
        <b-form-file id="file-large" v-model="patientsFile" size="lg" />
      </div>
      <div>
        <button @click="upload">
          {{ $t("importPatients.importFile") }}
        </button>
      </div>
    </div>
    <div v-else-if="state == State.Uploading">
      <b-spinner />
    </div>
    <div v-else>
      <b-row class="import-results-header text-center">
        <b-col>
          <h4>
            <b-badge variant="primary">
              {{ processedText }}
            </b-badge>
          </h4>
        </b-col>
        <b-col>
          <h4>
            <b-badge variant="success">
              {{ succeededText }}
            </b-badge>
          </h4>
        </b-col>
        <b-col>
          <h4>
            <b-badge variant="danger">
              {{ failedText }}
            </b-badge>
          </h4>
        </b-col>
      </b-row>
      <b-row v-for="error in errors" :key="error.line">
        {{ `${error.line}: ${error.errors.join(", ")}` }}
      </b-row>
    </div>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
const State = Object.freeze({ Open: 1, Uploading: 2, Uploaded: 3 });

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      State,
      state: State.Open,
      patientsFile: null,
      processed: null,
      succeeded: null,
      failed: null,
      errors: null,
    };
  },
  computed: {
    processedText() {
      return `${this.$t("importPatients.processed")} ${this.processed}`;
    },
    succeededText() {
      return `${this.$t("importPatients.succeeded")} ${this.succeeded}`;
    },
    failedText() {
      return `${this.$t("importPatients.failed")} ${this.failed}`;
    },
  },
  methods: {
    onShow: function () {
      this.patientsFile = null;
      this.state = this.State.Open;
      this.processed = null;
      this.succeeded = null;
      this.failed = null;
      this.errors = null;
    },
    upload: function () {
      this.state = State.Uploading;
      this.api
        .v1PatientsBulkPost(this.patientsFile, this.$store.getters.clinicId)
        .then((result) => {
          this.processed = result.data?.processed;
          this.succeeded = result.data?.succeeded;
          this.failed = result.data?.failed;
          this.errors = result.data?.errors;
          this.state = State.Uploaded;
        })
        .catch((error) => {
          this.showApiResponseError(error);
          this.$bvModal.hide(this.modalId);
        });
    },
  },
};
</script>
<style lang="scss">
.import-results-header {
  width: 100%;
  justify-content: space-between;
}
.import-results-header-column {
  justify-content: center;
}
</style>
