<template>
  <div class="hardware-search-container">
    <b-input-group :prepend="searchPrompt">
      <b-form-input
        v-model="searchText"
        class="box-input"
        @keydown.enter="search"
      ></b-form-input>
      <b-input-group-append>
        <b-button class="search-button" @click="search">{{
          $t("common.search")
        }}</b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: "HardwareSearch",
  props: {
    searchPrompt: {
      type: String,
      default: "Search",
    },
  },
  data() {
    return {
      searchText: "",
    };
  },
  methods: {
    search() {
      this.$emit("search", this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.hardware-search-container {
  width: 768px;
  display: flex;
  gap: 24px;
}
.box-input {
  border: 1px solid #ced4da;
}
.search-button {
  background-color: $purple-dark;
}
</style>
