<template>
  <div>
    <label for="time-zone-choice">
      {{ $t("patient.selectTimezone") }}
      <span v-b-tooltip.hover :title="$t('patient.tooltipTimezone')">
        <SVGIconWrapper>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="size-6 tw-mt-1"
          >
            <path
              fill-rule="evenodd"
              d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </SVGIconWrapper> </span
    ></label>
    <b-form-input
      id="time-zone-choice"
      :value="inputFields.choice"
      autocomplete="off"
      class="tw-w-full"
      list="time-zones"
      name="time-zone-choice"
      aria-describedby="time-zone-choice-feedback"
      :state="validateState('choice')"
      @input="updateChoice"
      @blur="v$.inputFields.choice.$touch()"
    />
    <b-form-invalid-feedback id="time-zone-choice-feedback">
      {{ $t("patient.invalidTimezone") }}
    </b-form-invalid-feedback>

    <datalist id="time-zones">
      <option
        v-for="tz in timeZones"
        :key="tz.intl_timezone"
        :value="tz.intl_timezone"
      >
        {{ tz.cn }}
      </option>
    </datalist>
  </div>
</template>

<script lang="ts">
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";
import useVuelidate from "@vuelidate/core";

export default {
  name: "TimeZonePicker",
  components: { SVGIconWrapper },
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      inputFields: {
        choice: {
          validTZ: this.isValidTZ,
        },
      },
    };
  },
  data() {
    return {
      timeZones: [],
      inputFields: {
        choice: null,
      },
    };
  },
  watch: {
    value(newVal) {
      this.inputFields.choice = newVal;
    },
  },
  mounted() {
    this.inputFields.choice = this.value;
    this.loadData();
  },
  methods: {
    updateChoice(newVal) {
      this.inputFields.choice = newVal;
      this.$emit("input", newVal);
    },
    isValidTZ(value) {
      return this.timeZones.some((tz) => tz.intl_timezone === value);
    },
    async loadData() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        // Dynamic import of the JSON file
        const module = await import(
          "@/shared/assets/iana_friendly_timezone_mapping.json"
        );

        const timeZonesObject = module.default;
        const timeZonesArray = [];

        for (const key in timeZonesObject) {
          if (timeZonesObject.hasOwnProperty(key)) {
            timeZonesArray.push({
              intl_timezone: key,
              cn: `${timeZonesObject[key].long} (${timeZonesObject[key].short})`,
            });
          }
        }
        this.timeZones = timeZonesArray;
      } catch (error) {
        console.error("Error loading timezone data:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

