<template>
  <b-modal
    :id="modalId"
    :no-close-on-backdrop="true"
    centered
    hide-header
    hide-footer
    @show="onShow"
  >
    <section-header
      v-if="requireAccept"
      :header-text="$t('privacyPolicy.titleAccept')"
    />
    <section-header v-else :header-text="$t('privacyPolicy.title')" />
    <div class="eula-date-row">
      {{ $d(new Date(2023, 7, 29), "dayOfYear") }}
    </div>
    <div class="privacy-policy-content" v-html="privacyPolicyContentHtml"></div>

    <div
      v-if="requireAccept"
      id="privacy-buttons-container"
      class="buttons-container"
    >
      <button
        id="accept-button"
        :disabled="!privacyPolicyLoaded"
        @click="onAccept"
      >
        {{ $t("common.buttonAccept") }}
      </button>
      <button
        id="logout-cancel-button"
        class="button-negative"
        @click="onLogout"
      >
        {{ $t("common.buttonLogout") }}
      </button>
    </div>
    <div v-else id="privacy-button-container" class="buttons-container">
      <button id="close-button" @click="onClose">
        {{ $t("common.buttonClose") }}
      </button>
    </div>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    requireAccept: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      // Update the following constants if the privacy policy changes
      PRIVACY_POLICY_VERSION: "08-29-23",
      PRIVACY_POLICY_URL: "/privacy_policy/en/eula_08-29-23.html",
      // Data variables
      privacyPolicyLoaded: false,
      privacyPolicyContentHtml: "<p>" + this.$t("common.loading") + "</p>",
    };
  },
  created: function () {
    // Emit the last accepted version so the the parent knows.
    this.$emit("last-accepted-version", this.PRIVACY_POLICY_VERSION);
  },
  methods: {
    onAccept: function () {
      // On success hide the modal
      this.$bvModal.hide(this.modalId);

      // On success indicate that the policy was accepted
      this.$emit("policy-accepted", this.PRIVACY_POLICY_VERSION);
    },
    onLogout: function () {
      this.$bvModal.hide(this.modalId);

      // On logout send an event that the policy was not accepted.
      this.$emit("policy-not-accepted");
    },
    onClose: function () {
      // On success hide the modal
      this.$bvModal.hide(this.modalId);

      // On success indicate the privacy policy was closed
      this.$emit("policy-closed");
    },
    onShow: function () {
      if (!this.privacyPolicyLoaded) {
        // Get the privacy policy content from a local HTML file. This allows
        // for easy editing and replacement of the document
        this.axios({
          method: "get",
          baseURL: "", // Local file
          url: this.PRIVACY_POLICY_URL,
        })
          .then((result) => {
            this.privacyPolicyContentHtml = result.data;

            // Make sure there is a policy loaded, otherwise the
            // user will not be able to accept
            if (this.privacyPolicyContentHtml) {
              this.privacyPolicyLoaded = true;
            }
          })
          .catch((error) => {
            console.log(error);
            this.privacyPolicyLoaded = false;
            this.privacyPolicyContentHtml =
              "<p>" + this.$t("privacyPolicy.errorLoad") + "</p>";
          });
      }
    },
  },
};
</script>
<style lang="scss">
.privacy-ol-list {
  text-align: left;
}
.eula-date-row {
  font-style: italic;
  width: 100%;
  text-align: right;
}
.privacy-policy-content {
  h1,
  h2 {
    margin-top: 24px;
    margin-bottom: 0px;

    text-align: left;

    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 24px;
    margin-bottom: 0px;

    text-align: left;

    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 23px;
  }

  ul {
    text-align: left;
    padding-top: 24px;
  }
}
</style>
