/* eslint-disable */
import axios from "axios";
import qs from "qs";
let domain = "";
let axiosInstance = axios.create();
export const getDomain = () => {
  return domain;
};
export const setDomain = ($domain) => {
  domain = $domain;
};
export const getAxiosInstance = () => {
  return axiosInstance;
};
export const setAxiosInstance = ($axiosInstance) => {
  axiosInstance = $axiosInstance;
};
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase();
  let keys = Object.keys(queryParameters);
  let queryUrl = url;
  if (keys.length > 0) {
    queryUrl = url + "?" + qs.stringify(queryParameters);
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axiosInstance[method](queryUrl, body, config);
  } else if (
    method === "get" ||
    method === "delete" ||
    method === "head" ||
    method === "option"
  ) {
    return axiosInstance[method](queryUrl, config);
  } else {
    return axiosInstance[method](queryUrl, form, config);
  }
};
/*==========================================================
 *                    powered by Flasgger
 ==========================================================*/
// TODO get this generated from the backend
export const getScenarios = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = "/v1/scenarios";
  let body;
  let queryParameters = {};
  let form = {};

  return request("get", domain + path, body, queryParameters, form, config);
};

export const getPatient = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = `/v1/patients/${parameters.id}`;
  let body;
  let queryParameters = {};
  let form = {};

  return request("get", domain + path, body, queryParameters, form, config);
};

export const patchPatient = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = `/v1/patients/${parameters.id}`;
  let body = {};
  if (parameters["is_active"] !== undefined) {
    body["is_active"] = parameters["is_active"];
  }
  if (parameters["scenario_id"] !== undefined) {
    body["scenario_id"] = parameters["scenario_id"];
  }
  if (parameters["acute_patient_mode_id"] !== undefined) {
    body["acute_patient_mode_id"] = parameters["acute_patient_mode_id"];
  }
  let queryParameters = {};
  let form = {};

  return request("patch", domain + path, body, queryParameters, form, config);
};

export const createPatient = function (parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain();
  const config = parameters.$config;
  let path = `/v1/patients`;
  let body = {
    patient_id: parameters.id,
    scenario_id: parameters.scenario_id,
    acute_patient_mode_id: parameters.acute_patient_mode_id,
    end_time: parameters.end_time,
    start_time: parameters.start_time,
  };
  let queryParameters = {};
  let form = {};

  return request("post", domain + path, body, queryParameters, form, config);
};
