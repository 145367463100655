<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :navigation-buttons-right="navigationButtonsRight"
      :action-buttons="actionButtons"
      :show-last-viewed="false"
      @view-clinic-list-event="navViewClinicList"
      @download-report-button-event="downloadReport"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    />
    <PatientSearch @hide-completed="hideCompletedUpdate" @search="search" />
    <div class="container-fluid main-section patient-list-container">
      <div
        v-if="clinicsResult === null"
        id="patient-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("common.loading") }}</span>
      </div>
      <div v-else-if="clinics" id="patients-list">
        <patient-list-table
          :clinics="clinics"
          :fields-allowed-filter="[
            'clinicpatientidentifier',
            'name',
            'clinic',
            'lastdataseen',
            'status',
          ]"
          :search-text="searchText"
          :hide-completed="hideCompleted"
          :reload-patients="patientReloadTrigger"
        >
        </patient-list-table>
      </div>
      <div
        v-else-if="clinicsResult === false"
        id="patients-list-error"
        class="data-empty-container"
      >
        <span>{{ $t("clinic.errorLoadClinics") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import PatientSearch from "@/shared/components/PatientSearch.vue";
export default {
  components: {
    PatientSearch,
  },
  data: function () {
    return {
      // Subheader Configuration
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewClinicList"),
          id: "view-clinic-list-sub-header-button",
          event: "view-clinic-list-event",
        },
      ],
      navigationButtonsRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      // Clinics
      clinics: [],
      clinicsResult: null,
      // Patient Table support
      patientReloadTrigger: 0,
      searchText: null,
      hideCompleted: true,
    };
  },
  computed: {
    actionButtons() {
      if (!this.isReportsSupported) return [];

      return [
        {
          label: this.$t("action.downloadReport"),
          id: "download-report-sub-header-button",
          event: "download-report-button-event",
        },
      ];
    },
  },
  mounted: function () {
    this.getClinics();
  },
  methods: {
    downloadReport() {
      this.api.v1ReportPatientsGet().then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "patients_report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    hideCompletedUpdate(hideCompleted) {
      this.hideCompleted = hideCompleted;
      this.reloadPatients();
    },
    search(searchText) {
      this.searchText = searchText;
      this.reloadPatients();
    },
    getClinics: function () {
      this.clinics = null;
      this.clinicsResult = null;

      this.api
        .v1ClinicsGet()
        .then((response) => {
          this.clinics = response.data.clinics;
          this.clinicsResult = true;
        })
        .catch((error) => {
          this.clinicsResult = false;

          this.showApiResponseError(error, this.$t("clinic.errorLoadList"));
        });
    },
    reloadPatients: function () {
      this.patientReloadTrigger++;
    },
    navViewClinicList: function () {
      this.$router.push({ name: "clinic-list" });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
  },
};
</script>
