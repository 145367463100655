<template>
  <div>
    <div
      v-for="(address, index) in addresses"
      :key="index"
      class="address-container"
    >
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <div class="description-label-container">
              <label :for="`patient-address-description-${index}`">
                {{ $t("patient.addressPlaceholderDescription") }}
              </label>
              <div class="address-options">
                <b-form-checkbox
                  :checked="address.active"
                  @change="(newValue) => setActive(index, newValue)"
                >
                  <span
                    v-b-tooltip.hover
                    :title="$t('patient.shippingAddress')"
                  >
                    {{ $t("patient.activeAddress") }}
                  </span>
                </b-form-checkbox>
                <div class="remove-address-container">
                  <SVGIconWrapper
                    :id="`remove-button-${index}`"
                    role="button"
                    tabindex="0"
                    class="remove-icon"
                    clickable
                    @click.prevent="removeAddress(index)"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      class="size-6"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M16.5 4.478v.227a48.816 48.816 0 0 1 3.878.512.75.75 0 1 1-.256 1.478l-.209-.035-1.005 13.07a3 3 0 0 1-2.991 2.77H8.084a3 3 0 0 1-2.991-2.77L4.087 6.66l-.209.035a.75.75 0 0 1-.256-1.478A48.567 48.567 0 0 1 7.5 4.705v-.227c0-1.564 1.213-2.9 2.816-2.951a52.662 52.662 0 0 1 3.369 0c1.603.051 2.815 1.387 2.815 2.951Zm-6.136-1.452a51.196 51.196 0 0 1 3.273 0C14.39 3.05 15 3.684 15 4.478v.113a49.488 49.488 0 0 0-6 0v-.113c0-.794.609-1.428 1.364-1.452Zm-.355 5.945a.75.75 0 1 0-1.5.058l.347 9a.75.75 0 1 0 1.499-.058l-.346-9Zm5.48.058a.75.75 0 1 0-1.498-.058l-.347 9a.75.75 0 0 0 1.5.058l.345-9Z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </SVGIconWrapper>
                </div>
              </div>
            </div>
            <b-form-input
              :id="`patient-address-description-${index}`"
              :value="address.description"
              class="form-input"
              type="text"
              :placeholder="$t('patient.addressPlaceholderDescription')"
              :maxlength="getInputMaxChar('email')"
              @update="(newVal) => updateAddress(index, 'description', newVal)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12">
          <b-form-group>
            <label :for="`patient-address-street-${index}`">
              {{ $t("patient.addressLabelStreetAddress") }}
            </label>
            <b-form-textarea
              :id="`patient-address-street-${index}`"
              :value="address.street_city_state_address"
              type="text"
              :placeholder="$t('patient.addressPlaceholderStreetAddress')"
              :maxlength="getInputMaxChar('address')"
              rows="4"
              @update="
                (newVal) =>
                  updateAddress(index, 'street_city_state_address', newVal)
              "
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="6">
          <b-form-group>
            <label :for="`address-postal-code-${index}`">
              {{ $t("patient.zipOrPostalCodeLabel") }}
              <span v-b-tooltip.hover :title="$t('patient.zipOrPostalTooltip')">
                <SVGIconWrapper class="button-link">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    class="size-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </SVGIconWrapper>
              </span>
            </label>
            <b-form-input
              :id="`address-postal-code-${index}`"
              :value="address.postal_code"
              class="form-input"
              type="text"
              :placeholder="$t('patient.zipOrPostalCodeLabel')"
              @update="(newVal) => updateAddress(index, 'postal_code', newVal)"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group>
            <label :for="`address-postal-code-${index}`">
              {{ $t("patient.labelCountryOrRegion") }}
            </label>
            <b-form-select
              :id="`address-country-${index}`"
              :value="address.country_id"
              class="form-input"
              :options="countriesSelect"
              @change="(newVal) => updateAddress(index, 'country_id', newVal)"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <div class="add-new-address-container">
      <button type="button" @click="addAddress">
        {{ $t("patient.newAddressButton") }}
      </button>
    </div>
  </div>
</template>
<script lang="ts">
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default {
  name: "PatientAddresses",
  components: { SVGIconWrapper },
  props: {
    addresses: {},
  },
  data() {
    return {
      countries: [],
    };
  },
  computed: {
    countriesSelect() {
      return [
        {
          value: null,
          text: this.$t("clinic.selectCountry"),
          disabled: true,
        },
        ...this.countries.map((country) => {
          return {
            value: country.id,
            text: country.name,
          };
        }),
      ];
    },
  },
  mounted() {
    this.api.v1CountriesGet().then((response) => {
      this.countries = response.data.countries;
    });
  },
  methods: {
    addAddress() {
      this.$emit("add-address");
    },
    updateAddress(index, key, newVal) {
      const updatedAddress = { ...this.addresses[index] };
      updatedAddress[key] = newVal;
      this.$emit("update-address", { index, updatedAddress });
    },
    removeAddress(index) {
      this.$emit("remove-address", index);
    },
    setActive(index, newVal) {
      this.$emit("update-active", { index, active: newVal });
    },
  },
};
</script>
<style scoped lang="scss">
label {
  float: left;
}
.address-options {
  display: flex;
  justify-content: flex-end;
}
.remove-address-container {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  padding-bottom: 8px;
  cursor: pointer;
}
.remove-icon {
  color: $red;
}
.form-input {
  padding-left: 0px;
}
.description-label-container {
  display: flex;
  justify-content: space-between;
  align-content: center;
}

.add-new-address-container {
  display: flex;
  justify-content: center;
}
.address-container {
  padding-bottom: 32px;
}
</style>
