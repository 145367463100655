<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "SubSectionHeader",
  props: {
    headerText: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <h3>{{ headerText }}</h3>
</template>

<style scoped lang="scss">
h3 {
  color: $gray-dark;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
  margin: 0;

  text-transform: uppercase;
}
</style>
