<template>
  <div class="patient-search-container">
    <div class="side-flex-section"></div>
    <div class="flex-section">
      <b-input-group size="sm" class="box-input-group">
        <b-form-input
          v-model="searchText"
          data-testid="clinic-search-input"
          type="search"
          class="box-input"
          :placeholder="$t('clinic.searchPlaceholder')"
          @keydown.enter="search"
        />
        <b-input-group-append>
          <b-button
            data-testid="clinic-search-button"
            class="search-button"
            size="sm"
            @click="search"
          >
            <SVGIconWrapper :size="18">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            </SVGIconWrapper>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div class="side-flex-section">
      <div class="right-checkbox" data-testid="hide-test-checkbox">
        <b-form-checkbox v-model="hideTest">
          {{ $t("clinic.hideTest") }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default {
  name: "PatientSearch",
  components: { SVGIconWrapper },
  data() {
    return {
      searchText: "",
      hideTest: true,
    };
  },
  watch: {
    searchText(newValue, oldValue) {
      if (newValue.trim() === "" && oldValue.length > 1) {
        this.$emit("search", this.searchText.trim());
      }
    },
    hideTest() {
      this.$emit("hide-test", this.hideTest);
    },
  },
  methods: {
    search() {
      this.$emit("search", this.searchText);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-flex-section {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  @media (max-width: $sm) {
    display: none;
  }
}
.right-checkbox {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.flex-section {
  height: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0px 8px;
}
.patient-search-container {
  display: flex;
  justify-content: space-between;
  background-color: #f2f2f2;
  height: 36px;
}
.search-button {
  background-color: $purple-dark;
  min-width: 100%;
}
.box-input-group {
  min-width: 200px;
}
.box-input {
  border: 1px solid #ced4da;
}

//noinspection ALL
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 14px;
  width: 12px;
  background-image: url("~/public/alio-x.png");
  background-size: 12px 14px;
}
</style>
