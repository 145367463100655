<template>
  <div id="app">
    <div class="content">
      <main-header :support-portal="isSupportApp()"></main-header>
      <router-view class="router-view" />
    </div>
    <div v-if="showFooter" class="footer">
      <Footer />
    </div>
    <general-success-modal
      modal-id="general-success-modal"
    ></general-success-modal>
    <general-error-modal modal-id="general-error-modal"></general-error-modal>
    <confirmation-prompt-modal
      modal-id="confirmation-prompt-modal"
    ></confirmation-prompt-modal>
    <TimeoutModal modal-id="timeout-modal" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  setDocumentDirectionPerLocale,
  setDocumentLang,
} from "@/shared/i18n/document";
import TimeoutModal from "@/shared/components/TimeoutModal.vue";
import Footer from "@/shared/components/Footer.vue";
import { fetchAuthSession } from "aws-amplify/auth";

const TWENTY_MINUTES = 1000 * 60 * 20;
const EIGHTEEN_MINUTES = 1000 * 60 * 18;

export default defineComponent({
  components: {
    Footer,
    TimeoutModal,
  },
  data() {
    return {
      showFooter: false,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // handle showing the footer
        if (to.name === "login" || to.name === "login-sso") {
          this.showFooter = false;
        } else {
          this.showFooter = true;
        }

        // handle setting the proper title for the route
        if (this.$te(`routeTitles.${to.name}`)) {
          document.title = this.$t(`routeTitles.${to.name}`) + " - Alio";
        } else {
          document.title = "Alio";
        }
      },
    },
  },
  created() {
    // this handles making sure timeouts work properly whether you've ever logged in before or not
    if (
      Date.now() - (this.$store.state.lastRequest || 0) > TWENTY_MINUTES &&
      this.$route.name !== "login"
    ) {
      this.navToLogin(this.$route.path);
    }
    this.axios.interceptors.request.use(async (config) => {
      // Set up the main auth header for the current session. Note that
      // if there is a "Signature=" in the URL, this is considered the
      // authorization for the URL and we should NOT include the normal
      // Authorization header, or it will fail. This normally involves time
      // limited media files.
      if (config.url && !config.url.includes("Signature=")) {
        try {
          if (this.$route.name !== "login") {
            this.$store.commit("updateLastRequest", Date.now());
          }

          const session = await fetchAuthSession();
          // Get idToken from the authSession
          const idToken = session.tokens.idToken || "";

          if (session) {
            if (idToken) {
              config.headers.Authorization = idToken;
            }
          }
        } catch (error) {
          // Ignore any errors
        }
      }

      return config;
    });
  },
  mounted() {
    setInterval(() => {
      // only check for timeout if lastRequest has been set
      if (this.$store.state.lastRequest) {
        // logout if we are over 20 minutes
        if (Date.now() - this.$store.state.lastRequest > TWENTY_MINUTES) {
          this.$bvModal.hide("timeout-modal");
          this.navToLogin(null);
        } else if (
          Date.now() - this.$store.state.lastRequest >
          EIGHTEEN_MINUTES
        ) {
          // Show popup if we are within 1 minute of timing out
          this.$bvModal.show("timeout-modal");
        }
      }
    }, 1000 * 30); // check every 30 seconds
    this.$watch(
      "$i18n.locale",
      (newLocale, oldLocale) => {
        if (newLocale === oldLocale) {
          return;
        }
        setDocumentLang(newLocale);
        setDocumentDirectionPerLocale(newLocale);
      },
      { immediate: true }
    );
  },
});
</script>
<style lang="scss">
@import "../shared/styles/app";
.router-view {
  height: 100%;
}
.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.footer {
  flex-grow: 0;
  margin-top: auto;
}
</style>
