import { defineComponent } from "vue";
import { fieldExists, getField, setField } from "@/shared/utils";

const SUPPORT_PASSWORD_POLICY = {
  MinimumLength: 12,
  RequireUppercase: true,
  RequireLowercase: true,
  RequireNumbers: true,
  RequireSymbols: true,
  TemporaryPasswordValidityDays: 14,
};

export default defineComponent({
  methods: {
    validateState(name: string): boolean {
      if (name.includes(".")) {
        const sn = name.split(".");
        const { $dirty, $error } = this.v$.inputFields[sn[0]][sn[1]];
        return $dirty ? !$error : null;
      } else {
        const { $dirty, $error } = this.v$.inputFields[name];
        return $dirty ? !$error : null;
      }
    },
    getInputMaxChar(inputFieldType: string) {
      switch (inputFieldType) {
        case "email":
          return 254; // https://tools.ietf.org/html/rfc5321#section-4.5.3

        case "password":
          return 99; // https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html

        case "password-reset-code":
          return 6; // Cognito uses 6

        case "phone":
          return 30; // Maximum digits is 15, but there can be decorations so double this

        case "first-name":
        case "last-name":
          return 35;

        case "country":
        case "subject-id":
        case "clinic-patient-id":
        case "patch-placement-name":
        case "clinic-name":
        case "clinic-city":
        case "validation-code":
        case "gsn":
          return 64;

        case "address":
          return 255;

        case "zip-code":
          return 5;

        case "notes":
        case "text":
        default:
          return 2048; // https://docs.aws.amazon.com/cognito/latest/developerguide/limits.html
      }
    },
    validateInputMinChar(inputField: string, minCharacters: number) {
      if (inputField && inputField.length >= minCharacters) {
        return true;
      }

      return false;
    },
    validateInputEmail(inputField: string): boolean {
      if (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(inputField)) {
        return true;
      }

      return false;
    },
    getInputNumberLimit(
      limitType: string,
      inputFieldType: string,
      units: string
    ): number {
      const unitsLowerCase = units ? units.toLowerCase() : units;
      let min = 0;
      let max = 0;
      let step = 0;

      switch (inputFieldType) {
        case "hr":
          min = 0;
          max = 250;
          step = 1;
          break;

        case "height":
          if (unitsLowerCase === "cm") {
            min = 0;
            max = 275;
            step = 1;
          } else if (unitsLowerCase === "inches") {
            min = 0;
            max = 108;
            step = 0.5;
          }
          break;

        case "weight":
          if (unitsLowerCase === "kg") {
            min = 0;
            max = 454;
            step = 0.1;
          } else if (unitsLowerCase === "lbs") {
            min = 0;
            max = 1000;
            step = 1;
          }
          break;

        case "session-duration":
          if (unitsLowerCase === "m" || unitsLowerCase === "minutes") {
            min = 1;
            max = 1440;
            step = 1;
          }
          break;

        case "kits-order-patient":
        case "hubs-order-patient":
        case "patchboxes-order-patient":
          if (unitsLowerCase === "count") {
            min = 0;
            max = 1;
            step = 1;
          }
          break;
      }

      switch (limitType) {
        case "min":
          return min;

        case "max":
          return max;

        case "step":
          return step;
      }

      return 0;
    },
    getInputPasswordRequirements() {
      if (this.$APP_TYPE === "support" || !this.$store.getters.siteConfig) {
        return this.getInputPasswordRequirementsRaw(SUPPORT_PASSWORD_POLICY);
      } else {
        const siteConfig = this.$store.getters.siteConfig;
        if (siteConfig?.Policies?.PasswordPolicy) {
          return this.getInputPasswordRequirementsRaw(
            siteConfig.Policies.PasswordPolicy
          );
        } else {
          return "";
        }
      }
    },
    getInputPasswordRequirementsRaw(passwordPolicy) {
      const passwordRequirements = [];
      if (passwordPolicy.RequireLowercase) {
        passwordRequirements.push("lowercase letters");
      }

      if (passwordPolicy.RequireUppercase) {
        passwordRequirements.push("uppercase letters");
      }

      if (passwordPolicy.RequireNumbers) {
        passwordRequirements.push("numbers");
      }

      if (passwordPolicy.RequireSymbols) {
        passwordRequirements.push("symbols");
      }

      const minumum = passwordPolicy.MinimumLength
        ? passwordPolicy.MinimumLength
        : 8;

      if (passwordRequirements.length > 0) {
        const lastElement: any = passwordRequirements.pop();

        if (passwordRequirements.length > 0) {
          let joinedRequirements = passwordRequirements.join(", ");
          joinedRequirements +=
            this.$t("forgotPasswordReset.passwordRequirementsAnd") +
            lastElement;
          return this.$t("forgotPasswordReset.passwordRequirementsCriteria", {
            minimum: minumum,
            criteria: joinedRequirements,
          });
        } else {
          return this.$t("forgotPasswordReset.passwordRequirementsCriteria", {
            minimum: minumum,
            criteria: lastElement,
          });
        }
      } else {
        return this.$t("forgotPasswordReset.passwordRequirements", {
          minimum: minumum,
        });
      }
    },
    validPassword(value: string): boolean {
      if (this.$APP_TYPE === "support" || !this.$store.getters.siteConfig) {
        return this.validPasswordRaw(value, SUPPORT_PASSWORD_POLICY);
      } else {
        const siteConfig = this.$store.getters.siteConfig;
        return this.validPasswordRaw(value, siteConfig.Policies.PasswordPolicy);
      }
    },
    // split into two functions so that I could easily test it
    validPasswordRaw(value: string, passwordPolicy: any): boolean {
      // if not truthy then it won't pass
      if (!value) return false;

      // if not less than minimum length then it won't pass
      if (value.length < passwordPolicy.MinimumLength) return false;

      // if we have a lowercase letter requirement then it must have one to pass
      if (passwordPolicy.RequireLowercase && !/[a-z]/.test(value)) return false;

      // if we have an uppercase letter requirement then it must have one to pass
      if (passwordPolicy.RequireUppercase && !/[A-Z]/.test(value)) return false;

      // if we have a number requirement then it must have one to pass
      if (passwordPolicy.RequireNumbers && !/\d/.test(value)) return false;

      // if we have a symbol requirement then it must have one to pass
      if (
        passwordPolicy.RequireSymbols &&
        !/[\^$*.\[\]{}\(\)?\-"!@#%&\/,><\’:;|_~`]/.test(value)
      ) {
        return false;
      }
      return true;
    },
    validateInputDate(inputField: string): boolean {
      if (/^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/.test(inputField)) {
        return true;
      }

      return false;
    },
    validateSkinTone(inputField: number): boolean {
      if (inputField === 1) return true;
      if (inputField === 2) return true;
      if (inputField === 3) return true;

      return false;
    },
    validateInputPhone(inputField: string): boolean {
      if (/^[0-9()+\- ]{10,}$/.test(inputField)) {
        return true;
      }

      return false;
    },
    validateInputZipCode(inputField: string): boolean {
      if (/^[0-9]{5}$/.test(inputField)) {
        return true;
      }

      return false;
    },
    toStringFromBoolean(value: boolean): string {
      if (value && value === true) {
        return "true";
      }

      return "false";
    },
    toBooleanFromString(value: string): boolean {
      if (value && value === "true") {
        return true;
      }

      return false;
    },
    getStatesSelect(): any {
      return [
        { value: null, text: "State", disabled: true },
        { value: "AK", text: "Alaska" },
        { value: "AL", text: "Alabama" },
        { value: "AR", text: "Arkansas" },
        { value: "AZ", text: "Arizona" },
        { value: "CA", text: "California" },
        { value: "CO", text: "Colorado" },
        { value: "CT", text: "Connecticut" },
        { value: "DE", text: "Delaware" },
        { value: "FL", text: "Florida" },
        { value: "GA", text: "Georgia" },
        { value: "HI", text: "Hawaii" },
        { value: "IA", text: "Iowa" },
        { value: "ID", text: "Idaho" },
        { value: "IL", text: "Illinois" },
        { value: "IN", text: "Indiana" },
        { value: "KS", text: "Kansas" },
        { value: "KY", text: "Kentucky" },
        { value: "LA", text: "Louisiana" },
        { value: "MA", text: "Massachusetts" },
        { value: "MD", text: "Maryland" },
        { value: "ME", text: "Maine" },
        { value: "MI", text: "Michigan" },
        { value: "MN", text: "Minnesota" },
        { value: "MO", text: "Missouri" },
        { value: "MS", text: "Mississippi" },
        { value: "MT", text: "Montana" },
        { value: "NC", text: "North Carolina" },
        { value: "ND", text: "North Dakota" },
        { value: "NE", text: "Nebraska" },
        { value: "NH", text: "New Hampshire" },
        { value: "NJ", text: "New Jersey" },
        { value: "NM", text: "New Mexico" },
        { value: "NV", text: "Nevada" },
        { value: "NY", text: "New York" },
        { value: "OH", text: "Ohio" },
        { value: "OK", text: "Oklahoma" },
        { value: "OR", text: "Oregon" },
        { value: "PA", text: "Pennsylvania" },
        { value: "RI", text: "Rhode Island" },
        { value: "SC", text: "South Carolina" },
        { value: "SD", text: "South Dakota" },
        { value: "TN", text: "Tennessee" },
        { value: "TX", text: "Texas" },
        { value: "UT", text: "Utah" },
        { value: "VA", text: "Virginia" },
        { value: "VT", text: "Vermont" },
        { value: "WA", text: "Washington" },
        { value: "WI", text: "Wisconsin" },
        { value: "WV", text: "West Virginia" },
        { value: "WY", text: "Wyoming" },
      ];
    },
    getFieldChanges(
      current: any,
      updated: any,
      fieldMappingCurrentToUpdated: any
    ) {
      const fieldChanges = {};

      for (const currentKey in fieldMappingCurrentToUpdated) {
        const newKey = fieldMappingCurrentToUpdated[currentKey];

        if (getField(current, currentKey) !== getField(updated, newKey)) {
          const currentKeySplit = currentKey.split(".");

          // If the current key is a nested key, we need to make sure to include the entire nest object
          // in the field changes, as the API typically does not handle merging these changes.
          let lastSubKey = null;
          for (let i = 0; i < currentKeySplit.length - 1; i++) {
            const currentSubKey: any =
              lastSubKey === null
                ? currentKeySplit[i]
                : lastSubKey + "." + currentKeySplit[i];

            if (!fieldExists(fieldChanges, currentSubKey)) {
              setField(
                fieldChanges,
                currentSubKey,
                Object.assign({}, getField(current, currentSubKey))
              );
            }

            lastSubKey = currentSubKey;
          }

          setField(fieldChanges, currentKey, getField(updated, newKey));
        }
      }

      return fieldChanges;
    },
  },
});
