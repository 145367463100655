<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :show-last-viewed="false"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    >
    </sub-header>
    <b-container fluid class="hub-data-container">
      <div v-if="!hubDetailsResult">
        <span>{{ $t("common.loading") }}</span>
      </div>
      <div v-else class="hub-details-container">
        <div class="hub-info-item-container">
          <div>
            <header-container :header-text="$t('hub.detailsHeader')" />
            <div class="hub-info-items-container">
              <info-item :item="{ label: $t('hub.detailsLabelClinic') }">
                <template #value>
                  <span v-html="formattedClinicURL"></span>
                </template>
              </info-item>
              <info-item :item="{ label: $t('hub.detailsLabelPatient') }">
                <template #value>
                  <span v-html="formattedPatientURL"></span>
                </template>
              </info-item>
              <info-item
                :item="{
                  label: $t('hub.detailsLabelGSN'),
                  value: hubDetails.hub_id,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelDeviceID'),
                  value: hubDetails.device_id,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelHardware'),
                  value: hubDetails.hub_hardware,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelFirmware'),
                  value: hubDetails.hub_firmware,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelSoftware'),
                  value: hubDetails.hub_software,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelIMEI'),
                  value: hubDetails.imei,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelSimCardNumber'),
                  value: hubDetails.sim_card_number,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelModemType'),
                  value: hubDetails.modem_type,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelVPNHostname'),
                  value: hubDetails.vpn_hostname,
                }"
              />
              <info-item
                :item="{
                  label: $t('hub.detailsLabelVPNIP'),
                  value: hubDetails.vpn_ip,
                }"
              />
            </div>
            <div class="container-divider"></div>
            <header-container
              id="hologram-header-container"
              :header-text="$t('hub.detailsHeaderThirdParty')"
            />
            <div>
              <info-item :item="{ label: $t('hub.detailsLabelHologramLink') }">
                <template #value>
                  <span v-html="formattedHologramURL"></span>
                </template>
              </info-item>
              <info-item
                :item="{
                  label: $t('hub.detailsLabelHologramLastConnected'),
                  value: formattedLastConnectTime,
                }"
              />
              <info-item :item="{ label: $t('hub.detailsLabelMenderLink') }">
                <template #value>
                  <a href="https://hosted.mender.io/ui/devices/accepted">{{
                    $t("common.link")
                  }}</a>
                </template>
              </info-item>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import HeaderContainer from "@/shared/components/Primitives/HeaderContainer.vue";
import InfoItem from "@/shared/components/Primitives/InfoItem.vue";

export default {
  components: { HeaderContainer, InfoItem },
  data: function () {
    return {
      // Subheader Configuration
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewKitHubPatchListWithBack"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      // Hub Data
      hubDetailsResult: false,
      hubDetails: null,
    };
  },
  computed: {
    formattedLastConnectTime: function () {
      if (!this.hubDetails.hologram_data.last_connect_time) {
        return "";
      } else {
        // adding Z because this is a UTC date even though it isn't formatted like one
        return this.$d(
          new Date(`${this.hubDetails.hologram_data.last_connect_time}Z`),
          "monitoring"
        );
      }
    },
    formattedClinicURL: function () {
      return this?.hubDetails?.clinic_id
        ? '<a href="/clinic-detail/' +
            this.hubDetails.clinic_id +
            '" target="_blank" rel="noopener noreferrer">Link</a>'
        : "-";
    },
    formattedHologramURL: function () {
      return this?.hubDetails?.hologram_data?.link
        ? `<a href="${this.hubDetails?.hologram_data?.link}" target="_blank" rel="noopener noreferrer">Link</a>`
        : "-";
    },
    formattedPatientURL: function () {
      if (this?.hubDetails?.patient_id && this?.hubDetails?.clinic_id) {
        return `<a href="/patient-detail/${this.hubDetails.clinic_id}/${this.hubDetails.patient_id}" target="_blank" rel="noopener noreferrer">Link</a>`;
      } else {
        return "-";
      }
    },
    hubId: function () {
      return this.$route.params.hubId;
    },
  },
  created() {
    this.getHubDetails();
  },
  methods: {
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    getHubDetails: function () {
      this.api
        .v1HubsHubIdGet(this.hubId)
        .then((results) => {
          this.hubDetails = results.data;
          this.hubDetailsResult = true;
        })
        .catch((error) => {
          this.hubDetailsResult = false;
          // Get the error and display the error
          this.showApiResponseError(error, this.$t("patient.errorLoad"));
        });
    },
  },
};
</script>

<style lang="scss">
.hub-details-container {
  display: flex;
}
.hub-info-item-container {
  flex: 0;
  margin-right: 30px;
  white-space: nowrap;
}
.hub-detail-log-container {
  flex: 1;
}
#hologram-header-container {
  margin-top: 16px;
}
.hub-info-items-container {
  margin-bottom: 40px;
}
.hub-info-item-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  margin-top: 8px;
}

.hub-info-label {
  color: $gray-dark;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;

  text-transform: uppercase;
}

.hub-info-value {
  color: $gray-dark;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: right;
}

.logs-container {
  width: 100%;
  height: 500px;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-top: 8px;
}

.hidden-logs-container {
  background-color: $gray-light;
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.log-white-background {
  background-color: white;
}

.log-gray-background {
  background-color: $gray-light;
}
</style>
