<script lang="ts">
import { BFormCheckbox, BFormTimepicker } from "bootstrap-vue";

export default {
  name: "DialysisSchedule",
  props: {
    schedule: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allOptions: [
        { text: "Sunday", day_of_week: 0, time_of_day: null, active: false },
        { text: "Monday", day_of_week: 1, time_of_day: null, active: false },
        { text: "Tuesday", day_of_week: 2, time_of_day: null, active: false },
        { text: "Wednesday", day_of_week: 3, time_of_day: null, active: false },
        { text: "Thursday", day_of_week: 4, time_of_day: null, active: false },
        { text: "Friday", day_of_week: 5, time_of_day: null, active: false },
        { text: "Saturday", day_of_week: 6, time_of_day: null, active: false },
      ],
    };
  },
  computed: {
    // This will merge the schedule prop with the allOptions data to allow people to edit the schedule and add new options
    mergedSchedule() {
      return this.allOptions.map((option) => {
        const index = this.schedule.findIndex(
          (scheduleOption) => scheduleOption.day_of_week === option.day_of_week
        );
        if (index !== -1) {
          return { ...option, ...this.schedule[index] };
        }
        return option;
      });
    },
  },
  methods: {
    toggleScheduleActive(dayOfWeek) {
      const index = this.schedule.findIndex(
        (option) => option.day_of_week === dayOfWeek
      );
      if (index !== -1) {
        const updatedSchedule = [...this.schedule];
        updatedSchedule[index] = {
          ...updatedSchedule[index],
          active: !updatedSchedule[index].active,
        };
        if (updatedSchedule[index].time_of_day === null) {
          updatedSchedule[index] = {
            ...updatedSchedule[index],
            time_of_day: "08:00:00",
          };
        }
        this.$emit("update:schedule", updatedSchedule);
      } else {
        const newOption = {
          ...this.allOptions.find((option) => option.day_of_week === dayOfWeek),
          time_of_day: "08:00:00",
          active: true,
        };
        this.$emit("update:schedule", [...this.schedule, newOption]);
      }
    },
    changeTOD(dayOfWeek, time) {
      const index = this.schedule.findIndex(
        (option) => option.day_of_week === dayOfWeek
      );
      if (index !== -1) {
        const updatedSchedule = [...this.schedule];
        updatedSchedule[index] = {
          ...updatedSchedule[index],
          time_of_day: time,
        };
        this.$emit("update:schedule", updatedSchedule);
      }
    },
  },
};
</script>

<template>
  <div>
    <div
      v-for="scheduleOption in mergedSchedule"
      :key="scheduleOption.day_of_week"
      v-b-tooltip.hover="disabled ? $t('patient.tooltipTimezone') : ''"
      class="tw-flex tw-justify-between tw-h-12"
    >
      <div class="tw-w-40">
        <b-form-checkbox
          :key="scheduleOption.day_of_week"
          :disabled="disabled"
          :checked="scheduleOption.active"
          @change="toggleScheduleActive(scheduleOption.day_of_week)"
        >
          {{ scheduleOption.text }}
        </b-form-checkbox>
      </div>
      <div v-if="scheduleOption.active" class="tw-w-80">
        <b-form-timepicker
          :disabled="disabled"
          :value="scheduleOption.time_of_day"
          @input="changeTOD(scheduleOption.day_of_week, $event)"
        />
      </div>
    </div>
  </div>
</template>
