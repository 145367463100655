<template>
  <b-modal :id="modalId" hide-header hide-footer @show="onShow" @hide="onHide">
    <div class="audio-file-list-header">
      <span>{{ titleFormatted }}</span>
    </div>

    <div v-if="isMicrosoftExplorer()">
      <bgsound
        id="audio-file-bg-player"
        ref="audio-file-bg-player"
        :src="audioFileToPlayBg"
      />
    </div>
    <div
      v-if="gettingAudioItems"
      class="text-center mb-3 d-flex justify-content-center"
    >
      <b-spinner
        style="color: #8768ad; width: 100px; height: 100px"
      ></b-spinner>
    </div>
    <div v-else-if="hasAudioItems">
      <div class="audio-file-list-download-all">
        <span
          ><button class="button-link" @click.prevent="downloadFiles">
            {{ $t("audio.buttonDownloadAll") }}
          </button></span
        >
      </div>

      <div
        v-for="(audioItem, index) in audioItems"
        :key="getAudioId(index, audioItem)"
      >
        <div class="sound-player-container">
          <div class="sound-player-header">
            <span class="sound-player-time">{{
              getAudioTimeOfDay(audioItem.x)
            }}</span>
            <button
              v-if="showEngineeringLinks"
              class="button-link"
              @click.prevent="navToEngineering(audioItem)"
            >
              {{ $t("audio.buttonEngineering") }}
            </button>
            <button
              :id="'audio-file-download-' + getAudioId(index, audioItem)"
              class="button-link"
              @click.prevent="downloadFile"
            >
              {{ $t("audio.buttonDownload") }}
            </button>
          </div>

          <div
            v-if="!isMicrosoftExplorer()"
            class="sound-player-seek-container"
          >
            <audio
              :id="'audio-files-player-' + getAudioId(index, audioItem)"
              :ref="getAudioId(index, audioItem)"
              @timeupdate="onAudioTimeUpdate"
              @durationchange="onAudioDurationUpdate"
              @error="onAudioError"
            >
              <source :src="audioItem.url" type="audio/wav" />
              {{ $t("audio.errorAudioBrowserNotSupported") }}
            </audio>
            <span class="sound-player-elapsed-time">{{
              formatAudioElapsedTime(getAudioId(index, audioItem))
            }}</span>
            <input
              :id="'audio-files-seek-bar-' + getAudioId(index, audioItem)"
              type="range"
              min="0"
              :max="getAudioDuration(getAudioId(index, audioItem))"
              step="1"
              :value="getAudioElapsedTime(getAudioId(index, audioItem))"
              class="sound-player-seek-bar"
              @change="onAudioSeekChange"
              @mousedown="onAudioSeekDragStart"
              @mouseup="onAudioSeekDragStop"
            />
            <span class="sound-player-duration">{{
              formatAudioDuration(getAudioId(index, audioItem))
            }}</span>
          </div>

          <div class="sound-player-controls-container">
            <SVGIconWrapper
              :id="'audio-file-play-' + getAudioId(index, audioItem)"
              clickable
              class="button-link"
              :size="32"
              @click.prevent="play(getAudioId(index, audioItem))"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z"
                  clip-rule="evenodd"
                />
              </svg>
            </SVGIconWrapper>
            <SVGIconWrapper
              :id="'audio-file-play-' + getAudioId(index, audioItem)"
              clickable
              class="button-link"
              :size="32"
              @click.prevent="pauseStop(getAudioId(index, audioItem))"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
              >
                <path
                  fill-rule="evenodd"
                  d="M6.75 5.25a.75.75 0 0 1 .75-.75H9a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H7.5a.75.75 0 0 1-.75-.75V5.25Zm7.5 0A.75.75 0 0 1 15 4.5h1.5a.75.75 0 0 1 .75.75v13.5a.75.75 0 0 1-.75.75H15a.75.75 0 0 1-.75-.75V5.25Z"
                  clip-rule="evenodd"
                />
              </svg>
            </SVGIconWrapper>
          </div>
        </div>
        <div class="container-divider"></div>
      </div>
    </div>
    <div v-else class="audio-file-container-empty">
      <span>{{ $t("audio.errorNoAudioFiles") }}</span>
    </div>
    <div id="audio-file-button-container" class="buttons-container">
      <button id="close-button" class="button-negative" @click="close">
        Close
      </button>
    </div>
  </b-modal>
</template>
<script>
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default {
  components: { SVGIconWrapper },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    start: {
      type: Number,
      default: null,
    },
    stop: {
      type: Number,
      default: null,
    },
    showEngineeringLinks: {
      type: Boolean,
      default: false,
    },
    patientid: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      audioItemsIndex: null,
      audioFileToPlayBg: null,
      audioBeingDragged: {},
      audioElapsedTimes: {},
      audioDurations: {},
      audioItems: [],
      gettingAudioItems: false,
    };
  },
  computed: {
    titleFormatted: function () {
      if (this.start) {
        // if true then we need to show a date range
        if (this.start && this.stop) {
          // const beginRange = new Date(this.audioItems[0].x * 1000);
          // const endRange = new Date(this.audioItems[this.audioItems.length] * 1000);
          return `${this.convertDateString(
            this.start
          )} - ${this.convertDateString(this.stop)}`;
        } else {
          return `${this.convertDateString(this.start)}`;
        }
      }
      return null;
    },
    hasAudioItems: function () {
      return this.audioItems && this.audioItems.length > 0;
    },
  },
  watch: {
    start: function () {
      this.getAudioItems();
    },
  },
  mounted() {
    this.clearModal();
  },
  methods: {
    clearModal: function () {
      this.audioItemsIndex = null;
      this.audioFileToPlayBg = null;
      this.audioBeingDragged = {};
      this.audioElapsedTimes = {};
      this.audioDurations = {};
      this.audioItems = [];
      this.gettingAudioItems = false;
    },
    getAudioItems: async function () {
      if (this.start) {
        this.gettingAudioItems = true;
        try {
          const response = await this.api.v1DataPatientidDatatypesGet(
            this.patientid,
            "audio",
            this.start,
            this.stop ? this.stop : undefined,
            undefined,
            undefined,
            undefined,
            true
          );
          this.audioItems = response?.data?.audio?.data;
        } catch (error) {
          this.showApiResponseError(
            error,
            this.$t("patient.errorLoadAuscultation")
          );
        } finally {
          this.gettingAudioItems = false;
        }
      }
    },
    getAudioId: function (index, item) {
      if (item && index !== false) {
        return index + "_" + item.x;
      }

      return null;
    },
    getAudioIdFromElementId: function (elementId) {
      const splitId = elementId.split("-");
      if (splitId && splitId.length > 0) {
        return splitId[splitId.length - 1];
      }

      return null;
    },
    getAudioPlayerForAudioId: function (audioId) {
      if (audioId) {
        return this.$refs[audioId][0];
      }

      return null;
    },
    getAudioTimeOfDay: function (epoch) {
      if (epoch) {
        return this.$d(new Date(epoch * 1000), "nHourMinute");
      }

      return "-";
    },
    getAudioElapsedTime: function (audioId) {
      if (this.audioElapsedTimes && audioId in this.audioElapsedTimes) {
        return this.audioElapsedTimes[audioId];
      }

      return 0;
    },
    formatAudioElapsedTime: function (audioId) {
      return this.formatSecondsToTime(this.getAudioElapsedTime(audioId));
    },
    getAudioDuration: function (audioId) {
      if (this.audioDurations && audioId in this.audioDurations) {
        return this.audioDurations[audioId];
      }

      return 0;
    },
    formatAudioDuration: function (audioId) {
      return this.formatSecondsToTime(this.getAudioDuration(audioId));
    },
    onAudioSeekChange: function (event) {
      const audioId = this.getAudioIdFromElementId(event.target.id);
      const player = this.getAudioPlayerForAudioId(audioId);
      if (player) {
        player.currentTime = event.target.value;
      }
    },
    onAudioSeekDragStart: function (event) {
      // Save a flag when the seek thumb might be dragged
      const audioId = this.getAudioIdFromElementId(event.target.id);
      this.audioBeingDragged[audioId] = true;
    },
    onAudioSeekDragStop: function (event) {
      // Save the flag when the seek thumb might be done dragging
      const audioId = this.getAudioIdFromElementId(event.target.id);
      delete this.audioBeingDragged[audioId];
    },
    onAudioTimeUpdate: function (event) {
      const audioId = this.getAudioIdFromElementId(event.target.id);
      const player = this.getAudioPlayerForAudioId(audioId);
      if (player) {
        if (audioId in this.audioBeingDragged === false) {
          // Don't set if currently being dragged as this will just can
          // the seek bar to jump around
          this.$set(this.audioElapsedTimes, audioId, player.currentTime);
        }
      }
    },
    onAudioDurationUpdate: function (event) {
      const audioId = this.getAudioIdFromElementId(event.target.id);
      const player = this.getAudioPlayerForAudioId(audioId);
      if (player) {
        this.$set(this.audioDurations, audioId, player.duration);
      }
    },
    onAudioError: function (event) {
      let errorMsg = "";

      switch (event.target.error.code) {
        case event.target.error.MEDIA_ERR_ABORTED:
          errorMsg = this.$t("audio.errorAborted");
          break;

        case event.target.error.MEDIA_ERR_NETWORK:
          errorMsg = this.$t("audio.errorNetwork");
          break;

        case event.target.error.MEDIA_ERR_DECODE:
          errorMsg = this.$t("audio.errorCorruption");
          break;

        case event.target.error.MEDIA_ERR_SRC_NOT_SUPPORTED:
          errorMsg = this.$t("audio.errorLoadingFile");
          break;

        default:
          errorMsg = this.$t("audio.errorUnknown");
          break;
      }

      this.$bus.emit("show-general-error", errorMsg);
    },
    play: function (audioId) {
      if (this.isMicrosoftExplorer()) {
        let audioUrl = this.getAudioUrlForEpoch(audioId);

        // Make sure if there is any audio file currently there that
        // it is blanked. This ensures as well that if we are playing
        // the same file, it'll work again.
        this.audioFileToPlayBg = null;

        // Do an audio file check first to ensure file exists
        // and there are permissions. This provides some error
        // checking ability for the background player.
        this.audioFileVerify(audioUrl, () => {
          this.audioFileToPlayBg = audioUrl;
        });
      } else {
        let player = this.getAudioPlayerForAudioId(audioId);
        if (player) {
          player.play();
        }
      }
    },
    audioFileVerify: function (url, nextStep) {
      // Verify that the file exists and there is access to it. This is used in a few
      // places to ensure there is still access to the file.
      //
      // It is an extra step for IE11 as there is no feedback from the background
      // player if there are file issues. Note that HEAD will not work with
      // these URLs, so we need to use GET, but with a 0 byte range. This
      // does the check that the file exists and there are no permissions issues.
      // The expectation is there there will be a limited access time range
      // for these files
      this.axios({
        method: "get",
        url: url,
        headers: {
          Range: "bytes=0-0",
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
        },
      })
        .then(() => {
          nextStep();
        })
        .catch((error) => {
          this.showApiResponseError(error, this.$t("audio.errorReceivingFile"));
        });
    },
    pauseStop: function (audioId) {
      if (this.isMicrosoftExplorer()) {
        this.audioFileToPlayBg = null;
      } else {
        let player = this.getAudioPlayerForAudioId(audioId);
        if (player) {
          player.pause();
        }
      }
    },
    getAudioUrlForEpoch: function (audioId) {
      if (audioId && this.hasAudioItems) {
        let audioIdSplit = audioId.split("_");
        if (audioIdSplit && audioIdSplit.length === 2) {
          let index = audioIdSplit[0];

          if (index in this.audioItems) {
            return this.audioItems[index].url;
          }
        }
      }

      return null;
    },
    navToEngineering: function (audioItem) {
      this.$emit("handle-engineering-link", "audio", audioItem.x);
    },
    downloadFile: function (event) {
      const audioId = this.getAudioIdFromElementId(event.target.id);
      const url = this.getAudioUrlForEpoch(audioId);

      if (url) {
        this.downloadURI(url);
      }
    },
    downloadFiles: function () {
      this.audioItems.forEach((item, i) => {
        if (item.url) {
          // Need to set a timeout because if multiple downloads occur
          // too quickly Chrome will ignore all but the first
          setTimeout(this.downloadURI, 1000 * i, item.url);
        }
      });
    },
    downloadURI: function (uri) {
      // Verify the URI before downloading. The files have a limited valdity time
      // and by doing this verification step it ensures that the user see a proper
      // error message rather than raw XML when things go wrong
      this.audioFileVerify(uri, function () {
        const link = document.createElement("a");
        // Should just use the name of the file if left blank
        link.setAttribute("download", "");
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    onShow: function () {
      // Clear any saved state
      this.audioElapsedTimes = {};
      this.audioDurations = {};
      this.audioBeingDragged = {};
      this.audioFileToPlayBg = null;
    },
    onHide: function () {
      // On close make sure to disable the bg file, otherwise
      // it will continue to play
      this.audioFileToPlayBg = null;
    },
    close: function () {
      // Make sure this is no longer set
      this.audioFileToPlayBg = null;

      // On success hide the modal
      this.$bvModal.hide(this.modalId);
    },
    convertDateString: function (x) {
      const date = new Date(x * 1000);
      return `${this.$d(date, "dayOfYear")} ${this.getAudioTimeOfDay(x)}`;
    },
  },
};
</script>
<style lang="scss">
.audio-file-list-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  font-size: 20px;
  line-height: 28px;
  color: $gray-dark;
  font-weight: bold;

  .button-link {
    color: $gray-dark;

    &:active,
    &:focus {
      color: $gray-dark;
    }

    &:disabled {
      color: $gray2-light;
    }
  }
}

.audio-file-container-empty {
  margin-top: 24px;
}

.sound-player-container {
  width: 100%;
  min-height: 81px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sound-player-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.sound-player-time {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-right: 8px;
}

.sound-player-seek-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  margin-top: 8px;

  .sound-player-elapsed-time {
    min-width: 25px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-right: 8px;
    text-align: left;
  }

  .sound-player-duration {
    min-width: 25px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-left: 8px;
    text-align: right;
  }
}

.sound-player-controls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  margin-top: 8px;

  svg {
    width: 16px !important;
    font-size: 16px;
    color: $gray-dark;
  }

  > *:not(:first-child) {
    margin-left: 8px;
  }
}
</style>
