<template>
  <div v-if="hubs?.length === 0">{{ $t("hubTable.noneFound") }}</div>
  <b-table
    v-else
    id="patch-table"
    class="basic-table"
    :fields="tableFields"
    :items="hubs"
    sort-by="index_id"
  >
    <template #cell(info)="data">
      <div>{{ $t("hubTable.sn") }}: {{ data.item.hub_id }}</div>
      <div>{{ $t("hubTable.mac") }}: {{ data.item.device_id }}</div>
      <div>
        <span>
          <a :href="`/hub-detail/${data.item.hub_id}`">
            {{ $t("hubPatch.itemHub") }}
          </a>
        </span>
      </div>
    </template>
    <template #cell(networking)="data">
      <div>{{ $t("hubTable.vpn") }}: {{ data.item.vpn_hostname }}</div>
    </template>
    <template #cell(versions)="data">
      <div>{{ $t("hubTable.hw") }}: {{ data.item.hub_hardware }}</div>
      <div>{{ $t("hubTable.fw") }}: {{ data.item.hub_firmware }}</div>
      <div>{{ $t("hubTable.sw") }}: {{ data.item.hub_software }}</div>
    </template>
    <template #cell(last_connect)="data">
      <div>
        {{
          data.item.last_connect
            ? $d(new Date(data.item.last_connect), "table")
            : null
        }}
      </div>
      <div
        v-html="
          getSupportInfoLinkTag(
            supportInfo,
            'engineering_hub_search',
            data.item,
            'Engineering'
          )
        "
      />
    </template>
    <template #cell(actions)="data">
      <button
        v-if="fieldExists(data.item, 'hub_id')"
        id="table-actions"
        type="button"
        class="button-link"
        @click.prevent="removeHub(data.item)"
      >
        <span>{{ $t("hubPatch.removeButton") }}</span>
      </button>
    </template>
  </b-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HubTable",
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    hubs: {
      type: Array,
      required: true,
    },
    supportInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableFields: [
        {
          key: "info",
          label: this.$t("hubTable.headerInfo"),
          sortable: false,
        },
        {
          key: "versions",
          label: this.$t("hubTable.headerVersions"),
          sortable: false,
        },
        {
          key: "networking",
          label: this.$t("hubTable.headerNetworking"),
          sortable: false,
        },
        {
          key: "last_connect",
          label: this.$t("hubTable.headerLastConnect"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("hubTable.headerActions"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    callRemoveHub: function (state, item) {
      if (state) {
        this.api
          .v1PatientsPatientIdHubsHubIdDelete(this.patientId, item.hub_id)
          .then(() => {
            this.$emit("refresh");
            this.$bus.emit(
              "show-general-success",
              this.$t("hubTable.removedHub")
            );
          })
          .catch((error) => {
            this.showApiResponseError(error, this.$t("hubTable.failedRemove"));
          });
      }
    },
    removeHub: function (item) {
      this.showConfirmationPrompt(
        this.$t("hubTable.confirm"),
        "remove-hub",
        (state) => this.callRemoveHub(state, item)
      );
    },
  },
});
</script>

<style scoped lang="scss">
.status {
  text-transform: uppercase;
}
</style>
