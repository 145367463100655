<template>
  <div>
    <sub-section-header :header-text="header" class="mb-1" />
    <div class="setting-section">
      <div
        :id="`${type}-low-select-container`"
        class="input-container-settings-select thresholds-container"
      >
        <label :for="`${type}-low-select`">{{
          $t("settingsClinician.notifyBelow")
        }}</label>
        <b-form-input
          :id="`${type}-low-select`"
          class="hh-threshold-input"
          :value="thresholdLow"
          :min="min"
          :max="maxOfMinInput"
          step="any"
          type="number"
          number
          :disabled="disabled"
          @change="thresholdLowHandler"
        />
        <label class="threshold-high-label" :for="`${type}-high-select`">{{
          $t("settingsClinician.notifyAbove")
        }}</label>
        <b-form-input
          :id="`${type}-high-select`"
          :min="minOfMaxInput"
          :max="max"
          step="any"
          type="number"
          number
          class="hh-threshold-input"
          :value="thresholdHigh"
          :disabled="disabled"
          @change="thresholdHighHandler"
        />
      </div>
    </div>
    <div class="setting-section">
      <div
        :id="`${type}-low-select-container`"
        class="input-container-settings-select"
      >
        <label :for="`${type}-low-select`">{{
          $t("settingsClinician.numberLowReadings")
        }}</label>
        <b-form-select
          :id="`${type}-low-select`"
          :value="numberLow"
          :options="hctHbRepeatedOptions"
          :disabled="disabled"
          @change="numberLowHandler"
        />
      </div>
      <div
        :id="`${type}-high-select-container`"
        class="input-container-settings-select"
      >
        <label :for="`${type}-high-select`">{{
          $t("settingsClinician.numberHighReadings")
        }}</label>
        <b-form-select
          :id="`${type}-high-select`"
          :value="numberHigh"
          :options="hctHbRepeatedOptions"
          :disabled="disabled"
          @change="numberHighHandler"
        />
      </div>
    </div>
    <div class="setting-section">
      <div :id="`${type}-container`" class="input-container-settings-select">
        <b-form-checkbox
          :id="`${type}-checkbox`"
          :disabled="disabled"
          :checked="notify"
          :name="`${type}-notify`"
          @change="notifyHandler"
        >
          {{ checkboxText }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";

const HCT_HIGH_MAX = 45;
const HGB_HIGH_MAX = 18;
const HCT_LOW_MIN = 20;
const HGB_LOW_MIN = 7;

export default {
  name: "HctOrHgbSettings",
  components: { SubSectionHeader },
  props: {
    type: {
      required: true,
      type: String,
      validator: function (value) {
        return ["hct", "hgb"].includes(value);
      },
    },
    notify: {
      required: true,
      type: Boolean,
    },
    thresholdLow: {
      required: true,
      type: Number,
    },
    thresholdHigh: {
      required: true,
      type: Number,
    },
    numberLow: {
      required: true,
      type: Number,
    },
    numberHigh: {
      required: true,
      type: Number,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    clinicIdParameter: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      hctHbRepeatedOptions: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  computed: {
    maxOfMinInput() {
      return this.thresholdHigh - 1;
    },
    minOfMaxInput() {
      return this.thresholdLow + 1;
    },
    max() {
      if (this.type === "hct") {
        return HCT_HIGH_MAX;
      } else {
        return HGB_HIGH_MAX;
      }
    },
    min() {
      if (this.type === "hct") {
        return HCT_LOW_MIN;
      } else {
        return HGB_LOW_MIN;
      }
    },
    header() {
      if (this.type === "hct") {
        return this.$t("settingsClinician.headingHCT");
      } else {
        return this.$t("settingsClinician.headingHGB");
      }
    },
    checkboxText() {
      if (this.type === "hct") {
        return this.$t("settingsClinician.notifyHCTWithRepeated");
      } else {
        return this.$t("settingsClinician.notifyHGBWithRepeated");
      }
    },
  },
  methods: {
    thresholdHighHandler(val) {
      this.$emit("update:threshold-high", Number(val));
    },
    thresholdLowHandler(val) {
      this.$emit("update:threshold-low", Number(val));
    },
    numberHighHandler(val) {
      this.$emit("update:number-high", val);
    },
    numberLowHandler(val) {
      this.$emit("update:number-low", val);
    },
    notifyHandler(val) {
      this.$emit("update:notify", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.thesholds-container {
  margin-left: 24px;
  display: inline-block;
}
.threshold-high-label {
  margin-left: 30px;
}
.hh-threshold-input {
  width: 72px;
}
</style>
