<template>
  <div class="info-item-container">
    <span class="text label">{{ item.label }}</span>
    <slot name="value">
      <div class="text value">
        {{ item.value }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  name: "InfoItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.info-item-container {
  display: flex;
  align-items: center;
  flex-flow: nowrap;
  justify-content: space-between;
}

.text {
  color: $gray-dark;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}

.label {
  white-space: nowrap;
  align-self: flex-start;
}

.value {
  text-align: right;
  margin-left: 8px;
  overflow-wrap: anywhere;
  word-wrap: anywhere;
}
</style>
