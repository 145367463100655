<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtons"
      :navigation-buttons-right="navigationButtonsSupportRight"
      :action-buttons="actionButtons"
      @view-clinic-details-event="navViewClinicDetails"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
      @add-session-type-button-event="showAddSessionType"
    >
      <template #last-viewed>
        {{ formattedSessionTypeCount }}
      </template>
    </sub-header>

    <div class="container-fluid main-section session-type-list-container">
      <div
        v-if="sessionTypesLoadResult === null"
        id="session-type-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("session.loadingTypes") }}</span>
      </div>
      <div
        v-else-if="sessionTypes && sessionTypes.length > 0"
        id="session-type-list"
      >
        <session-type-list-table
          :clinic="clinic ? clinic : {}"
          :session-types="sessionTypes ? sessionTypes : []"
          @refresh-list="getSessionTypes"
        >
        </session-type-list-table>
      </div>
      <div v-else id="session-type-list-error" class="data-empty-container">
        <span>{{ $t("session.errorNoneFoundType") }}</span>
      </div>
    </div>

    <div v-if="clinic">
      <add-session-type-modal
        modal-id="add-session-type-modal"
        :clinic="clinic"
        @added="getSessionTypes"
      >
      </add-session-type-modal>
    </div>

    <b-popover
      id="add-session-type-first-popover"
      ref="add_session_type_first_popover"
      target="add-session-type-sub-header-button"
      triggers="blur"
      placement="bottom"
    >
      {{ $t("session.popoverClickToAddType") }}
    </b-popover>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      // Subheader
      navigationButtons: [
        {
          label: this.$t("navigation.viewClinicDetailsWithBack"),
          id: "view-clinic-details-sub-header-button",
          event: "view-clinic-details-event",
        },
      ],
      navigationButtonsSupportRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("action.addSessionType"),
          id: "add-session-type-sub-header-button",
          event: "add-session-type-button-event",
        },
      ],
      // Clinics Data
      clinicLoadResult: false,
      clinic: null,
      // Session Type Data
      sessionTypesLoadResult: false,
      sessionTypes: null,
    };
  },
  computed: {
    clinicIdParameter: function () {
      // Clinic id is converted to a string in the router, we require it as a integer
      return parseInt(this.$route.params.clinicId, 10);
    },
    formattedSessionTypeCount: function () {
      if (this.clinic && this.clinic.patchlocations) {
        return this.$t("session.labelTotalSessionTypes", {
          count: this.sessionTypes.length,
        });
      }

      return "";
    },
  },
  mounted: function () {
    // This needs to be in mounted rather than created because the popover
    // might be shown on the result of the next call
    this.getClinic();
    this.getSessionTypes();
  },
  methods: {
    getClinic: function () {
      if (this.clinicIdParameter < 0) {
        this.$bus.emit(
          "show-general-error",
          this.$t("clinic.errorUnspecified")
        );
      } else {
        this.clinics = null;
        this.clinicResult = null;

        this.api
          .v1ClinicClinicidGet(this.clinicIdParameter)
          .then((response) => {
            this.clinic = response.data.clinic;
            this.clinicResult = true;
          })
          .catch((error) => {
            this.clinicResult = false;
            this.showApiResponseError(
              error,
              this.$t("clinic.errorLoadDetails")
            );
          });
      }
    },
    getSessionTypes() {
      this.sessionTypesLoadResult = null;
      this.sessionTypes = [];

      this.api
        .v1ClinicClinicidSessiontypesGet(this.clinicIdParameter)
        .then((response) => {
          this.sessionTypes = response.data.sessiontypes;
          this.sessionTypesLoadResult = true;

          // Check to see if the popover should be shown, which is only
          // if there are no session types loaded
          if (
            !this.sessionTypes ||
            (Array.isArray(this.sessionTypes) && this.sessionTypes.length === 0)
          ) {
            this.controlFirstLocationPopover(true);
          } else {
            this.controlFirstLocationPopover(false);
          }
        })
        .catch((error) => {
          this.sessionTypesLoadResult = false;

          // Make sure the popover is closed if it exists
          this.controlFirstLocationPopover(false);

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("session.errorLoadListTypes")
          );
        });
    },
    navViewClinicDetails: function () {
      this.$router.push({
        name: "clinic-detail",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    showAddSessionType: function () {
      this.$bvModal.show("add-session-type-modal");
    },
    controlFirstLocationPopover: function (show) {
      var popover = this.$refs.add_session_type_first_popover;

      if (popover) {
        popover.$emit(show ? "open" : "close");
      }
    },
  },
};
</script>
