<template>
  <div>
    <div fluid class="main-header-top"></div>
    <div v-if="$route.meta.showNavigation" fluid class="main-header">
      <div id="header-logo">
        <a @click.prevent="navToHome()">
          <img src="/alio_rms_logo.png" alt="main logo" />
        </a>
      </div>
      <div id="header-title">
        <span v-if="supportPortal">{{ clinicName }}</span>
        <span v-else>{{ formattedHeaderTitle }}</span>
      </div>
      <div
        v-if="isSmallScreen()"
        v-b-toggle.main-sidebar
        class="header-sidebar-button"
      >
        <SVGIconWrapper :size="48" class="icon" clickable>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="size-6"
          >
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
              clip-rule="evenodd"
            />
          </svg>
        </SVGIconWrapper>
      </div>
      <b-sidebar
        v-if="isSmallScreen()"
        id="main-sidebar"
        :title="formattedUserName"
        shadow
        right
        backdrop
      >
        <div class="header-info-container">
          <div v-if="supportPortal" class="header-help-container">
            {{ $t("common.supportPortal") }}
          </div>
          <div v-else class="header-help-container">
            <span>
              <button
                id="need-help-header-button"
                type="button"
                class="button-link"
                @click.prevent="showHelp()"
              >
                {{ $t("mainHeader.labelHelp") }}
              </button>
              <span class="contact-phone"> {{ supportPhoneNumber }}</span>
            </span>
          </div>
          <div class="header-clinician-info-container">
            <button
              id="logout-main-header-button"
              type="button"
              class="button-link"
              @click.prevent="navToLogin(null)"
            >
              <span>{{ $t("common.buttonLogout") }}</span>
            </button>
          </div>
          <div class="header-settings-container">
            <button
              id="settings-main-header-button"
              type="button"
              class="button-link"
              @click.prevent="$router.push({ name: 'settings' })"
            >
              <span>{{ $t("common.buttonSettings") }}</span>
            </button>
          </div>
        </div>
      </b-sidebar>
      <!--
        TODO(ryan): this is basically duplicating the above code for desktop where the sidebar isn't used... we should either break this into another
        component or some other way to do it in the same vue component
      -->
      <div v-else class="header-info-container">
        <div v-if="supportPortal" class="header-help-container">
          {{ $t("common.supportPortal") }}
        </div>
        <div v-else class="header-help-container">
          <span>
            <button
              id="need-help-header-button"
              type="button"
              class="button-link"
              @click.prevent="showHelp()"
            >
              {{ $t("mainHeader.labelHelp") }}
            </button>
            <span class="contact-phone"> {{ supportPhoneNumber }} </span>
          </span>
        </div>
        <div class="header-clinician-info-container">
          <span id="user-name"
            >{{ formattedUserName }}
            <button
              id="logout-main-header-button"
              type="button"
              class="button-link"
              @click.prevent="navToLogin(null)"
            >
              <span>{{ $t("common.buttonLogout") }}</span>
            </button>
          </span>
        </div>
        <div class="header-settings-container">
          <button
            id="settings-main-header-button"
            type="button"
            class="button-link"
            @click.prevent="$router.push({ name: 'settings' })"
          >
            <span>{{ $t("common.buttonSettings") }}</span>
          </button>
        </div>
      </div>
    </div>
    <div v-else class="no-header">
      <div class="header-info-container">
        <div class="header-help-container-no-header">
          {{ $t("mainHeader.labelHelp") }}
          <span class="contact-phone"> {{ supportPhoneNumber }} </span>
        </div>
      </div>
    </div>

    <instructions-for-use-modal modal-id="instructions-for-use-modal">
    </instructions-for-use-modal>
  </div>
</template>
<script>
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default {
  components: { SVGIconWrapper },
  props: {
    supportPortal: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      clinic: null,
    };
  },
  computed: {
    supportPhoneNumber() {
      if (import.meta.env.VITE_ALIO_PHONE_NUMBER) {
        return import.meta.env.VITE_ALIO_PHONE_NUMBER;
      }
      return "1-855-979-1600";
    },
    formattedHeaderTitle() {
      if (this.clinic !== null) {
        return this.clinic.name;
      }
      return null;
    },
    formattedUserName() {
      return this.formatUserName(this.$store.getters.user);
    },
    clinicName() {
      if (this.$route?.params?.clinicId) {
        return this.$store.getters.clinic?.name;
      }
      return null;
    },
  },
  watch: {
    "$route.params.clinicId": {
      handler: function (clinicId) {
        if (this.isSupportApp()) {
          if (clinicId) {
            this.$store.commit("updateClinicId", clinicId);
            this.getClinic();
          } else {
            this.$store.commit("updateClinicId", null);
            this.$store.commit("updateClinic", {});
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.$bus.on("current-clinic-id-updated", this.getClinic);
    this.getClinic();
    window.addEventListener("resize", this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
    this.$bus.off("current-clinic-id-updated", this.getClinic);
  },
  methods: {
    handleWindowResize: function () {
      this.$forceUpdate();
    },
    isSmallScreen: function () {
      return window.innerWidth < 959;
    },
    getClinic: function () {
      // Only get the current clinic if the current clinic ID is not null.
      // For the Support Portal this will only be in certain scenarios
      this.clinic = null;

      if (this.getCurrentClinicId() !== null) {
        this.getClinicInfo(
          this.getCurrentClinicId(),
          false,
          (clinic) => {
            this.clinic = clinic;
            this.$store.commit("updateClinic", clinic);
          },
          (error) => {
            this.logApiResponseError(
              "Get Clinic",
              error,
              this.$t("clinic.errorLoadDetails")
            );
          }
        );
      }
    },
    showHelp: function () {
      this.$bvModal.show("instructions-for-use-modal");
    },
  },
};
</script>
<style lang="scss">
.main-header-top {
  height: 16px;
  width: 100%;
  background: linear-gradient(to left, $primary-light, $primary-dark);
}

.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 100px;
  padding: 0 min(40px, 5%);
  width: 100%;

  background: $gray2-light;
  color: $gray-dark;
  border-bottom: 1px solid $gray-light;

  //> * {
  //  flex: 1 1 0px;
  //}
}

#header-logo {
  flex-grow: 0;

  img {
    max-height: 100%;
    width: 100px;
  }
}

#header-title {
  max-height: 100%;

  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 38px;
  overflow: hidden;
  text-align: center;
  margin-left: 20px;
  @media (max-width: 500px) {
    text-align: center;
    margin: auto;
  }
}

.header-sidebar-button {
  flex-grow: 0;
  font-size: 2em;
  padding: 10px 20px;
}

#main-sidebar .header-info-container {
  margin: 10px 20px;
}

#main-sidebar .b-sidebar-header button {
  width: auto;
  min-width: auto;
  font-size: 1.5em;
}

#main-sidebar .b-sidebar-header {
  font-size: 2rem;
}

.header-info-container {
  max-height: 100%;

  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  overflow: hidden;
  text-align: right;

  @media (max-width: 959px) {
    button.button-link {
      font-size: 30px;
      line-height: 34px;
    }
    font-size: 30px;
    line-height: 34px;
  }

  > * {
    margin-top: 8px;
    display: block;

    @media (max-width: 959px) {
      margin: 30px 0;
    }
  }

  #need-help-header-button {
    margin-right: 8px;
  }

  #logout-main-header-button {
    margin-left: 8px;
  }

  .header-help-container-no-header {
    color: #58585b;
    margin-top: 19px;
    margin-right: 19px;
    font-size: 16px;
    line-height: 20px;
  }
}

.contact-phone {
  white-space: nowrap;
}
</style>
