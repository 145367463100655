<script lang="ts">
import { defineComponent } from "vue";
import PatientForm from "@/shared/components/PatientForm.vue";
import { getChangedProperties } from "@/shared/utils";
import FormLayout from "@/shared/layouts/FormLayout.vue";

export default defineComponent({
  name: "PatientEdit",
  components: { FormLayout, PatientForm },
  data() {
    return {
      patient: null,
    };
  },
  mounted() {
    // Load the patient info
    this.api
      .v1PatientPatientidGet(this.$route.params.patientId)
      .then(async (response) => {
        if (
          response.data.patient.clinic !== parseInt(this.$route.params.clinicId)
        ) {
          this.$bus.emit(
            "show-general-error",
            this.$t("patient.errorInvalidClinic")
          );
        } else {
          this.patient = response.data.patient;

          // this is because we return the entire object in the post, but it's not all needed
          if (response.data.patient?.ordering_clinician) {
            this.patient.ordering_clinician =
              response.data.patient.ordering_clinician.id;
          }

          // addresses might be null
          if (!response.data.patient?.addresses) {
            this.patient.addresses = [];
          } else {
            // we send up country_id but receive country.id
            this.patient.addresses = this.patient.addresses.map((address) => {
              if (address.country) {
                return { ...address, country_id: address?.country?.id };
              }
              return address;
            });
          }
        }
      });
  },
  methods: {
    cancel() {
      // edit can only be triggered in the portal from the patient detail page
      this.$router.push({
        name: "patient-detail",
        params: {
          clinicId: this.$route.params.clinicId,
          patientId: this.$route.params.patientId,
        },
      });
    },
    showToast() {
      this.$root.$bvToast.toast(`Patient updated`, {
        title: "Changes saved!",
        toaster: "b-toaster-bottom-right",
      });
    },
    editPatient: function ({ formPatient }) {
      // always include addresses
      const updatepatient = {
        ...getChangedProperties(this.patient, formPatient),
        addresses: formPatient.addresses,
      };
      this.api
        .v1PatientPatientidPut(this.patient.id, {
          updatepatient,
        })
        .then(() => {
          this.showToast();
          this.$router.push({
            name: "patient-detail",
            params: {
              clinicId: this.$route.params.clinicId,
              patientId: this.$route.params.patientId,
            },
          });
        })
        .catch((error) => {
          // Get and show the error
          this.showApiResponseError(error, this.$t("patient.errorUpdate"));
        });
    },
  },
});
</script>

<template>
  <FormLayout>
    <PatientForm
      v-if="patient"
      :patient-to-edit="patient"
      :clinic-id="parseInt($route.params.clinicId)"
      @submit="editPatient"
      @cancel="cancel"
    />
  </FormLayout>
</template>

<style scoped lang="scss">
</style>
