<template>
  <div ref="skinDropdown" class="custom-select-wrapper">
    <div class="custom-select">
      <div
        tabindex="0"
        class="selected-option"
        @click="toggleDropdown"
        @keydown.enter="toggleDropdown"
      >
        <div
          v-if="selectedOption?.text"
          class="selected"
          :style="{ background: selectedOption.color }"
        />
        <div v-else>
          {{ $t("skinTone.pleaseSelect") }}
        </div>
      </div>
      <div v-if="showDropdown" class="options-container">
        <p class="please-select">{{ $t("skinTone.pleaseSelect") }}</p>
        <div
          v-for="option in options"
          :key="option.value"
          tabindex="0"
          class="option"
          :style="{ background: option.color }"
          @click="selectOption(option)"
          @keydown.enter="selectOption(option)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skin: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      showDropdown: false,
      selectedOption: {},
      options: [
        {
          value: 1,
          text: "Light",
          color: "linear-gradient(to right, #f6ede4, #f3e7db)",
        },
        {
          value: 2,
          text: "Medium",
          color: "linear-gradient(to right, #f7ead0, #d7bd96)",
        },
        {
          value: 3,
          text: "Dark",
          color: "linear-gradient(to right, #a07e56, #292420)",
        },
      ],
    };
  },
  watch: {
    skin(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.setSkinOption();
      }
    },
  },
  mounted() {
    this.setSkinOption();
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    handleClickOutside(event) {
      if (
        this.$refs.skinDropdown &&
        !this.$refs.skinDropdown.contains(event.target)
      ) {
        this.showDropdown = false;
      }
    },
    setSkinOption() {
      if (this.skin) {
        for (let option of this.options) {
          if (option.value === this.skin) {
            this.selectedOption = option;
          }
        }
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.showDropdown = false;
      // Emit an event with the selected value for parent components
      this.$emit("input", this.selectedOption.value);
    },
  },
};
</script>

<style scoped>
.please-select {
  text-align: center;
  color: #eeeeee;
}
.custom-select-wrapper {
  position: relative;
}
.custom-select {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  user-select: none;
  padding-left: 0px;
}

.selected {
  padding: 0px;
  margin: 0px;
  height: 16px;
}

.selected-option {
  //padding: 10px;
  //background-color: #eee;
  cursor: pointer;
  padding: 0px;
  //border: 1px solid #ccc;
}

.options-container {
  position: absolute;
  bottom: 100%;
  width: 100%;
  z-index: 10;
  background-color: #808080;
  border: 1px solid #808080;
}

.option {
  height: 54px;
  margin: 24px;
  cursor: pointer;
  background-size: 100%;
  background-repeat: no-repeat;
  border-radius: 8px;
}

.option:hover {
  opacity: 0.8;
}
</style>
