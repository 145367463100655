import Vue from "vue";
import VueRouter from "vue-router";
import { getCurrentUser } from "aws-amplify/auth";
import axios from "axios";

// Specific pages
import Login from "@/support/pages/Login.vue";
import LoginDefault from "@/support/pages/LoginDefault.vue";
import ClinicList from "@/support/pages/ClinicList.vue";
import ClinicDetail from "@/support/pages/ClinicDetail.vue";
import ClinicUserList from "@/support/pages/ClinicUserList.vue";
import PatchLocationList from "@/support/pages/PatchLocationList.vue";
import SessionTypeList from "@/support/pages/SessionTypeList.vue";
import PatientListAll from "@/support/pages/PatientListAll.vue";
import KitHubPatchList from "@/support/pages/KitHubPatchList.vue";
import HubDetail from "@/support/pages/HubDetail.vue";
import Settings from "@/support/pages/Settings.vue";

// Common pages
import ClinicNotificationSettings from "@/support/pages/ClinicNotificationSettings.vue";
import { sharedRoutes } from "@/shared/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  routes: [
    {
      // On base redirect go to clinic-list, but if not currently
      // signed in, then go back to login
      path: "/",
      name: "find-home",
      redirect: { name: "clinic-list" },
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login/",
      name: "login",
      component: Login,
      meta: {
        showNavigation: false,
      },
    },
    {
      path: "/login/default",
      name: "login-default",
      component: LoginDefault,
      meta: {
        showNavigation: false,
      },
    },
    {
      path: "/clinic-list",
      name: "clinic-list",
      component: ClinicList,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/clinic-detail/:clinicId",
      name: "clinic-detail",
      component: ClinicDetail,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/clinic-detail/:clinicId/notification-settings",
      name: "clinic-notification-settings",
      component: ClinicNotificationSettings,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/clinic-user-list/:clinicId",
      name: "clinic-user-list",
      component: ClinicUserList,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/patch-location-list/:clinicId",
      name: "patch-location-list",
      component: PatchLocationList,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/session-type-list/:clinicId",
      name: "session-type-list",
      component: SessionTypeList,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/patient-list-all/",
      name: "patient-list-all",
      component: PatientListAll,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/kit-hub-patch-list/",
      name: "kit-hub-patch-list",
      component: KitHubPatchList,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/hub-detail/:hubId",
      name: "hub-detail",
      component: HubDetail,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    {
      path: "/settings/",
      name: "settings",
      component: Settings,
      meta: {
        requiresAuth: true,
        showNavigation: true,
      },
    },
    ...sharedRoutes,
    {
      path: "*",
      redirect: { name: "find-home" },
    },
  ],
});

// Authentication check, if not authorized, go back to login
router.beforeResolve((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    getCurrentUser()
      .then(() => {
        next();
      })
      .catch(() => {
        // If the 'to' was not already a redirect then use it as a next. If
        // it was redirected then we just ignore it as it means it was likely
        // going somewhere that does not exist
        let nextUrl = "redirectedFrom" in to ? null : to.path;

        next({
          name: "login",
          query: nextUrl !== null ? { next: nextUrl } : null,
        });
      });
  } else {
    next();
  }
});

// Add in support for cancelling existing axios commands on any navigation. In
// general all commands for a particular page are expected to be complete before
// going through any navigation.
let cancelSource = axios.CancelToken.source();

axios.interceptors.request.use(async (config) => {
  config.cancelToken = cancelSource.token;
  return config;
});

router.beforeEach((to, from, next) => {
  // Cancel any current requests.
  cancelSource.cancel("Operation canceled by navigation.");
  cancelSource = axios.CancelToken.source();

  // Continue on with the navigation
  next();
});

export default router;
