<template>
  <div class="header-container">
    <section-header :header-text="headerText" />
    <slot name="right">
      <button
        v-if="buttonText"
        type="button"
        class="button-link"
        @click.prevent="emitButtonClick"
      >
        {{ buttonText }}
      </button>
    </slot>
  </div>
</template>

<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default {
  name: "HeaderContainer",
  components: {
    SectionHeader,
  },
  props: {
    headerText: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: false,
      default: null,
    },
  },
  methods: {
    emitButtonClick() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;

  margin-top: 40px;
}
</style>
