<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="showModal">
    <section-header :header-text="$t('forgotPassword.title')" />
    <form @submit.prevent="submit">
      <div class="input-container">
        <input
          id="forgot-password-username"
          v-model="inputFields.username"
          type="email"
          :placeholder="$t('forgotPassword.fieldPlaceholderEmail')"
          :maxlength="getInputMaxChar('email')"
          autocomplete="username"
        />
      </div>
      <div id="forgot-buttons-container" class="buttons-container">
        <button
          id="forgot-password-submit-button"
          type="submit"
          :disabled="!submitEnabled() || !buttonsEnabled"
        >
          {{ $t("common.buttonSubmit") }}
        </button>
        <button
          id="forgot-password-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </form>
    <p id="forgot-password-email-info">{{ $t("forgotPassword.infoEmail") }}</p>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { Amplify } from "aws-amplify";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      buttonsEnabled: true,
      inputFields: {
        username: null,
      },
    };
  },
  methods: {
    submitEnabled: function () {
      if (this.validateInputEmail(this.inputFields.username)) {
        return true;
      }

      return false;
    },
    async submit() {
      this.buttonsEnabled = false;

      try {
        const client_id = Amplify.getConfig().Auth.Cognito.userPoolClientId;
        await this.api.v1ClinicuserForgotPasswordPost({
          email: this.inputFields.username,
          client_id,
        });
        // On success hide the modal
        this.$bvModal.hide(this.modalId);

        // Show a general success message
        this.$bus.emit(
          "show-general-success",
          this.$t("forgotPassword.success")
        );
      } catch (error) {
        this.showCognitoResponseError(
          error,
          this.$t("forgotPassword.errorPlaceholder")
        );
      } finally {
        this.buttonsEnabled = true;
      }
    },
    cancel: function () {
      this.$bvModal.hide(this.modalId);
    },
    showModal: function () {
      // Anytime this is shown, clear the input fields. This is so they
      // do not hold state when it might be shown a second time
      this.inputFields.username = null;
    },
  },
};
</script>
<style lang="scss">
#forgot-password-email-info {
  width: 239px;

  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;

  text-align: center;
}
</style>
