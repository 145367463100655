<template>
  <div>
    <sub-section-header
      class="mt-2 mb-1"
      :header-text="$t('settingsClinician.headingPotassium')"
    />
    <div v-if="ordinal">
      <div class="setting-section">
        <div
          id="potassium-high-container"
          class="input-container-settings-select"
        >
          <b-form-checkbox
            id="potassium-high-checkbox"
            :disabled="disabled"
            :checked="notifyHighPotassium"
            name="potassium-high"
            @change="notifyHighHandler"
          >
            {{ $t("settingsClinician.notifyHighPotassium") }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="setting-section">
        <div
          id="potassium-high-select"
          class="input-container-settings-select potassium-select"
        >
          <label for="potassium-high-select">{{
            $t("settingsClinician.numberHighReadings")
          }}</label>
          <b-form-select
            id="potassium-high-select"
            :value="numberHighPotassium"
            :options="potassiumNumberNotificationsOptions"
            :disabled="disabled"
            @change="numberHighHandler"
          />
        </div>
      </div>
      <div class="setting-section">
        <div
          id="potassium-low-container"
          class="input-container-settings-select"
        >
          <b-form-checkbox
            id="potassium-low-checkbox"
            :disabled="disabled"
            :checked="notifyLowPotassium"
            name="potassium-low"
            @change="notifyLowHandler"
          >
            {{ $t("settingsClinician.notifyLowPotassium") }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="setting-section">
        <div
          id="potassium-low-select"
          class="input-container-settings-select potassium-select"
        >
          <label for="potassium-low-select">{{
            $t("settingsClinician.numberLowReadings")
          }}</label>
          <b-form-select
            id="potassium-low-select"
            :value="numberLowPotassium"
            :options="potassiumNumberNotificationsOptions"
            :disabled="disabled"
            @change="numberLowHandler"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <div class="setting-section">
        <div
          id="potassium-dyskalemia-container"
          class="input-container-settings-select"
        >
          <b-form-checkbox
            id="potassium-dyskalemia-checkbox"
            :disabled="disabled"
            :checked="notifyDyskalemia"
            name="dyskalemia"
            @change="notifyDyskalemiaHandler"
          >
            {{ $t("settingsClinician.notifyDyskalemia") }}
          </b-form-checkbox>
        </div>
      </div>
      <div class="setting-section">
        <div
          id="potassium-dyskalemia-select-container"
          class="input-container-settings-select potassium-select"
        >
          <label for="potassium-dyskalemia-select">{{
            $t("settingsClinician.numberReadings")
          }}</label>
          <b-form-select
            id="potassium-dyskalemia-select"
            :value="numberDyskalemia"
            :options="potassiumNumberNotificationsOptions"
            :disabled="disabled"
            @change="numberDyskalemiaHandler"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";

export default {
  name: "PotassiumSettings",
  components: { SubSectionHeader },
  props: {
    notifyLowPotassium: {
      required: true,
      type: Boolean,
    },
    numberLowPotassium: {
      required: true,
      type: Number,
    },
    notifyHighPotassium: {
      required: true,
      type: Boolean,
    },
    numberHighPotassium: {
      required: true,
      type: Number,
    },
    notifyDyskalemia: {
      required: true,
      type: Boolean,
    },
    numberDyskalemia: {
      required: true,
      type: Number,
    },
    clinicIdParameter: {
      required: true,
      type: Number,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
    ordinal: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      potassiumNumberNotificationsOptions: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  methods: {
    notifyLowHandler(val) {
      this.$emit("update:notify-low-potassium", val);
    },
    numberLowHandler(val) {
      this.$emit("update:number-low-potassium", val);
    },
    notifyHighHandler(val) {
      this.$emit("update:notify-high-potassium", val);
    },
    numberHighHandler(val) {
      this.$emit("update:number-high-potassium", val);
    },
    notifyDyskalemiaHandler(val) {
      this.$emit("update:notify-dyskalemia", val);
    },
    numberDyskalemiaHandler(val) {
      this.$emit("update:number-dyskalemia", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.potassium-select {
  margin-left: 24px;
}
</style>
