<template>
  <div fluid class="sub-header">
    <div
      v-if="navigationButtonsLeft"
      class="sub-header-navigation-buttons-left"
    >
      <button
        v-for="item in navigationButtonsLeft"
        :id="item.id"
        :key="item.id"
        type="button"
        class="button-link"
        @click.prevent="buttonOnClick(item.event)"
      >
        {{ item.label }}
      </button>
    </div>
    <div v-else-if="showRefresh" class="sub-header-last-refresh-container">
      <span id="last-refresh-info">
        <slot name="last-refresh">
          {{ formattedlastRefreshInfo }}
          {{ formattedlastRefreshDate }}
        </slot>
      </span>
      <button
        v-if="showRefreshButton"
        id="refresh-sub-header-button"
        type="button"
        class="button-link"
        @click.prevent="sendRefresh"
      >
        {{ $t("common.buttonRefresh") }}
      </button>
    </div>
    <div v-else></div>
    <div class="sub-header-actions-containers">
      <slot name="action-buttons">
        <button
          v-for="item in actionButtons"
          :id="item.id"
          :key="item.id"
          type="button"
          class="sub-header-action-button"
          @click.prevent="buttonOnClick(item.event)"
        >
          {{ item.label }}
        </button>
      </slot>
    </div>
    <div class="sub-header-right">
      <div
        v-if="navigationButtonsRight"
        class="sub-header-navigation-buttons-right"
      >
        <button
          v-for="item in navigationButtonsRight"
          :id="item.id"
          :key="item.id"
          type="button"
          class="button-link"
          @click.prevent="buttonOnClick(item.event)"
        >
          {{ item.label }}
        </button>
      </div>
      <div class="sub-header-last-viewed-container">
        <span v-if="showLastViewed && lastViewedDate" id="last-viewed-info">
          <slot name="last-viewed">
            {{ formattedlastViewedInfo }}
            {{ formattedlastViewedDate }}
          </slot>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    patient: {
      type: Object,
      default: null,
    },
    showRefresh: {
      type: Boolean,
      default: true,
    },
    lastRefreshLabel: {
      type: String,
      default: null,
    },
    lastRefreshDate: {
      validator: function (value) {
        // Supports epoch or Date
        return (
          typeof value === null ||
          typeof value === "number" ||
          (typeof value === "object" && value instanceof Date)
        );
      },
      default: null,
    },
    showRefreshButton: {
      type: Boolean,
      default: true,
    },
    navigationButtonsLeft: {
      type: Array,
      default: null,
    },
    navigationButtonsRight: {
      type: Array,
      default: null,
    },
    actionButtons: {
      type: Array,
      default: null,
    },
    showLastViewed: {
      type: Boolean,
      default: true,
    },
    lastViewedDate: {
      validator: function (value) {
        // Supports epoch or Date
        return (
          typeof value === null ||
          typeof value === "number" ||
          (typeof value === "object" && value instanceof Date)
        );
      },
      default: null,
    },
    lastViewedClinician: {
      validator: function (value) {
        return (
          typeof value === null ||
          typeof value === "number" ||
          typeof value === "object"
        );
      },
      default: null,
    },
  },
  computed: {
    formattedlastRefreshInfo: function () {
      if (this.patient) {
        return this.$t("subHeader.labelDataCurrentOfName", {
          name: this.formatPatientName(this.patient),
        });
      }

      if (this.lastRefreshLabel) {
        return this.lastRefreshLabel;
      }

      return this.$t("subHeader.labelDataCurrentOf");
    },
    formattedlastRefreshDate: function () {
      if (this.lastRefreshDate) {
        return this.dateOrEpochFormat(this.lastRefreshDate, false);
      }

      return "-";
    },
    formattedlastViewedInfo: function () {
      if (this.patient) {
        return this.$t("subHeader.labelLastViewedName", {
          name: this.formatPatientName(this.patient),
        });
      }

      return this.$t("subHeader.labelLastViewed");
    },
    formattedlastViewedDate: function () {
      var date = this.lastViewedDate
        ? this.dateOrEpochFormat(this.lastViewedDate, false)
        : "-";
      var name = this.lastViewedClinician
        ? this.formatClinicUserName(this.lastViewedClinician)
        : "-";

      return this.$t("subHeader.labelLastViewedBy", { date: date, name: name });
    },
  },
  methods: {
    buttonOnClick: function (event) {
      if (event) {
        this.$emit(event);
      } else {
        console.log("No event for button!");
      }
    },
    sendRefresh: function () {
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="scss">
.sub-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  max-height: 64px;
  padding: 5px min(40px, 5%);

  background: $gray2-light;
  color: $gray-dark;
  border-bottom: 1px solid $gray-light;

  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;

  > * {
    flex: 1 1 0px;
    margin: auto;
  }
}

.sub-header-navigation-buttons-left {
  span {
    display: inline-block;
  }
}

.sub-header-last-refresh-container {
  @media (max-width: 500px) {
    display: none;
  }
  span {
    display: inline-block;
  }

  #last-refresh-info {
    font-style: italic;
    font-size: 11px;
    line-height: 15px;
  }

  #refresh-sub-header-button {
    margin-left: 8px;
  }
}

.sub-header-actions-containers {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sub-header-right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  flex-direction: column;
  > * {
    flex: 1 1 0px;
  }
}

.sub-header-last-viewed-container {
  display: flex;
  justify-content: flex-end;

  span {
    display: inline-block;
    text-align: end;
  }

  #last-viewed-info {
    font-style: italic;
    font-size: 11px;
    line-height: 15px;
  }

  @media (max-width: 500px) {
    display: none;
  }
}

.sub-header-action-button {
  margin: 0px 4px 0px 4px;
}
</style>
