<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Header",
  props: {
    headerText: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <h2>{{ headerText }}</h2>
</template>

<style scoped lang="scss">
h2 {
  color: $gray-dark;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 27px;
  margin: 0;

  text-transform: uppercase;
}
</style>
