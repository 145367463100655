<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import ChartMixin from "@/shared/mixins/ChartMixin";
import {
  AxisRendererY,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from "@amcharts/amcharts5/xy";
import {
  Bullet,
  Container,
  p50,
  Rectangle,
  Template,
  Root,
  Tooltip,
} from "@amcharts/amcharts5";
import {
  calcFixedPosition,
  chartProps,
} from "@/shared/components/Charts/chartCommon";
import { getExcludeEvents } from "@/shared/utils";

const POTASSIUM_LOW_VALUE = 2.7;
const POTASSIUM_NORMAL_VALUE = 4.3;
const POTASSIUM_HIGH_VALUE = 5.9;

const CHART_TYPE = "potassium";

export default {
  name: "PotassiumChart",
  mixins: [ChartMixin],
  props: chartProps,
  data() {
    return {
      chartLoaded: false,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      rangeUnitMapping: {
        temperature: {
          fahrenheit: this.$t("chart.labelRangeTemperatureF"),
          celsius: this.$t("chart.labelRangeTemperatureC"),
        },
      },
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "chart";
    },
  },
  watch: {
    chartData: function () {
      this.destroyRoot();
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    this.destroyRoot();
  },
  methods: {
    destroyRoot() {
      if (this.root) {
        this.root.dispose();
      }
    },
    createChart() {
      this.label = this.$t("chart.labelRangePotassium");

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        // ordinal is high/normal/low and it has a legend on the right side
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: this.ordinal ? 0 : 42,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(
        chart,
        this.root,
        this.upperBound,
        this.lowerBound
      );

      if (this.ordinal) {
        this.setupAxisRanges(yAxis);
      }

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);
      this.setupBullets(this.root, series);

      series.data.setAll(this.setupData());

      const cursorSeries = [series];

      if (this?.events?.length > 0) {
        const fixedPosition = calcFixedPosition(this.chartData);

        const dialysisEvents = this.setupDialysisEvents(
          fixedPosition,
          getExcludeEvents("potassium")
        );
        cursorSeries.push(
          this.addDialysisEventsSeries(
            this.root,
            chart,
            xAxis,
            yAxis,
            dialysisEvents
          )
        );
      }

      chart.set(
        "cursor",
        XYCursor.new(this.root, {
          xAxis,
          snapToSeries: cursorSeries,
        })
      );

      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    setupBullets(root, series) {
      const bulletTemplate = Template.new(root, {});

      bulletTemplate.events.on("click", (ev) => {
        this.clickHandler(CHART_TYPE, ev);
      });
      const bullet = series.bullets.push((root) => {
        const container = Container.new(
          root,
          {
            centerX: p50,
            centerY: p50,
          },
          bulletTemplate
        );

        const rectangle = Rectangle.new(root, {
          width: 30,
          height: 30,
          strokeWidth: 0,
        });

        rectangle.adapters.add("fill", (fill, target) => {
          if (!target.dataItem) {
            return fill;
          }

          if (this.ordinal) {
            if (target?.dataItem.get("valueY") === POTASSIUM_NORMAL_VALUE) {
              return this.ALIO_SUCCESS_GREEN;
            } else {
              return this.ALIO_SECONDARY_PURPLE;
            }
          } else {
            if (target?.dataItem.get("valueY") === POTASSIUM_NORMAL_VALUE) {
              return this.ALIO_SECONDARY_PURPLE;
            }
          }
        });

        container.children.push(rectangle);

        return Bullet.new(root, {
          sprite: container,
        });
      });

      return bullet;
    },
    getPotassiumValueNumeric(stringVal) {
      if (this.ordinal) {
        if (stringVal.toUpperCase() === "LOW") return POTASSIUM_LOW_VALUE;
        if (stringVal.toUpperCase() === "NORMAL") return POTASSIUM_NORMAL_VALUE;
        if (stringVal.toUpperCase() === "HIGH") return POTASSIUM_HIGH_VALUE;
      } else {
        if (stringVal.toUpperCase() === "LOW") return POTASSIUM_NORMAL_VALUE;
        if (stringVal.toUpperCase() === "NORMAL") return null;
        if (stringVal.toUpperCase() === "HIGH") return POTASSIUM_NORMAL_VALUE;
      }
    },
    getPotassiumValueString(numericVal) {
      if (this.ordinal) {
        if (numericVal === POTASSIUM_LOW_VALUE)
          return this.$t("patient.lowPotassium");
        if (numericVal === POTASSIUM_NORMAL_VALUE)
          return this.$t("patient.normalPotassium");
        if (numericVal === POTASSIUM_HIGH_VALUE)
          return this.$t("patient.highPotassium");
      } else {
        // this code makes no sense, but I'm expecting the FDA to allow us to show high/low again
        // so I want to keep this code here so I don't have to reinvent the wheel
        if (numericVal === POTASSIUM_LOW_VALUE) return "";
        if (numericVal === POTASSIUM_NORMAL_VALUE) return "";
        if (numericVal === POTASSIUM_HIGH_VALUE) return "";
      }
    },
    setupAxisRanges(yAxis) {
      const rangeDataItem = yAxis.makeDataItem({
        value: this.chartData?.goodrangefloor,
        endValue: this.chartData?.goodrangeceiling,
      });
      const range = yAxis.createAxisRange(rangeDataItem);
      rangeDataItem.get("grid").set("visible", false);
      range.get("axisFill").setAll({
        fill: this.ALIO_RANGE_GREEN,
        fillOpacity: 1,
        visible: true,
        forceHidden: false,
      });

      const brdi = yAxis.makeDataItem({
        value: this.chartData?.goodrangefloor,
      });
      const bottomRange = yAxis.createAxisRange(brdi);
      bottomRange.get("grid").setAll({ strokeOpacity: 0, forceHidden: false });
      bottomRange.get("label").setAll({
        html: `<div class="threshold-tabs">
                     <svg width="4" height="24"><polygon stroke="none" fill="${this.ALIO_SUCCESS_GREEN_HEX}" points="4,6 0,12 4,18"/></svg>
                     <div class="threshold-text">${this.chartData?.goodrangefloor}</div>
                 </div>`,
        paddingLeft: 0,
        forceHidden: false,
      });

      const trdi = yAxis.makeDataItem({
        value: this.chartData?.goodrangeceiling,
      });
      const topRange = yAxis.createAxisRange(trdi);
      topRange.get("grid").setAll({ strokeOpacity: 0, forceHidden: false });
      topRange.get("label").setAll({
        html: `<div class="threshold-tabs">
                     <svg width="4" height="24"><polygon stroke="none" fill="${this.ALIO_SUCCESS_GREEN_HEX}" points="4,6 0,12 4,18"/></svg>
                     <div class="threshold-text">${this.chartData?.goodrangeceiling}</div>
                 </div>`,
        paddingLeft: 0,
        forceHidden: false,
      });
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        showTooltipOn: "never",
      };

      if (this.ordinal) {
        const tooltip = Tooltip.new(root, {
          labelText: this.getTooltipText(),
          getFillFromSprite: false,
        });
        tooltip.get("background").setAll({
          fill: this.ALIO_PURPLE,
          fillOpacity: 1,
          strokeWidth: 0,
          strokeOpacity: 0,
        });
        settings.tooltip = tooltip;
        settings.showTooltipOn = "always";
      }

      settings.connect = this.getLineConnect();
      settings.autoGapCount = this.getAutoGapCount(
        CHART_TYPE,
        this.acute,
        this.acuteMode
      );
      settings.stroke = this.ALIO_SUCCESS_GREEN;
      settings.fill = this.ALIO_SUCCESS_GREEN;

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 0,
      });

      return series;
    },
    setupYAxis(chart, root) {
      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 30,
            minWidth: 35,
          }),
          strictMinMax: true,
          max: this.chartData.maxrange,
          min: this.chartData.minrange,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.set("forceHidden", true);
      yRenderer.grid.template.set("forceHidden", true);

      this.createGrid(yAxis, this.chartData.minrange, true, false, true);
      this.createGrid(yAxis, this.chartData.maxrange, true, false, true);

      return yAxis;
    },
    getTooltipText() {
      return "{realValue}";
    },
    setupData() {
      if (this.ordinal) {
        return this.chartData.data.map((entry) => {
          const date = entry.x * 1000; // turn to ms
          const chartEntry = { date };
          chartEntry.value = this.getPotassiumValueNumeric(entry.y);
          chartEntry.realValue = this.getPotassiumValueString(chartEntry.value);

          return chartEntry;
        });
      } else {
        // we are filtering out normal values because we won't show them now that
        // the FDA has decided to not allow us to show high/low
        return this.chartData.data
          .filter((entry) => entry.y !== "NORMAL")
          .map((entry) => {
            const date = entry.x * 1000; // turn to ms
            const chartEntry = { date };
            chartEntry.value = this.getPotassiumValueNumeric(entry.y);
            chartEntry.realValue = this.getPotassiumValueString(
              chartEntry.value
            );

            return chartEntry;
          });
      }
    },
  },
};
</script>

<style lang="scss">
.threshold-tabs {
  height: 24px;
  width: 40px;
  display: flex;
  align-content: center;
  background-color: white;
  line-height: normal;
}
.threshold-text {
  height: 24px;
  width: 36px;
  border: 2px solid #1ab83d;
  color: #1ab83d;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: white;
}
.threshold-triangle {
  align-content: center;
}
</style>
