<!--suppress AllyHtmlVueInspection -->
<!--necessary to suppress because the trademark shouldn't be translated-->
<template>
  <footer class="container">
    <div>
      <div>
        <img
          :alt="$t('footer.alioLogo')"
          src="/alio_rms_logo.png"
          class="alio-logo"
        />
      </div>
      <div>
        <span>© {{ currentYear }} Alio® Inc.</span
        ><span
          :class="[
            footerLessVisible ? 'footer-less-visible' : 'footer-fully-visible',
          ]"
          >{{ formattedFooterInfo }}</span
        >
      </div>
    </div>
    <div>
      <div>
        <SVGIconWrapper :size="20" class="button-link footer-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="size-6"
          >
            <path
              d="M21.721 12.752a9.711 9.711 0 0 0-.945-5.003 12.754 12.754 0 0 1-4.339 2.708 18.991 18.991 0 0 1-.214 4.772 17.165 17.165 0 0 0 5.498-2.477ZM14.634 15.55a17.324 17.324 0 0 0 .332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 0 0 .332 4.647 17.385 17.385 0 0 0 5.268 0ZM9.772 17.119a18.963 18.963 0 0 0 4.456 0A17.182 17.182 0 0 1 12 21.724a17.18 17.18 0 0 1-2.228-4.605ZM7.777 15.23a18.87 18.87 0 0 1-.214-4.774 12.753 12.753 0 0 1-4.34-2.708 9.711 9.711 0 0 0-.944 5.004 17.165 17.165 0 0 0 5.498 2.477ZM21.356 14.752a9.765 9.765 0 0 1-7.478 6.817 18.64 18.64 0 0 0 1.988-4.718 18.627 18.627 0 0 0 5.49-2.098ZM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 0 0 1.988 4.718 9.765 9.765 0 0 1-7.478-6.816ZM13.878 2.43a9.755 9.755 0 0 1 6.116 3.986 11.267 11.267 0 0 1-3.746 2.504 18.63 18.63 0 0 0-2.37-6.49ZM12 2.276a17.152 17.152 0 0 1 2.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0 1 12 2.276ZM10.122 2.43a18.629 18.629 0 0 0-2.37 6.49 11.266 11.266 0 0 1-3.746-2.504 9.754 9.754 0 0 1 6.116-3.985Z"
            />
          </svg>
        </SVGIconWrapper>
        <a target="_blank" href="https://alio.ai">{{ $t("footer.website") }}</a>
      </div>
      <div>
        <SVGIconWrapper :size="20" class="button-link footer-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="size-6"
          >
            <path
              d="M1.5 8.67v8.58a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V8.67l-8.928 5.493a3 3 0 0 1-3.144 0L1.5 8.67Z"
            />
            <path
              d="M22.5 6.908V6.75a3 3 0 0 0-3-3h-15a3 3 0 0 0-3 3v.158l9.714 5.978a1.5 1.5 0 0 0 1.572 0L22.5 6.908Z"
            />
          </svg>
        </SVGIconWrapper>
        <a href="mailto:support@alio.ai">{{ $t("footer.supportEmail") }}</a>
      </div>
    </div>
    <div>
      <div>{{ $t("footer.legalStatement") }}</div>
      <address>{{ $t("footer.alioAddress") }}</address>
    </div>
  </footer>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

export default defineComponent({
  name: "Footer",
  components: { SVGIconWrapper },
  data: () => ({
    WEB_REVISION_URL: "/revision.txt" as string,
    API_REVISION_URL: "/v1/systeminfo" as string,
    webRevision: null as string | null,
    apiRevision: null as string | null,
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    formattedFooterInfo: function () {
      if (import.meta.env.VITE_APP_MODE) {
        return (
          "[" +
          this.$APP_TYPE +
          " " +
          import.meta.env.VITE_APP_MODE +
          ":" +
          (this.webRevision ? this.webRevision.substring(0, 7) : "") +
          " API:" +
          (this.apiRevision ? this.apiRevision.substring(0, 7) : "") +
          "]"
        );
      } else {
        return (
          "[" +
          this.$APP_TYPE +
          ":" +
          (this.webRevision ? this.webRevision.substring(0, 7) : "") +
          " API:" +
          (this.apiRevision ? this.apiRevision.substring(0, 7) : "") +
          "]"
        );
      }
    },
    footerLessVisible: function () {
      return import.meta.env.VITE_APP_MODE ? false : true;
    },
  },
  mounted() {
    // Get the revision numbers
    this.getRevisions();

    // Reload the revision information on login complete. The API Revision
    // won't be able until signed in.
    this.$bus.on("login-complete", this.handleLoginComplete);
  },
  beforeDestroy() {
    this.$bus.off("login-complete", this.handleLoginComplete);
  },
  methods: {
    getWebRevision: function () {
      this.axios({
        method: "get",
        baseURL: "", // Local file
        url: this.WEB_REVISION_URL,
      })
        .then((response) => {
          this.webRevision = response.data;
        })
        .catch(() => {
          // Something went wrong - clear it
          this.webRevision = null;
        });
    },
    getApiRevision: function () {
      this.api
        .v1SysteminfoGet()
        .then((response) => {
          this.apiRevision = response.data.git_commit;
        })
        .catch(() => {
          // Something went wrong - clear it
          this.apiRevision = null;
        });
    },
    getRevisions: function () {
      this.getWebRevision();
      this.getApiRevision();
    },
    handleLoginComplete: function () {
      this.getRevisions();
    },
  },
});
</script>

<style scoped lang="scss">
.container {
  display: flex;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    margin: 0 5px;
  }
}
.alio-logo {
  height: 22px;
}
.footer-fully-visible {
  padding-left: 8px;
  font-size: 9px;
}

.footer-less-visible {
  padding-left: 8px;
  font-size: 9px;
  opacity: 0.1;
}
.footer-icon {
  color: $purple-dark;
}
</style>
