import Vue from "vue";
import Vuex from "vuex";
import { state } from "./state";
import { getters } from "./getters";
import { mutations } from "./mutations";

Vue.use(Vuex);

// Custom persistence plugin
const customPersistencePlugin = (store) => {
  // Load the state from localStorage when the store is initialized
  const savedState = localStorage.getItem("vuex");
  if (savedState) {
    store.replaceState(JSON.parse(savedState));
  }

  // Save the state to localStorage whenever it changes
  store.subscribe((mutation, state) => {
    localStorage.setItem("vuex", JSON.stringify(state));
  });
};

const store = new Vuex.Store({
  state: state,
  mutations: mutations,
  getters: getters,
  plugins: [customPersistencePlugin],
});

export default store;
