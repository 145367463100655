<template>
  <b-table
    id="clinic-table"
    class="basic-table"
    :class="{ 'clickable-table': rowClickSupported }"
    no-sort-reset
    :fields="tableFields"
    :items="clinicsStyled"
    sort-by="name"
    :tbody-tr-attr="rowAttributes"
    @row-clicked="rowClickHandler"
  >
  </b-table>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Clinic } from "@/shared/types/clinic";

export default defineComponent({
  props: {
    clinics: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    showRowState: {
      type: Boolean,
      default: true,
    },
    rowClickSupported: {
      type: Boolean,
      default: true,
    },
    rowClickNav: {
      type: String,
      default: "clinic-detail",
      validator: function (value: string): boolean {
        return (
          ["clinic-detail", "patient-list", "athlete-list"].indexOf(value) !==
          -1
        );
      },
    },
  },
  data: function () {
    return {
      tableFields: [
        {
          key: "name",
          label: this.$t("clinic.tableHeaderClinicName"),
          sortDirection: "asc",
          sortable: this.sortable,
          thClass: "column-stretch-50",
        },
        {
          key: "totalpatients",
          label: this.$t("clinic.tableHeaderTotalPatients"),
          formatter: this.totalPatientsFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "activepatients",
          label: this.$t("clinic.tableHeaderActivePatients"),
          formatter: this.activePatientsFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "active",
          label: this.$t("clinic.tableHeaderStatus"),
          formatter: this.statusFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
      ],
    };
  },
  computed: {
    clinicsStyled(): Clinic[] {
      if (!this.clinics) {
        return [];
      }

      if (!this.showRowState) {
        return this.clinics;
      }

      return this.clinics.map((item: any) => {
        item._rowVariant = this.getRowVariants(item);
        return item;
      });
    },
  },

  methods: {
    totalPatientsFormatter(value: any, key: any, item: any): string {
      if (this.fieldExists(item, key)) {
        return value;
      }

      return "-";
    },
    activePatientsFormatter(value: any, key: any, item: any): string {
      if (this.fieldExists(item, key)) {
        return value;
      }

      return "-";
    },
    issuesFormatter(value: any, key: any, item: any): string {
      if (this.fieldExists(item, key)) {
        return value;
      }

      return "-";
    },
    rowAttributes(item: any, type: string) {
      if (type === "row") {
        return {
          "data-testid": `name:${item.name}`,
        };
      }
      return {};
    },
    statusFormatter(value: any, key: any, item: any): string {
      if (this.fieldExists(item, key)) {
        return value
          ? `${this.$t("common.active")}`
          : `${this.$t("common.inactive")}`;
      }

      return "-";
    },
    rowClickHandler(item: any): void {
      if (this.rowClickSupported) {
        if (this.rowClickNav === "clinic-detail") {
          this.$router.push({
            name: "clinic-detail",
            params: { clinicId: item.id },
          });
        } else if (this.rowClickNav === "patient-list") {
          this.$router.push({
            name: "patient-list",
            params: { clinicId: item.id },
          });
        } else if (this.rowClickNav === "athlete-list") {
          this.$router.push({
            name: "athlete-list",
            params: { clinicId: item.id },
          });
        }
      }
    },
    getRowVariants(item: any): string {
      if (!item.active) {
        return "row-inactive";
      }

      return "";
    },
  },
});
</script>
