import { User } from "@/shared/types/user";
import { Clinic } from "@/shared/types/clinic";

export const state = {
  userId: null as number | null,
  user: {} as User,
  clinicId: null as number | null,
  clinic: {} as Clinic,
  lastRequest: null as Date | null,
  release: null as string | null,
  udi: null as string | null,
  environment: null as string | null,
  components: null as object | null,
  features: null as object | null,
};

export type State = typeof state;
