<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="show">
    <section-header
      :header-text="
        clinicUserToEdit
          ? $t('clinicalUser.titleEdit')
          : $t('clinicalUser.titleAdd')
      "
    />
    <form @submit.prevent="addEdit">
      <b-container fluid class="clinic-user-container">
        <b-row>
          <b-col sm="6">
            <div class="input-container">
              <input
                id="clinic-user-first-name"
                v-model="inputFields.firstName"
                type="text"
                :placeholder="$t('clinicalUser.placeholderFirstName')"
                tabindex="1"
                :maxlength="getInputMaxChar('first-name')"
              />
            </div>
          </b-col>
          <b-col sm="6">
            <div class="input-container">
              <input
                id="clinic-user-email"
                v-model="inputFields.email"
                type="text"
                :placeholder="$t('clinicalUser.placeholderEmail')"
                tabindex="3"
                :disabled="clinicUserToEdit ? true : false"
                :maxlength="getInputMaxChar('email')"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="6">
            <div class="input-container">
              <input
                id="clinic-user-last-name"
                v-model="inputFields.lastName"
                type="text"
                :placeholder="$t('clinicalUser.placeholderLastName')"
                tabindex="2"
                :maxlength="getInputMaxChar('last-name')"
              />
            </div>
          </b-col>
          <b-col sm="6">
            <div class="input-container">
              <b-form-select
                id="user-administrator-privileges"
                v-model="inputFields.administratorPrivileges"
                tabindex="4"
                :options="selects.administratorPrivileges"
              >
              </b-form-select>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <div id="add-clinic-user-buttons-container" class="buttons-container">
        <button
          id="add-clinic-user-add-edit-button"
          type="submit"
          :disabled="!isAddEditEnabled() || !buttonsEnabled"
        >
          {{
            clinicUserToEdit ? $t("common.buttonSave") : $t("common.buttonAdd")
          }}
        </button>
        <button
          id="add-clinic-user-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    clinicId: {
      type: Number,
      required: true,
    },
    clinicUserToEdit: {
      // If a clinic user exists it is a edit dialog
      type: Object,
      default: null,
    },
  },
  data: function () {
    return {
      buttonsEnabled: true,
      selects: {
        administratorPrivileges: [
          {
            value: "false",
            text: this.$t(
              "clinicalUser.selectAdministratorPrivilegesNotSupported"
            ),
          },
          {
            value: "true",
            text: this.$t(
              "clinicalUser.selectAdministratorPrivilegesSupported"
            ),
          },
        ],
      },
      inputFields: {
        firstName: null,
        lastName: null,
        email: null,
        administratorPrivileges: "false",
      },
    };
  },
  watch: {
    // Need to watch the clinic user for changes and update the inputFields if it does. There seems to be
    // a bit of race condition between the parent updating the prop and calling show, where show happens
    // before the prop update has been handled
    clinicUserToEdit: function () {
      this.show();
    },
  },
  methods: {
    isAddEditEnabled: function () {
      if (
        this.validateInputMinChar(this.inputFields.firstName, 1) &&
        this.validateInputMinChar(this.inputFields.lastName, 2) &&
        this.validateInputEmail(this.inputFields.email) &&
        this.inputFields.administratorPrivileges
      ) {
        if (this.clinicUserToEdit) {
          if (Object.keys(this.getFieldChangesClinicUser()).length > 0) {
            return true;
          }
        } else {
          return true;
        }
      }

      return false;
    },
    addEdit: function () {
      if (this.isAddEditEnabled()) {
        this.buttonsEnabled = false;

        if (this.clinicUserToEdit) {
          // Clinic user exists, it is an Edit dialog
          this.saveClinicUser();
        } else {
          // Clinic user does not exist, it is an Add dialog
          this.addClinicUser();
        }
      }
    },
    saveClinicUser: function () {
      this.api
        .v1ClinicuserClinicuseridPut(this.clinicUserToEdit.id, {
          updateclinicuser: this.getFieldChangesClinicUser(),
        })
        .then(() => {
          // User saved hide the modal
          this.$bvModal.hide(this.modalId);

          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("clinicalUser.successUpdate")
          );

          // On success send an event
          this.$emit("updated");

          // Renable the buttons
          this.buttonsEnabled = true;
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(error, this.$t("clinicalUser.errorUpdate"));

          // Renable the buttons
          this.buttonsEnabled = true;
        });
    },
    getFieldChangesClinicUser: function () {
      // Get a list of field changes based on the mapping below
      var fieldMappingObjectToInput = {
        firstname: "firstName",
        lastname: "lastName",
      };
      var fieldChanges = this.getFieldChanges(
        this.clinicUserToEdit,
        this.inputFields,
        fieldMappingObjectToInput
      );

      // Administrator privileges is a bit more difficult so do that in a custom fashion
      var administratorPrivileges = this.isClinicUserAdmin(
        this.clinicUserToEdit,
        this.clinicId
      )
        ? "true"
        : "false";

      if (
        administratorPrivileges !== this.inputFields.administratorPrivileges
      ) {
        let adminOfClinics = this.clinicUserToEdit.adminOfClinics
          ? this.clinicUserToEdit.adminOfClinics
          : [];
        let index = adminOfClinics.indexOf(this.clinicId);

        if (
          this.inputFields.administratorPrivileges === "true" &&
          index === -1
        ) {
          adminOfClinics.push(this.clinicId);
        } else if (
          this.inputFields.administratorPrivileges === "false" &&
          index > -1
        ) {
          adminOfClinics.splice(index, 1);
        }

        fieldChanges["adminofclinics"] = adminOfClinics;
      }

      return fieldChanges;
    },
    addClinicUser: function () {
      if (this.inputFields.administratorPrivileges === "true") {
        // Create Clinic Admin
        this.api
          .v1ClinicadminPost({
            createclinicadmin: {
              clinics: [this.clinicId],
              firstname: this.inputFields.firstName,
              lastname: this.inputFields.lastName,
              cognitoemail: this.inputFields.email,
            },
          })
          .then(() => {
            // User added hide the modal
            this.$bvModal.hide(this.modalId);

            // Show a general success message
            this.$bus.emit(
              "show-general-success",
              this.$t("clinicalUser.successAddAdmin")
            );

            // On success send an event
            this.$emit("updated");

            // Renable the buttons
            this.buttonsEnabled = true;
          })
          .catch((error) => {
            // Get the error and display it
            this.showApiResponseError(
              error,
              this.$t("clinicalUser.errorAddAdmin")
            );

            // Renable the buttons
            this.buttonsEnabled = true;
          });
      } else {
        // Create Clinic user
        this.api
          .v1ClinicuserPost({
            createclinicuser: {
              clinics: [this.clinicId],
              firstname: this.inputFields.firstName,
              lastname: this.inputFields.lastName,
              cognitoemail: this.inputFields.email,
            },
          })
          .then(() => {
            // User added hide the modal
            this.$bvModal.hide(this.modalId);

            // Show a general success message
            this.$bus.emit(
              "show-general-success",
              this.$t("clinicalUser.successAddUser")
            );

            // On success send an event
            this.$emit("updated");

            // Renable the buttons
            this.buttonsEnabled = true;
          })
          .catch((error) => {
            // Get the error and display it
            this.showApiResponseError(
              error,
              this.$t("clinicalUser.errorAddUser")
            );

            // Renable the buttons
            this.buttonsEnabled = true;
          });
      }
    },
    cancel: function () {
      this.$bvModal.hide(this.modalId);
    },
    show: function () {
      // Anytime this is shown, clear or set the input fields. This is so they
      // do not hold state over from everytime they are shown. First step is to
      // clear them all. As there is a bit of race condition that needs to be
      // handled. The clinic prop may not have been propagated yet. So use
      // the next tick to check that.
      this.inputFields.firstName = null;
      this.inputFields.lastName = null;
      this.inputFields.email = null;
      this.inputFields.administratorPrivileges = "false";

      // Have to wait until the next tick before we can be sure that the
      // clinic information is set properly. This is a bit of a race condition
      // with the @show event.
      this.$nextTick(function () {
        if (this.clinicUserToEdit) {
          this.inputFields.firstName = this.clinicUserToEdit.firstname;
          this.inputFields.lastName = this.clinicUserToEdit.lastname;
          this.inputFields.email = this.clinicUserToEdit.cognitoemail;
          this.inputFields.administratorPrivileges = this.isClinicUserAdmin(
            this.clinicUserToEdit,
            this.clinicId
          )
            ? "true"
            : "false";
        }
      });
    },
  },
};
</script>
