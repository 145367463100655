<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import ChartMixin from "@/shared/mixins/ChartMixin";
import {
  Tooltip,
  Bullet,
  Circle,
  Container,
  Root,
  Template,
  p50,
} from "@amcharts/amcharts5";
import {
  AxisRendererY,
  LineSeries,
  ValueAxis,
  XYChart,
  XYCursor,
} from "@amcharts/amcharts5/xy";
import {
  calcFixedPosition,
  chartProps,
} from "@/shared/components/Charts/chartCommon";
import { getExcludeEvents } from "@/shared/utils";

export default {
  name: "DotChart",
  mixins: [ChartMixin],
  props: {
    ...chartProps,
    chartType: {
      validator: function (value) {
        return ["hr", "hrv", "spo2"].indexOf(value) !== -1;
      },
      required: true,
    },
  },
  data() {
    return {
      chartLoaded: false,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      rangeUnitMapping: {
        hr: {
          bpm: this.$t("chart.labelRangeBeatsPerMinute"),
        },
        hrv: {
          ms: this.$t("chart.labelRangeMilliseconds"),
        },
        spo2: {
          "%": this.$t("chart.labelRangeOxygenSaturation"),
        },
      },
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "chart";
    },
  },
  watch: {
    chartData: function () {
      this.destroyRoot();
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    this.destroyRoot();
  },
  methods: {
    destroyRoot() {
      if (this.root) {
        this.root.dispose();
      }
    },
    createChart() {
      this.unitsRange = this.chartData.rangeunits;
      this.unitsRangeDisplay = this.chartData.rangedisplayunits
        ? this.chartData.rangedisplayunits
        : this.chartData.rangeunits;
      this.label =
        this.rangeUnitMapping[this.chartType][this.unitsRangeDisplay];

      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: 8,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(
        chart,
        this.root,
        this.upperBound,
        this.lowerBound
      );

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);

      this.setupDefaultBullets(this.root, series);

      series.data.setAll(this.setupData());

      const cursorSeries = [series];

      if (this?.events?.length > 0) {
        const dialysisEvents = this.setupDialysisEvents(
          calcFixedPosition(this.chartData),
          getExcludeEvents()
        );
        cursorSeries.push(
          this.addDialysisEventsSeries(
            this.root,
            chart,
            xAxis,
            yAxis,
            dialysisEvents
          )
        );
      }

      chart.set(
        "cursor",
        XYCursor.new(this.root, {
          xAxis,
          snapToSeries: cursorSeries,
        })
      );

      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    setupDefaultBullets(root, series) {
      const bulletTemplate = Template.new(root, {});

      bulletTemplate.events.on("click", (ev) => {
        this.clickHandler(this.chartType, ev);
      });

      const bullet = series.bullets.push((root) => {
        const container = Container.new(
          root,
          {
            centerX: p50,
            centerY: p50,
          },
          bulletTemplate
        );

        container.states.create("hover", {}).setAll({
          scale: 1.3,
        });

        const circle = Circle.new(root, {
          radius: 4,
          strokeWidth: 2,
          stroke: this.ALIO_SUCCESS_GREEN,
          fill: this.ALIO_SUCCESS_GREEN,
        });

        container.children.push(circle);

        return Bullet.new(root, {
          sprite: container,
        });
      });

      return bullet;
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const tooltip = Tooltip.new(root, {
        labelText: this.getTooltipText(),
      });

      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip,
        showTooltipOn: "always",
      };

      settings.connect = this.getLineConnect();
      settings.stroke = this.ALIO_PURPLE;
      settings.fill = this.ALIO_PURPLE;

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
        strokeOpacity: 0,
      });

      tooltip.get("background").setAll({
        fill: this.ALIO_PURPLE,
        fillOpacity: 1,
        strokeWidth: 0,
      });
      return series;
    },
    setupYAxis(chart, root) {
      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 50,
          }),
          strictMinMax: true,
          max: this.chartData.maxrange,
          min: this.chartData.minrange,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.setAll({
        minWidth: 35,
        opposite: true,
      });

      this.createGrid(yAxis, this.chartData.minrange, true, false, true);
      this.createGrid(yAxis, this.chartData.maxrange, true, false, true);

      return yAxis;
    },
    getTooltipText() {
      return `{valueY.formatNumber('###.')}${this.getUnitsString(
        this.unitsRangeDisplay
      )}`;
    },
    setupData() {
      return this.chartData.data.map((entry) => {
        const date = entry.x * 1000; // turn to ms
        const chartEntry = { date };
        chartEntry.value = entry.y;

        return chartEntry;
      });
    },
  },
};
</script>
