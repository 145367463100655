export class DataWindow {
  private _windowEnd: Date;
  private _monitoringStart: number | null;
  private _monitoringEnd: number | null;
  private _windowEndIsNow: boolean;
  private _monitoringEndIsNow: boolean;
  private _hasForward: boolean;
  private _hasBack: boolean;
  private _windowStart: Date;

  constructor(
    monitoringStart: number | null,
    monitoringEnd: number | null,
    windowStart?: number | null,
    windowEnd?: number | null
  ) {
    this._monitoringStart = monitoringStart;
    this._monitoringEnd = monitoringEnd;
    this._windowEndIsNow = false;
    this._hasForward = false;
    this._hasBack = false;

    if (windowEnd) {
      if (monitoringEnd === null) {
        this._monitoringEndIsNow = true;

        if (
          new Date(windowEnd * 1000) >
          new Date(Math.floor(Date.now() / 1000) * 1000)
        ) {
          this._windowEndIsNow = true;
          this._windowEnd = new Date(Math.floor(Date.now() / 1000) * 1000);
        } else {
          this._windowEnd = new Date(windowEnd * 1000);
          this._hasForward = true;
        }
      } else {
        if (windowEnd > monitoringEnd) {
          this._windowEnd = new Date(monitoringEnd * 1000);
        } else {
          this._windowEnd = new Date(windowEnd * 1000);
          this._hasForward = true;
        }
      }
    } else {
      if (monitoringEnd === null) {
        this._windowEndIsNow = true;
        this._monitoringEndIsNow = true;
        this._windowEnd = new Date(Math.floor(Date.now() / 1000) * 1000);
      } else {
        this._windowEnd = new Date(monitoringEnd * 1000);
      }
    }

    if (windowStart >= monitoringStart) {
      this._windowStart = new Date(windowStart * 1000);
      if (windowStart > monitoringStart) {
        this._hasBack = true;
      }
    } else {
      const oneWeekFromEnd = new Date(this._windowEnd.getTime());
      oneWeekFromEnd.setDate(oneWeekFromEnd.getDate() - 7);

      if (new Date(monitoringStart * 1000) > oneWeekFromEnd) {
        this._windowStart = new Date(monitoringStart * 1000);
      } else {
        this._hasBack = true;
        this._windowStart = oneWeekFromEnd;
      }
    }
  }

  exportState() {
    return {
      monitoringStart: this._monitoringStart,
      monitoringEnd: this._monitoringEnd,
      windowEnd: this._windowEnd.getTime(), // Export as a timestamp
      windowStart: this._windowStart.getTime(), // Export as a timestamp
      stopIsNow: this._windowEndIsNow,
      hasForward: this._hasForward,
      hasBack: this._hasBack,
    };
  }

  static fromExportedState(state) {
    const instance = new DataWindow(state.monitoringStart, state.monitoringEnd);
    instance._windowEnd = new Date(state.windowEnd);
    instance._windowStart = new Date(state.windowStart);
    instance._windowEndIsNow = state.stopIsNow;
    instance._hasForward = state.hasForward;
    instance._hasBack = state.hasBack;
    return instance;
  }

  setWindowStart(date: Date) {
    this._windowStart = date;
  }

  setWindowEnd(date: Date) {
    this._windowEnd = date;
  }

  get windowNumberOfDays(): number {
    return Math.floor(
      (this._windowEnd.getTime() - this._windowStart.getTime()) / 86400000
    );
  }

  get hasBack(): boolean {
    return this._hasBack;
  }
  get hasForward(): boolean {
    return this._hasForward;
  }

  get monitoringStart(): Date {
    return new Date(this._monitoringStart * 1000);
  }

  get monitoringEnd(): Date {
    if (this._monitoringEndIsNow) return new Date();
    return new Date(this._monitoringEnd * 1000);
  }

  get windowStartDate(): Date {
    return this._windowStart;
  }

  get windowStartTimeInSeconds(): number {
    return Math.floor(this._windowStart.getTime() / 1000);
  }

  get windowEndDate(): Date {
    return this._windowEnd;
  }

  get windowEndTimeInSeconds(): number {
    return Math.floor(this._windowEnd.getTime() / 1000);
  }

  get isEndNow(): boolean {
    return this._windowEndIsNow;
  }
}

function areSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}
