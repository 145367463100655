<template>
  <div>
    <HardwareSearch :search-prompt="searchPlaceholder" @search="search" />
    <b-table
      :id="'device-table-' + deviceType"
      class="basic-table"
      :class="{ 'clickable-table': rowClickSupported }"
      no-sort-reset
      no-local-sorting
      :fields="deviceTableFields"
      :items="devices"
      :sort-by="currentSortField"
      @sort-changed="onSort"
      @row-clicked="onRowClick"
    >
    </b-table>
    <div v-if="message" class="data-loading-container">
      <span class="table-message">{{ message }}</span>
    </div>
  </div>
</template>
<script>
// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};
import HardwareSearch from "@/shared/components/HardwareSearch.vue";

export const LOAD_TYPE_INITIAL = 1;
export const LOAD_TYPE_MORE = 2;
export const LOAD_TYPE_SORT = 3;
export const LOAD_TYPE_RELOAD = 4;

const DEFAULT_PAGE_COUNT = 25;
export default {
  components: {
    HardwareSearch,
  },
  props: {
    deviceType: {
      type: String,
      required: true,
    },
    clinics: {
      type: Array,
      required: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    showRowState: {
      type: Boolean,
      default: true,
    },
    rowClickSupported: {
      type: Boolean,
      default: true,
    },
    reloadDevices: {
      // Changing this number by the parent will cause a reload
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      searchText: null,
      tableFieldsKits: [
        {
          key: "kit_id",
          label: this.$t("kitHubPatch.tableHeaderGsn"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "hub_device_id",
          label: this.$t("kitHubPatch.tableHeaderHubMac"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "patch_box_id",
          label: this.$t("kitHubPatch.tableHeaderPatchBoxGsn"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "clinic_patient_id",
          label: this.$t("kitHubPatch.tableHeaderClinicPatientId"),
          formatter: this.clinicPatientIdFormatter,
          sortDirection: "asc",
          sortable: false,
          tdClass: "no-wrap",
        },
      ],
      tableFieldsHubs: [
        {
          key: "hub_id",
          label: this.$t("kitHubPatch.tableHeaderGsn"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "device_id",
          label: this.$t("kitHubPatch.tableHeaderMac"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "vpn_hostname",
          label: this.$t("kitHubPatch.tableHeaderVpnHostname"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "hub_hardware",
          label: this.$t("kitHubPatch.tableHeaderHardwareVersion"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "hub_firmware",
          label: this.$t("kitHubPatch.tableHeaderFirmwareVersion"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "hub_software",
          label: this.$t("kitHubPatch.tableHeaderSoftwareVersion"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "clinic_patient_id",
          label: this.$t("kitHubPatch.tableHeaderClinicPatientId"),
          formatter: this.clinicPatientIdFormatter,
          sortDirection: "asc",
          sortable: false,
          tdClass: "no-wrap",
        },
        {
          key: "last_connect",
          label: this.$t("kitHubPatch.tableHeaderLastConnect"),
          formatter: this.dateFormatter,
          sortDirection: "asc",
          sortable: true,
          tdClass: "no-wrap",
        },
      ],
      tableFieldsPatches: [
        {
          key: "patch_id",
          label: this.$t("kitHubPatch.tableHeaderGsn"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "device_id",
          label: this.$t("kitHubPatch.tableHeaderMac"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "hardware_version",
          label: this.$t("kitHubPatch.tableHeaderHardwareVersion"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "firmware_version",
          label: this.$t("kitHubPatch.tableHeaderFirmwareVersion"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "clinic_patient_id",
          label: this.$t("kitHubPatch.tableHeaderClinicPatientId"),
          formatter: this.clinicPatientIdFormatter,
          sortDirection: "asc",
          sortable: false,
          tdClass: "no-wrap",
        },
      ],
      tableFieldsPatchboxes: [
        {
          key: "gsn",
          label: this.$t("kitHubPatch.tableHeaderGsn"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "barcode",
          label: this.$t("kitHubPatch.tableHeaderBarcode"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "patch_ids",
          label: this.$t("kitHubPatch.tableHeaderPatchIds"),
          sortable: false,
        },
        {
          key: "clinic_patient_id",
          label: this.$t("kitHubPatch.tableHeaderClinicPatientId"),
          formatter: this.clinicPatientIdFormatter,
          sortDirection: "asc",
          sortable: false,
          tdClass: "no-wrap",
        },
      ],
      message: null,
      loading: null,
      fullyLoaded: false,
      rowLoadCount: 0,
      lastScollHeightLoad: null,
      currentSortField: null,
      currentSortDesc: false,
      // Devices
      devices: null,
    };
  },
  computed: {
    searchPlaceholder: function () {
      switch (this.deviceType) {
        case "kits":
          return this.$t("kitHubPatch.searchPlaceholderKits");

        case "hubs":
          return this.$t("kitHubPatch.searchPlaceholderHubs");

        case "patches":
          return this.$t("kitHubPatch.searchPlaceholderPatches");

        case "patchboxes":
          return this.$t("kitHubPatch.searchPlaceholderPatchboxes");
      }

      return null;
    },
    deviceTableFields: function () {
      switch (this.deviceType) {
        case "kits":
          return this.tableFieldsKits;

        case "hubs":
          return this.tableFieldsHubs;

        case "patches":
          return this.tableFieldsPatches;
        case "patchboxes":
          return this.tableFieldsPatchboxes;
      }

      return null;
    },
    defaultSortField: function () {
      switch (this.deviceType) {
        case "kits":
          return "kit_id";

        case "hubs":
          return "hub_id";

        case "patches":
          return "patch_id";

        case "patchboxes":
          return "gsn";
      }

      return null;
    },
  },
  watch: {
    reloadPatients: function () {
      this.getDevices(
        LOAD_TYPE_RELOAD,
        this.currentSortField,
        this.currentSortDesc
      );
    },
  },
  created() {
    // Delay 100 ms between resize events. Note that we have to manually create
    // a debounce function, otherwise if there are multiple instances of this
    // component it'll end up not working correctly.
    this.debouncedOnWindowResize = debounce(this.onWindowResize, 100);
  },
  mounted: function () {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.debouncedOnWindowResize);

    // Seed the patients
    this.currentSortField = this.defaultSortField;
    this.getDevices(
      LOAD_TYPE_INITIAL,
      this.currentSortField,
      this.currentSortDesc
    );
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.debouncedOnWindowResize);
  },
  methods: {
    dateFormatter: function (value) {
      if (value) {
        return this.$d(new Date(value), "nTable");
      } else {
        return null;
      }
    },
    clinicPatientIdFormatter: function (value, key, item) {
      let clinicId = this.getField(item, "patient_clinic");
      let patientId = this.getField(item, "patient_id");

      if (clinicId || patientId) {
        return (clinicId || "-") + "/" + (patientId || "-");
      }

      return "";
    },
    getDevices: function (loadType, sortField, sortDesc) {
      let loadMessage = null;
      let skipPages = 1;
      let pageCount = DEFAULT_PAGE_COUNT; // Default page count

      switch (loadType) {
        case LOAD_TYPE_INITIAL:
        case LOAD_TYPE_MORE:
          loadMessage = this.$t("common.loading");
          skipPages = Math.floor(this.rowLoadCount / pageCount) + 1;
          break;

        case LOAD_TYPE_SORT:
        case LOAD_TYPE_RELOAD:
          loadMessage =
            loadType === LOAD_TYPE_SORT
              ? this.$t("common.sorting")
              : this.$t("common.reloading");
          skipPages = 1;

          // Reset the all the variables
          this.rowLoadCount = 0;
          this.fullyLoaded = false;
          this.devices = null;
          break;
      }

      if (this.fullyLoaded || this.loading) {
        // All loaded, or it is currently loading
        return false;
      }

      // Reset the scroll height
      this.lastScollHeightLoad = null;

      this.loading = true;
      this.message = loadMessage;

      var sortFieldProcessed = null;
      switch (sortField) {
        default:
          sortFieldProcessed = sortField;
      }

      var queryParameters = {
        devicetype: this.deviceType,
        pageSize: pageCount,
        page: skipPages,
        sort: sortFieldProcessed,
        descending: sortDesc ? true : false,
      };

      this.api
        .v1DeviceSearchPost(queryParameters)
        .then((response) => {
          this.handleGetDevicesSuccess(response, loadType, pageCount);
        })
        .catch((error) => {
          this.handleGetDevicesError(error);
        });
    },
    handleGetDevicesSuccess: function (response, loadType, count) {
      this.message = null;

      if (this.devices === null || loadType === LOAD_TYPE_RELOAD) {
        this.devices = response.data.devices;
      } else {
        this.devices = this.devices.concat(response.data.devices);
      }

      if (this.devices.length < this.rowLoadCount + count) {
        this.fullyLoaded = true;

        if (this.devices.length === 0) {
          this.message = this.$t("kitHubPatch.errorNoData");
        }
      } else {
        this.message = this.$t("common.more");
      }

      this.rowLoadCount = this.devices.length;
      this.loading = false;
      this.$emit(
        "devices-updated",
        loadType !== LOAD_TYPE_MORE,
        this.rowLoadCount,
        this.fullyLoaded
      );

      this.$nextTick(() => {
        this.checkForLoad();
      });
    },
    handleGetDevicesError: function (error) {
      this.loading = false;

      this.showApiResponseError(error, this.$t("kitHubPatch.errorLoadList"));
    },
    onSort: function (context) {
      if (
        this.currentSortField !== context.sortBy ||
        this.currentSortDesc !== context.sortDesc
      ) {
        // Update the list if the sort has changed.
        this.currentSortField = context.sortBy;
        this.currentSortDesc = context.sortDesc;
        this.getDevices(
          LOAD_TYPE_SORT,
          this.currentSortField,
          this.currentSortDesc
        );
      }
    },
    // search function that works for all 4 tabs based off of the device type
    // we have currently selected.
    search(searchString) {
      const q = {};
      // generally this is the serial number
      q[this.defaultSortField] = `%${searchString}%`;

      // we add the specific search fields
      if (this.deviceType === "kits") {
        q["hub_device_id"] = `%${searchString}%`;
      } else if (this.deviceType === "hubs") {
        q["device_id"] = `%${searchString}%`;
        q["vpn_hostname"] = `%${searchString}%`;
      } else if (this.deviceType === "patches") {
        q["device_id"] = `%${searchString}%`;
      }

      const queryParameters = {
        sort: this.defaultSortField,
        descending: false,
        page: 1,
        pageSize: DEFAULT_PAGE_COUNT,
        devicetype: this.deviceType,
        q,
      };
      this.api
        .v1DeviceSearchPost(queryParameters)
        .then((response) => {
          this.handleGetDevicesSuccess(
            response,
            LOAD_TYPE_RELOAD,
            DEFAULT_PAGE_COUNT
          );
        })
        .catch((error) => {
          this.handleGetDevicesError(error);
        });
    },
    onRowClick: function (item) {
      if (this.rowClickSupported) {
        let clinicId = this.getField(item, "patient_clinic");
        let patientId = this.getField(item, "patient_id");
        let clinic = this.getClinicFromId(clinicId);

        if (this.deviceType === "hubs") {
          this.$router.push({
            name: "hub-detail",
            params: { hubId: item["hub_id"] },
          });
        } else {
          if (clinicId && patientId) {
            this.$router.push({
              name: "patient-detail",
              params: {
                clinicId: clinicId,
                patientId: patientId,
              },
            });
          } else {
            this.$bus.emit(
              "show-general-error",
              this.$t("kitHubPatch.errorNoPatientConnected")
            );
          }
        }
      }
    },
    getClinicFromId: function (clinicId) {
      if (this.clinics) {
        for (let i = 0; i < this.clinics.length; i++) {
          // look for the entry with a matching `code` value
          if (this.clinics[i].id == clinicId) {
            return this.clinics[i];
          }
        }
      }

      return null;
    },
    onScroll: function (event) {
      var node = event.target.scrollingElement || document.body;

      if (node.scrollHeight - node.scrollTop >= node.clientHeight * 0.8) {
        // Start loading anytime we hit above the 80% full
        if (this.lastScollHeightLoad !== node.scrollHeight) {
          this.lastScollHeightLoad = node.scrollHeight;
          this.getDevices(
            LOAD_TYPE_MORE,
            this.currentSortField,
            this.currentSortDesc
          );
        }
      }
    },
    onWindowResize() {
      this.checkForLoad();
    },
    checkForLoad() {
      if (window.innerHeight > document.body.offsetHeight) {
        this.getDevices(
          LOAD_TYPE_MORE,
          this.currentSortField,
          this.currentSortDesc
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.device-search {
  width: 100%;
}
</style>
