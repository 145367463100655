<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="show">
    <section-header :header-text="$t('patchLocation.title')" />
    <form @submit.prevent="add">
      <b-container fluid class="patch-location-container">
        <b-row>
          <b-col sm="12">
            <div class="input-container">
              <input
                id="patch-location-name"
                v-model="inputFields.name"
                type="text"
                :placeholder="$t('patchLocation.placeholderName')"
                :maxlength="getInputMaxChar('location-name')"
              />
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" class="mt-4 text-left">
            <b-form-radio-group
              id="radio-group"
              v-model="inputFields.location_type"
              :options="availableAlgoTypes"
              name="radio-options"
              stacked
            ></b-form-radio-group>
          </b-col>
        </b-row>
      </b-container>

      <div id="add-patch-location-buttons-container" class="buttons-container">
        <button
          id="add-patch-location-add-button"
          type="submit"
          :disabled="!isAddEnabled() || !buttonsEnabled"
        >
          {{ $t("common.buttonAdd") }}
        </button>
        <button
          id="add-patch-location-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </form>
  </b-modal>
</template>
<script lang="ts">
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import {
  PatchLocation,
  PatchLocationLocationTypeEnum,
} from "@/shared/api-client";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      buttonsEnabled: true,
      algoTypes: [
        {
          text: this.$t("patchLocation.algoAccessLabel"),
          value: PatchLocationLocationTypeEnum.Access,
        },
        {
          text: this.$t("patchLocation.algoCalfLabel"),
          value: PatchLocationLocationTypeEnum.Calf,
        },
      ],
      inputFields: {
        name: null,
        location_type: PatchLocationLocationTypeEnum.Access,
      },
    };
  },
  computed: {
    availableAlgoTypes() {
      if (this.$store.getters?.features.SUPPORT_CALF) {
        return this.algoTypes;
      } else {
        return this.algoTypes.filter(
          (algo) => algo.value !== PatchLocationLocationTypeEnum.Calf
        );
      }
    },
  },
  methods: {
    isAddEnabled: function () {
      // There currently is no minumum length specified, so if there
      // are any characters the button should be enabled.
      return this.validateInputMinChar(this.inputFields.name, 1);
    },
    add: function () {
      if (this.isAddEnabled()) {
        this.buttonsEnabled = false;

        // Copy the array and add the new element at the end
        const newPatchLocationsList = this.clinic.patchlocations.slice();
        const newLocation: PatchLocation = {
          active: true,
          locationname: this.inputFields.name,
          location_type: this.inputFields.location_type,
        };
        newPatchLocationsList.push(newLocation);

        this.api
          .v1ClinicClinicidPut(this.clinic.id, {
            updateclinic: {
              patchlocations: newPatchLocationsList,
            },
          })
          .then(() => {
            // User added hide the modal
            this.$bvModal.hide(this.modalId);

            // Show a general success message
            this.$bus.emit(
              "show-general-success",
              this.$t("patchLocation.successAdd")
            );

            // On success send an event
            this.$emit("added");

            // Renable the buttons
            this.buttonsEnabled = true;
          })
          .catch((error) => {
            // Get the error and display it
            this.showApiResponseError(error, this.$t("patchLocation.errorAdd"));

            // Renable the buttons
            this.buttonsEnabled = true;
          });
      }
    },
    cancel: function () {
      this.$bvModal.hide(this.modalId);
    },
    show: function () {
      // Anytime this is shown, clear the input fields. This is so they
      // do not hold state over
      this.inputFields.name = null;
      this.inputFields.location_type = "access";
    },
  },
};
</script>
