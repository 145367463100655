<script lang="ts">
import { defineComponent } from "vue";
import FormLayout from "@/shared/layouts/FormLayout.vue";
import PotassiumSettings from "@/shared/components/PotassiumSettings.vue";
import HCTOrHGBSettings from "@/shared/components/HCTOrHGBSettings.vue";
import FormButtonsLayout from "@/shared/layouts/FormButtonsLayout.vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { Clinic } from "@/shared/api-client";

export default defineComponent({
  name: "PatientThresholds",
  components: {
    FormButtonsLayout,
    FormLayout,
    PotassiumSettings,
    HCTOrHGBSettings,
    SectionHeader,
  },
  data() {
    return {
      isLoading: true,
      timer: null,
      clinic: null,
      patient: null,
      currentClinicLoadResult: null,
      inputFields: {
        potassiumSettings: {
          notifyLowPotassium: true,
          numberLowPotassium: null,
          notifyHighPotassium: true,
          numberHighPotassium: null,
          notifyDyskalemia: true,
          numberDyskalemia: null,
        },
        hgbSettings: {
          notify: true,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
        hctSettings: {
          notify: true,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
      },
    };
  },
  computed: {
    isPotassiumSupported() {
      return this.hasPotassiumFeatureSupport(this.clinic);
    },
    isHCTSupported() {
      return this.hasHctFeatureSupport(this.clinic);
    },
    isHGBSupported() {
      return this.hasHgbFeatureSupport(this.clinic);
    },
    isAnySupported() {
      return (
        this.isPotassiumSupported || this.isHCTSupported || this.isHGBSupported
      );
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    currentUser: function () {
      return this.$store.getters.user;
    },
    patientIdParameter: function () {
      return parseInt(this.$route.params.patientId, 10);
    },
  },
  mounted() {
    Promise.allSettled([this.getPatient(), this.refreshCurrentClinic()]).then(
      () => {
        this.loadInitialValues();
        this.isLoading = false;
      }
    );
  },
  methods: {
    loadInitialValues() {
      // Load the initial values
      this.inputFields.potassiumSettings.notifyLowPotassium =
        this.patient?.notify_low_potassium ?? this.clinic?.notify_low_potassium;
      this.inputFields.potassiumSettings.numberLowPotassium =
        this.patient?.number_low_potassium ?? this.clinic?.number_low_potassium;
      this.inputFields.potassiumSettings.notifyHighPotassium =
        this.patient?.notify_high_potassium ??
        this.clinic?.notify_high_potassium;
      this.inputFields.potassiumSettings.numberHighPotassium =
        this.patient?.number_high_potassium ??
        this.clinic?.number_high_potassium;
      this.inputFields.potassiumSettings.notifyDyskalemia =
        this.patient?.notify_dyskalemia ?? this.clinic?.notify_dyskalemia;
      this.inputFields.potassiumSettings.numberDyskalemia =
        this.patient?.number_dyskalemia ?? this.clinic?.number_dyskalemia;
      this.inputFields.hctSettings.notify =
        this.patient?.notify_hct ?? this.clinic?.notify_hct;
      this.inputFields.hctSettings.thresholdLow =
        this.patient?.threshold_low_hct ?? this.clinic?.threshold_low_hct;
      this.inputFields.hctSettings.thresholdHigh =
        this.patient?.threshold_high_hct ?? this.clinic?.threshold_high_hct;
      this.inputFields.hctSettings.numberLow =
        this.patient?.number_low_hct ?? this.clinic?.number_low_hct;
      this.inputFields.hctSettings.numberHigh =
        this.patient?.number_high_hct ?? this.clinic?.number_high_hct;
      this.inputFields.hgbSettings.notify =
        this.patient?.notify_hgb ?? this.clinic?.notify_hgb;
      this.inputFields.hgbSettings.thresholdLow =
        this.patient?.threshold_low_hgb ?? this.clinic?.threshold_low_hgb;
      this.inputFields.hgbSettings.thresholdHigh =
        this.patient?.threshold_high_hgb ?? this.clinic?.threshold_high_hgb;
      this.inputFields.hgbSettings.numberLow =
        this.patient?.number_low_hgb ?? this.clinic?.number_low_hgb;
      this.inputFields.hgbSettings.numberHigh =
        this.patient?.number_high_hgb ?? this.clinic?.number_high_hgb;
    },
    createUpdatePatientRecord() {
      return {
        notify_low_potassium:
          this.inputFields.potassiumSettings.notifyLowPotassium,
        number_low_potassium:
          this.inputFields.potassiumSettings.numberLowPotassium,
        notify_high_potassium:
          this.inputFields.potassiumSettings.notifyHighPotassium,
        number_high_potassium:
          this.inputFields.potassiumSettings.numberHighPotassium,
        notify_dyskalemia: this.inputFields.potassiumSettings.notifyDyskalemia,
        number_dyskalemia: this.inputFields.potassiumSettings.numberDyskalemia,
        notify_hct: this.inputFields.hctSettings.notify,
        threshold_low_hct: this.inputFields.hctSettings.thresholdLow,
        threshold_high_hct: this.inputFields.hctSettings.thresholdHigh,
        number_low_hct: this.inputFields.hctSettings.numberLow,
        number_high_hct: this.inputFields.hctSettings.numberHigh,
        notify_hgb: this.inputFields.hgbSettings.notify,
        threshold_low_hgb: this.inputFields.hgbSettings.thresholdLow,
        threshold_high_hgb: this.inputFields.hgbSettings.thresholdHigh,
        number_low_hgb: this.inputFields.hgbSettings.numberLow,
        number_high_hgb: this.inputFields.hgbSettings.numberHigh,
        // TODO(ryan): remove once the PUT endpoint removes requirement to always include these values
        notify_clinicians: this.patient.notify_clinicians.map((c) => c.id),
        notify_groups: this.patient.notify_groups.map((g) => g.id),
      };
    },
    createEmptyPatientRecord() {
      return {
        notify_low_potassium: null,
        number_low_potassium: null,
        notify_high_potassium: null,
        number_high_potassium: null,
        notify_dyskalemia: null,
        number_dyskalemia: null,
        notify_hct: null,
        threshold_low_hct: null,
        threshold_high_hct: null,
        number_low_hct: null,
        number_high_hct: null,
        notify_hgb: null,
        threshold_low_hgb: null,
        threshold_high_hgb: null,
        number_low_hgb: null,
        number_high_hgb: null,
      };
    },
    showToast() {
      this.$root.$bvToast.toast(`Patient updated`, {
        title: "Changes saved!",
        toaster: "b-toaster-bottom-right",
      });
    },
    resetDefaults() {
      this.api
        .v1PatientPatientidPut(this.patient.id, {
          updatepatient: this.createEmptyPatientRecord(),
        })
        .then(() => {
          this.showToast();
          this.$router.push({
            name: "patient-detail",
            params: {
              clinicId: this.$route.params.clinicId,
              patientId: this.$route.params.patientId,
            },
          });
        })
        .catch((error) => {
          // Get and show the error
          this.showApiResponseError(error, this.$t("patient.errorUpdate"));
        });
    },
    submit() {
      this.api
        .v1PatientPatientidPut(this.patient.id, {
          updatepatient: this.createUpdatePatientRecord(),
        })
        .then(() => {
          this.showToast();
          this.$router.push({
            name: "patient-detail",
            params: {
              clinicId: this.$route.params.clinicId,
              patientId: this.$route.params.patientId,
            },
          });
        })
        .catch((error) => {
          // Get and show the error
          this.showApiResponseError(error, this.$t("patient.errorUpdate"));
        });
    },
    cancel() {
      this.$router.push({
        name: "patient-detail",
        params: {
          clinicId: this.$route.params.clinicId,
          patientId: this.$route.params.patientId,
        },
      });
    },
    async getPatient() {
      if (this.patientIdParameter < 0) {
        this.$bus.emit(
          "show-general-error",
          this.$t("patient.errorUnspecified")
        );
      } else {
        // Load the patient info
        return this.api
          .v1PatientPatientidGet(this.patientIdParameter)
          .then(async (response) => {
            this.lastRefreshDate = new Date();
            if (response.data.patient.clinic !== this.clinicIdParameter) {
              this.$bus.emit(
                "show-general-error",
                this.$t("patient.errorInvalidClinic")
              );
            } else {
              this.patient = response.data.patient;
            }
          })
          .catch((error) => {
            // Get the error and display the error
            this.showApiResponseError(error, this.$t("patient.errorLoad"));
          });
      }
    },
    async refreshCurrentClinic() {
      // Get the latest Clinic information
      this.currentClinicLoadResult = null;
      this.clinic = null;

      try {
        this.clinic = await this.getClinicInfoPromise(
          this.clinicIdParameter,
          true
        );
      } catch (error) {
        this.currentClinicLoadResult = false;
        this.showApiResponseError(error, this.$t("clinic.errorLoadDetails"));
      }
    },
  },
});
</script>

<template>
  <FormLayout>
    <b-form class="settings" @submit.prevent="submit">
      <section-header
        :header-text="$t('settingsClinician.headingNotifications')"
      />
      <p v-if="!isAnySupported">No metrics with notifications are enabled</p>
      <PotassiumSettings
        v-if="isPotassiumSupported"
        :notify-low-potassium.sync="
          inputFields.potassiumSettings.notifyLowPotassium
        "
        :notify-high-potassium.sync="
          inputFields.potassiumSettings.notifyHighPotassium
        "
        :notify-dyskalemia.sync="inputFields.potassiumSettings.notifyDyskalemia"
        :number-dyskalemia.sync="inputFields.potassiumSettings.numberDyskalemia"
        :number-low-potassium.sync="
          inputFields.potassiumSettings.numberLowPotassium
        "
        :number-high-potassium.sync="
          inputFields.potassiumSettings.numberHighPotassium
        "
        :ordinal="clinic.ordinal_potassium"
        :clinic-id-parameter="clinicIdParameter"
      />
      <HCTOrHGBSettings
        v-if="isHCTSupported"
        class="mt-4"
        type="hct"
        :notify.sync="inputFields.hctSettings.notify"
        :threshold-low.sync="inputFields.hctSettings.thresholdLow"
        :threshold-high.sync="inputFields.hctSettings.thresholdHigh"
        :number-low.sync="inputFields.hctSettings.numberLow"
        :number-high.sync="inputFields.hctSettings.numberHigh"
        :clinic-id-parameter="clinicIdParameter"
      />
      <HCTOrHGBSettings
        v-if="isHGBSupported"
        class="mt-4"
        type="hgb"
        :notify.sync="inputFields.hgbSettings.notify"
        :threshold-low.sync="inputFields.hgbSettings.thresholdLow"
        :threshold-high.sync="inputFields.hgbSettings.thresholdHigh"
        :number-low.sync="inputFields.hgbSettings.numberLow"
        :number-high.sync="inputFields.hgbSettings.numberHigh"
        :clinic-id-parameter="clinicIdParameter"
      />
      <FormButtonsLayout v-if="!isLoading">
        <button id="settings-save" type="submit" :disabled="!isAnySupported">
          {{ $t("common.buttonSave") }}
        </button>
        <button
          type="button"
          :disabled="!isAnySupported"
          @click.prevent="resetDefaults"
        >
          {{ $t("common.buttonResetDefaults") }}
        </button>
        <button
          id="settings-reset"
          type="button"
          class="button-negative"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </FormButtonsLayout>
    </b-form>
  </FormLayout>
</template>

<style scoped lang="scss">
.notification-section-header {
  font-size: 24px;
  font-weight: 700;
}
</style>
