<script>
export default {
  name: "SVGIconWrapper",
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
      default: null,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconStyle() {
      const style = {
        width: typeof this.size === "number" ? `${this.size}px` : this.size,
        height: typeof this.size === "number" ? `${this.size}px` : this.size,
        display: "inline-block",
        lineHeight: 0,
      };
      if (this.color) {
        style.color = this.color;
      }
      if (this.clickable) {
        style.cursor = "pointer";
      }

      return style;
    },
  },
  methods: {
    handleClick(event) {
      this.$emit("click", event); // Emit the click event
    },
    handleEnter(event) {
      this.$emit("click", event); // Emit the click event
    },
  },
};
</script>

<template>
  <div :style="iconStyle" @click="handleClick" @keydown.enter="handleEnter">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">

</style>
