import { ResourcesConfig } from "aws-amplify";

const clinicianCognitoConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_CLINICIAN_USER_POOL_ID as string,
      userPoolClientId: import.meta.env
        .VITE_CLINICIAN_USER_POOL_CLIENT_ID as string,
    },
  },
};
const supportCognitoConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_SUPPORT_USER_POOL_ID as string,
      userPoolClientId: import.meta.env
        .VITE_SUPPORT_USER_POOL_CLIENT_ID as string,
      loginWith: {
        oauth: {
          domain: `${import.meta.env.VITE_SUPPORT_USER_ENDPOINT}`,
          scopes: [
            "phone",
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [
            `${import.meta.env.VITE_SUPPORT_USER_REDIRECT_LOGIN_URI}`,
          ],
          redirectSignOut: [
            `${import.meta.env.VITE_SUPPORT_USER_REDIRECT_LOGOUT_URI}`,
          ],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};

export { supportCognitoConfig, clinicianCognitoConfig };
