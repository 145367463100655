<template>
  <div v-if="patches?.length === 0">{{ $t("patchTable.noneFound") }}</div>
  <b-table
    v-else
    id="patch-table"
    class="basic-table"
    :fields="tableFields"
    :items="patches"
    sort-by="index_id"
  >
    <template #cell(info)="data">
      <div>{{ $t("patchTable.sn") }}: {{ data.item.sn }}</div>
      <div>{{ $t("patchTable.mac") }}: {{ data.item.device_id }}</div>
    </template>
    <template #cell(versions)="data">
      <div>{{ $t("patchTable.hw") }}: {{ data.item.hardware_version }}</div>
      <div>{{ $t("patchTable.fw") }}: {{ data.item.firmware_version }}</div>
    </template>
    <template #cell(status)="data">
      <div class="status">{{ data.item.status }}</div>
    </template>
    <template #cell(status_start)="data">
      <div>{{ $d(new Date(data.item.date_start), "table") }}</div>
    </template>
    <template #cell(configuration)="data">
      <div>{{ data.item.configuration }}</div>
      <div
        v-html="
          getSupportInfoLinkTag(
            supportInfo,
            'engineering_patch_reads_search',
            data.item,
            'Engineering'
          )
        "
      />
    </template>
    <template #cell(actions)="data">
      <button
        v-if="fieldExists(data.item, 'patch_id')"
        id="table-actions"
        type="button"
        class="button-link"
        @click.prevent="removePatch(data.item)"
      >
        <span>{{ $t("hubPatch.removeButton") }}</span>
      </button>
    </template>
  </b-table>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PatchTable",
  props: {
    patientId: {
      type: Number,
      required: true,
    },
    patches: {
      type: Array,
      required: true,
    },
    supportInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableFields: [
        {
          key: "info",
          label: this.$t("patchTable.headerInfo"),
          sortable: false,
        },
        {
          key: "versions",
          label: this.$t("patchTable.headerVersions"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("patchTable.headerStatus"),
          sortable: false,
        },
        {
          key: "status_start",
          label: this.$t("patchTable.headerStatusStart"),
          sortable: false,
        },
        {
          key: "configuration",
          label: this.$t("patchTable.headerConfiguration"),
          sortable: false,
        },
        {
          key: "actions",
          label: this.$t("patchTable.headerActions"),
          sortable: false,
        },
      ],
    };
  },
  methods: {
    removePatch: function (item) {
      this.showConfirmationPrompt(
        this.$t("patchTable.confirm"),
        "remove-patch",
        (state) => this.callRemovePatch(state, item)
      );
    },
    callRemovePatch: function (state, item) {
      if (state) {
        this.api
          .v1PatientsPatientIdPatchesPatchIdDelete(this.patientId, item.sn)
          .then(() => {
            this.$emit("refresh");
            this.$bus.emit(
              "show-general-success",
              this.$t("patchTable.removedPatch")
            );
          })
          .catch((error) => {
            this.showApiResponseError(
              error,
              this.$t("patchTable.failedRemove")
            );
          });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.status {
  text-transform: uppercase;
}
</style>
