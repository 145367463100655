<template>
  <div class="date-select">
    <b-form-select
      :id="`${type}-${cuuid}-${index}`"
      v-blur="handleBlur"
      class="form-select"
      :disabled="disabled"
      :value="selected"
      :options="options"
      :tabindex="tabindex"
      @change="change"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "DateSelect",
  directives: {
    blur: {
      bind: function (el, binding) {
        el.addEventListener("blur", binding.value, true);
      },
      unbind: function (el, binding) {
        el.removeEventListener("blur", binding.value, true);
      },
    },
  },
  props: {
    type: {
      required: true,
    },
    selected: {
      required: true,
    },
    options: {
      required: true,
    },
    tabindex: {
      required: true,
    },
    index: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cuuid: uuidv4(),
    };
  },
  methods: {
    change(value) {
      this.$emit(`update`, { type: this.type, value });
    },
    handleBlur() {
      this.$emit(`blur`, this.type);
    },
  },
};
</script>

<style scoped>
.form-select {
  padding-left: 0px;
}
.date-select {
  flex: 1;
}
</style>
