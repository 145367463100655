<template>
  <div>
    <p>
      Percent change in relative blood volume (BV) is inversely related to
      hematocrit. Select a start date to use for the initial hematocrit and a
      follow-up date to calculate the percent change in blood volume. % change
      in BV is calculated using the mean Hct value for the selected dates.
    </p>
    <div class="tw-flex tw-gap-6">
      <div>
        <div class="tw-flex tw-items-center tw-justify-between mb-2">
          <div class="tw-text-nowrap tw-font-bold tw-w-48">
            <span>Initial HCT</span>
          </div>
          <div class="tw-max-w-44">
            <DateInput :value="initialHCT" @click="scrollToDateRangePicker" />
          </div>
        </div>
        <div class="tw-flex tw-items-center tw-justify-between">
          <div class="tw-text-nowrap tw-font-bold tw-w-48">
            <span>Follow-up HCT</span>
          </div>
          <div class="tw-max-w-44">
            <DateInput :value="followUpHCT" @click="scrollToDateRangePicker" />
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-flex-grow">
        <div class="tw-flex tw-justify-center tw-items-center tw-h-full">
          <div class="tw-flex">
            <div class="tw-flex tw-items-center tw-justify-center tw-font-bold">
              <span>% change in Blood Volume</span>
            </div>
            <div
              v-if="relativePercentChange"
              class="percent-change-container tw-flex tw-items-center tw-justify-center tw-font-bold tw-ml-4 tw-p-2"
            >
              <div>{{ relativePercentChange }}</div>
            </div>
            <div
              v-else
              class="no-percent-change-container tw-flex tw-items-center tw-justify-center tw-font-bold tw-ml-4 tw-p-2"
            >
              <div>N/A*</div>
            </div>
            <div v-if="failReason" class="tw-ml-2">{{ failReason }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateInput from "@/shared/components/Primitives/DateInput.vue";

export default {
  name: "RelativeBloodVolume",
  components: {
    DateInput,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    lowerBound: {
      type: Number,
      default: null,
    },
    upperBound: {
      type: Number,
      default: null,
    },
  },
  computed: {
    failReason() {
      if (!this.data.data) return "*No data available";
      let missingBegin = false;
      let missingEnd = false;

      const initialDate = new Date(this.lowerBound * 1000);
      const initialHCTValues = this.getValuesForDate(
        initialDate,
        this.data.data
      );

      if (initialHCTValues.length === 0) missingBegin = true;

      const endDate = new Date(this.upperBound * 1000);
      const endHCTValues = this.getValuesForDate(endDate, this.data.data);

      if (endHCTValues.length === 0) missingEnd = true;

      if (missingBegin && missingEnd)
        return "*Missing hematocrit read on start and end date";
      if (missingBegin) return "*Missing hematocrit read on start date";
      if (missingEnd) return "*Missing hematocrit read on end date";
      return null;
    },
    initialHCT() {
      return this.$d(this.lowerBound * 1000, "date");
    },
    followUpHCT() {
      return this.$d(this.upperBound * 1000, "date");
    },
    relativePercentChange() {
      if (!this.data.data) return null;
      if (!this.lowerBound || !this.upperBound) return null;
      if (this.lowerBound === this.upperBound) return null;
      if (this.data.data.length === 0) return null;

      const initialDate = new Date(this.lowerBound * 1000);
      const initialHCTValues = this.getValuesForDate(
        initialDate,
        this.data.data
      );

      if (initialHCTValues.length === 0) return null;

      const initialSum = initialHCTValues.reduce((a, b) => a + b, 0);
      const initialMean = initialSum / initialHCTValues.length;

      const endDate = new Date(this.upperBound * 1000);

      const endHCTValues = this.getValuesForDate(endDate, this.data.data);
      if (endHCTValues.length === 0) return null;
      const endSum = endHCTValues.reduce((a, b) => a + b, 0);
      const endMean = endSum / endHCTValues.length;

      try {
        return `${Math.round((initialMean / endMean - 1) * 100)}%`;
      } catch (error) {
        return null;
      }
    },
  },
  methods: {
    scrollToViewWithCallback(element, callback) {
      const initialPosition = window.pageYOffset;
      let lastPosition = -1;

      function checkIfDone() {
        // Check current position
        const currentPosition = window.pageYOffset;
        if (currentPosition === lastPosition) {
          // If the position hasn't changed, it's likely done scrolling
          callback();
        } else {
          // If it's still moving, update the position and check again
          lastPosition = currentPosition;
          requestAnimationFrame(checkIfDone);
        }
      }

      // Start scrolling
      element.scrollIntoView({ behavior: "smooth", block: "start" });
      requestAnimationFrame(checkIfDone);
    },
    scrollToDateRangePicker() {
      const element = document.getElementById("range-date-picker");
      if (element) {
        this.scrollToViewWithCallback(element, () => {
          console.log("Finished scrolling");
          this.$bus.emit("open-range-date-picker");
        });
      }
    },
    getValuesForDate(targetDate, dataArray) {
      // Set time to midnight for consistent comparison
      targetDate.setHours(0, 0, 0, 0);

      return dataArray
        .filter((item) => {
          // Convert item's date to Date object and set to midnight
          const itemDate = new Date(item.x * 1000);
          itemDate.setHours(0, 0, 0, 0);
          // Compare dates
          return itemDate.getTime() === targetDate.getTime();
        })
        .map((item) => item.y);
    },
  },
};
</script>

<style scoped lang="scss">
.percent-change-container {
  background-color: #edf9ed;
}
.no-percent-change-container {
  background-color: #b2b2b2;
}
</style>
