<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormButton",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: true,
    },
  },
});
</script>

<template>
  <button :id="id" :disabled="disabled" type="button">
    {{ text }}
  </button>
</template>

<style scoped lang="scss">
button {
  min-width: 0;
  margin: 0;
}
</style>
