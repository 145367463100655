<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :action-buttons="actionButtons"
      :navigation-buttons-right="navigationButtonsRight"
      @view-patient-list-event="navViewPatientListAll"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    >
      <template #action-buttons>
        <button
          id="add-clinic-sub-header-button"
          type="button"
          class="sub-header-action-button"
          @click.prevent="showAddClinic"
        >
          {{ $t("action.addClinic") }}
        </button>
        <button
          v-if="isReportsSupported"
          id="download-report-sub-header-button"
          type="button"
          class="sub-header-action-button"
          @click.prevent="downloadReport"
        >
          {{ $t("action.downloadReport") }}
        </button>
      </template>
      <template #last-viewed>
        {{ formattedClinicCount }}
      </template>
    </sub-header>
    <clinic-search @search="search" @hide-test="hideTestUpdate" />
    <div class="container-fluid main-section clinic-list-container">
      <div
        v-if="clinicsResult === null"
        id="clinic-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("common.loading") }}</span>
      </div>
      <div v-else-if="clinics && clinics.length > 0" id="clinics-list">
        <clinic-list-table :clinics="clinics"></clinic-list-table>
      </div>
      <div v-else id="clinic-list-error" class="data-empty-container">
        <span>{{ $t("clinic.errorNoneFound") }}</span>
      </div>
    </div>

    <add-edit-clinic-modal modal-id="add-clinic-modal" @added="getClinics">
    </add-edit-clinic-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { Clinic } from "@/shared/types/clinic";
import ClinicSearch from "@/shared/components/ClinicSearch.vue";
import VueI18n from "vue-i18n";
import LocaleMessage = VueI18n.LocaleMessage;

export default defineComponent({
  components: {
    ClinicSearch,
  },
  data: function () {
    return {
      // Subheader Configuration
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewPatientList"),
          id: "view-patient-list-sub-header-button",
          event: "view-patient-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("action.addClinic"),
          id: "add-clinic-sub-header-button",
          event: "add-clinic-button-event",
        },
        {
          label: this.$t("action.downloadReport"),
          id: "download-report-sub-header-button",
          event: "download-report-button-event",
        },
      ],
      navigationButtonsRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      // Clinics Data
      clinicsResult: false,
      clinics: [] as Clinic[],
      hideTest: true,
      searchText: null,
    };
  },
  computed: {
    formattedClinicCount(): string | LocaleMessage {
      if (this.clinics) {
        return this.$t("clinic.labelTotalClinics", {
          count: this.clinics.filter((clinic) => clinic.active).length,
        });
      }
      return "";
    },
  },
  watch: {
    searchText: {
      handler() {
        this.getClinics();
      },
    },
    hideTest: {
      handler() {
        this.getClinics();
      },
    },
  },
  mounted: function () {
    // This needs to be in mounted rather than created because the popover
    // might be shown on the result of the next call
    this.getClinics();
  },
  methods: {
    downloadReport() {
      this.api.v1ReportClinicsGet().then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "clinics_report.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    hideTestUpdate(hideTest: boolean) {
      this.hideTest = hideTest;
    },
    search(searchText: string) {
      this.searchText = searchText;
    },
    async getClinics() {
      this.clinics = null;
      this.clinicsResult = null;
      const search = this.searchText || undefined;
      const include = this.hideTest ? undefined : "test";

      try {
        const clinicResponse = await this.api.v1ClinicsGet(search, include);
        this.clinics = clinicResponse.data.clinics;
        this.clinicsResult = true;
      } catch (error) {
        this.clinicsResult = false;
        // Get the error message and display it
        this.showApiResponseError(error, this.$t("clinic.errorLoadList"));
      }
    },
    navViewPatientListAll: function () {
      this.$router.push({ name: "patient-list-all" });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    showAddClinic: function () {
      this.$bvModal.show("add-clinic-modal");
    },
  },
});
</script>
