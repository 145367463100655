<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtons"
      :navigation-buttons-right="navigationButtonsSupportRight"
      :action-buttons="actionButtons"
      @view-clinic-details-event="navViewClinicDetails"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
      @add-clinic-user-button-event="showAddClinicUser"
    >
      <template #last-viewed>
        {{ formattedClinicUserCount }}
      </template>
    </sub-header>

    <div class="container-fluid main-section clinic-user-list-container">
      <div
        v-if="clinicUsersLoadResult === null"
        id="clinic-user-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("common.loading") }}</span>
      </div>
      <div
        v-else-if="clinicUsers && clinicUsers.length > 0"
        id="clinic-user-list"
      >
        <clinic-user-list-table
          :clinic-id="clinicIdParameter"
          :clinic-users="clinicUsers"
          @refresh-list="getClinicUsers"
          @row-clicked="showEditClinicUser"
        >
        </clinic-user-list-table>
      </div>
      <div v-else id="clinic-user-list-error" class="data-empty-container">
        <span>{{ $t("clinicalUser.errorNoneFound") }}</span>
      </div>
    </div>

    <add-edit-clinic-user-modal
      modal-id="add-edit-clinic-user-modal"
      :clinic-id="clinicIdParameter"
      :clinic-user-to-edit="clinicUserToEdit"
      @updated="getClinicUsers"
    >
    </add-edit-clinic-user-modal>

    <b-popover
      id="add-clinic-user-first-popover"
      ref="add_clinic_user_first_popover"
      target="add-clinic-user-sub-header-button"
      triggers="blur"
      placement="bottom"
    >
      {{ $t("clinicalUser.popoverClickToAdd") }}
    </b-popover>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      // Subheader Configuration
      navigationButtons: [
        {
          label: this.$t("navigation.viewClinicDetailsWithBack"),
          id: "view-clinic-details-sub-header-button",
          event: "view-clinic-details-event",
        },
      ],
      navigationButtonsSupportRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("action.addClinicalUser"),
          id: "add-clinic-user-sub-header-button",
          event: "add-clinic-user-button-event",
        },
      ],
      // Clinic User Data
      clinicUsersLoadResult: false,
      clinicUsers: null,
      // Edit clinic Users
      clinicUserToEdit: null,
    };
  },
  computed: {
    clinicIdParameter: function () {
      // Clinic id is converted to a string in the router, we require it as a integer
      return parseInt(this.$route.params.clinicId, 10);
    },
    formattedClinicUserCount: function () {
      if (this.clinicUsers) {
        return this.$t("clinicalUser.labelTotalUser", {
          count: this.clinicUsers.length,
        });
      }

      return "";
    },
  },
  mounted: function () {
    // This needs to be in mounted rather than created because the popover
    // might be shown on the results of one of the next calls
    this.getClinicUsers();
  },
  methods: {
    getClinicUsers: function () {
      this.clinicUsersLoadResult = null;
      this.clinicUsers = [];

      this.api
        .v1ClinicClinicidUsersGet(this.clinicIdParameter)
        .then((response) => {
          this.clinicUsers = response.data.clinicusers;
          this.clinicUsersLoadResult = true;

          // Check to see if the popover should be shown
          if (
            !this.clinicUsers ||
            (Array.isArray(this.clinicUsers) && this.clinicUsers.length === 0)
          ) {
            this.controlFirstClinicUserPopover(true);
          } else {
            this.controlFirstClinicUserPopover(false);
          }
        })
        .catch((error) => {
          this.clinicUsersLoadResult = false;

          // Make sure the popover is closed if it exists
          this.controlFirstClinicUserPopover(false);

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadList")
          );
        });
    },
    navViewClinicDetails: function () {
      this.$router.push({
        name: "clinic-detail",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    showAddClinicUser: function () {
      this.clinicUserToEdit = null;
      this.$bvModal.show("add-edit-clinic-user-modal");
    },
    showEditClinicUser: function (clinicUserId) {
      this.clinicUserToEdit = this.clinicUsers.find(
        (item) => item.id === clinicUserId
      );
      this.$bvModal.show("add-edit-clinic-user-modal");
    },
    controlFirstClinicUserPopover: function (show) {
      var popover = this.$refs.add_clinic_user_first_popover;

      if (popover) {
        popover.$emit(show ? "open" : "close");
      }
    },
  },
};
</script>
