<!-- FormInput.vue -->
<template>
  <b-form-group>
    <label :for="inputId">
      <!-- Use slot for label; fallback to prop if slot is not provided -->
      <slot name="label">{{ label }}</slot>
    </label>
    <b-form-input
      :id="inputId"
      class="form-input"
      :value="modelValue"
      :state="validationState"
      type="text"
      :placeholder="placeholder"
      aria-describedby="`${inputId}-feedback`"
      :maxlength="maxlength"
      @input="updateValue"
      @blur="handleBlur"
    />
    <b-form-invalid-feedback :id="`${inputId}-feedback`">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
export default {
  props: {
    label: String,
    modelValue: String,
    validationState: {
      type: Boolean,
      default: null,
    },
    placeholder: String,
    inputId: {
      type: String,
      required: true,
    },
    maxlength: Number,
    invalidFeedback: String,
  },
  emits: ["update:modelValue", "blur"],
  methods: {
    handleBlur() {
      this.$emit("blur");
    },
    updateValue(value) {
      // Emit the new value to the parent component
      this.$emit("update:modelValue", value);
    },
  },
};
</script>

<style scoped lang="scss">
.form-input {
  padding-left: 0px;
}
</style>
