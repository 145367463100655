<template>
  <b-modal
    id="add-device-modal"
    centered
    hide-header
    hide-footer
    @show="onShow"
  >
    <section-header
      data-testid="add-device-modal-title"
      :header-text="$t('deviceModal.title')"
    />
    <b-form class="left-aligned" @submit.prevent="addDevice">
      <div v-if="loading" style="width: 100%">
        <div class="loading-container">
          <div>
            <b-spinner variant="primary" />
          </div>
        </div>
        <div class="loading-container">
          <p>Please be patient this could take a few minutes.</p>
        </div>
      </div>
      <div v-if="!loading">
        <label for="gsn-input">
          {{ $t("deviceModal.labelGsn") }}
          <span v-b-tooltip.hover :title="$t('deviceModal.tooltip')">
            <SVGIconWrapper class="button-link">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                class="size-6"
              >
                <path
                  fill-rule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 0 1 .67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 1 1-.671-1.34l.041-.022ZM12 9a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
                  clip-rule="evenodd"
                />
              </svg>
            </SVGIconWrapper>
          </span>
        </label>
        <b-form-group>
          <b-form-input
            id="gsn-input"
            ref="gsnInput"
            data-testid="gsn-input"
            :state="validateField('gsn')"
            aria-describedby="serial-number-feedback"
            :value="gsn"
            :placeholder="$t('deviceModal.placeholderGsn')"
            @input="handleInput"
            @blur.native="v$.gsn.$touch()"
          />
          <b-form-invalid-feedback id="serial-number-feedback">
            {{ $t("deviceModal.serialNumberFeedback") }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div>
          <b-form-group
            v-slot="{ ariaDescribedby }"
            :label="$t('deviceModal.radioLabel')"
          >
            <b-form-radio-group
              id="add-device-type-radio-options"
              data-testid="device-type-options"
              :checked="selected"
              button-variant="outline-primary"
              size="lg"
              buttons
              :options="options"
              :aria-describedby="ariaDescribedby"
              name="device-radio-options"
              @input="handleSelect"
            />
          </b-form-group>
        </div>
      </div>
      <div class="buttons-container">
        <FormButton
          id="add-supplies-gsn-button"
          data-testid="add-supplies-gsn-button"
          :disabled="!isFormValid || loading"
          :text="$t('common.buttonAdd')"
          @click.native="addDevice"
        />
        <FormButton
          id="add-supplies-cancel-button"
          data-testid="add-supplies-cancel-button"
          class="button-negative"
          :disabled="loading"
          :text="$t('common.buttonCancel')"
          @click.native="onCancel"
        />
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { isValidGSN, getHardwareType, debounce } from "@/shared/utils";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import FormButton from "@/shared/components/Primitives/FormButton.vue";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";

// The specifications we have here
// https://wearealio.atlassian.net/wiki/spaces/SOF/pages/1601273867/QR+Code+Specification
// tell us that we can extract the serial number between the (21) and (240)
const SERIAL_NUMBER_REGEX = /\d+(?=\(240\))/;
export default {
  components: {
    SVGIconWrapper,
    FormButton,
    SectionHeader,
  },
  props: {
    patientId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      loading: false,
      gsn: "",
      selected: null,
      options: [
        { value: "kit", text: this.$t("deviceModal.kit") },
        { value: "hub", text: this.$t("deviceModal.hub") },
        { value: "patch", text: this.$t("deviceModal.patch") },
        { value: "patchbox", text: this.$t("deviceModal.patchbox") },
      ],
    };
  },
  validations() {
    return {
      gsn: {
        required,
        isValidGSN,
      },
      selected: {
        required,
      },
    };
  },
  computed: {
    isFormValid: function () {
      return !this.v$.$invalid;
    },
  },
  methods: {
    validateField(field) {
      const { $dirty, $error } = this.v$[field];
      return $dirty ? !$error : null;
    },
    onShow: function () {
      this.v$.$reset();
      this.gsn = "";
      this.selected = null;
      setTimeout(() => {
        this.$nextTick(() => {
          this.$refs.gsnInput.focus();
        });
      }, 100);
    },
    handleSelect: function (value) {
      this.selected = value;
    },
    handleInput(value) {
      this.gsn = value;
      this.checkBarcodeMatch(value);
    },
    checkBarcodeMatch: debounce(function (input) {
      const matches = input.match(SERIAL_NUMBER_REGEX);
      if (matches && matches.length > 0) {
        this.gsn = matches[0];
        this.selected = getHardwareType(this.gsn);
      } else {
        this.gsn = input;
        if (this.gsn.length > 9) {
          this.selected = getHardwareType(this.gsn);
        }
      }
    }, 100),
    async addKit(serial_number) {
      return this.api.v1PatientPatientidStarterKitPost(this.patientId, {
        serial_number,
      });
    },
    async addHub(serial_number) {
      return this.api.v1PatientPatientidHubPost(this.patientId, {
        serial_number,
      });
    },
    async addDevice() {
      this.loading = true;

      if (this.v$.$invalid) return;
      const supplies = {};
      supplies[`${this.selected}gsn`] = this.gsn;

      // KITS require a retry
      if (["kit", "hub"].includes(this.selected)) {
        try {
          if (this.selected === "kit") {
            await this.addKit(this.gsn);
          } else {
            await this.addHub(this.gsn);
          }
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("deviceModal.asyncSuccess")
          );

          // On success indicate that it was added
          this.$emit("added");
        } catch (error) {
          switch (error.response.status) {
            case 403:
              this.$bus.emit(
                "show-general-error",
                this.$t(this.selected + ".errorPatientNotAMember")
              );
              break;

            case 409:
              this.$bus.emit(
                "show-general-error",
                this.$t(this.selected + ".errorSuppliesInvalid")
              );
              break;
          }
        }
      } else {
        try {
          await this.api.v1PatientPatientidSuppliesPost(this.patientId, {
            supplies,
          });
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t(this.selected + ".successAdd")
          );

          // On success indicate that it was added
          this.$emit("added");
        } catch (error) {
          switch (error.response.status) {
            case 403:
              this.$bus.emit(
                "show-general-error",
                this.$t(this.selected + ".errorPatientNotAMember")
              );
              break;

            case 404:
              const messageJson = error.response.data?.msg?.en;

              let errorMessage = this.$t("deviceModal.errorNotFound");
              try {
                errorMessage = JSON.parse(messageJson).message;
              } catch (error) {
                console.error(error);
              }

              this.$bus.emit("show-general-error", errorMessage);
              break;

            case 409:
              this.$bus.emit(
                "show-general-error",
                this.$t(this.selected + ".errorSuppliesInvalid")
              );
              break;
          }
        }
      }
      this.loading = false;
      this.$bvModal.hide("add-device-modal");
    },
    onCancel: function () {
      this.$bvModal.hide("add-device-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.left-aligned {
  text-align: left;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  gap: 24px;
  flex-wrap: nowrap;
}
</style>

<style lang="scss">
// for some reason we have a global margin-right for labels
#add-device-type-radio-options {
  label {
    margin-right: 0px !important;
  }
}
</style>
