// This is a global mixin, it is applied to every vue instance
import Vue from "vue";
import { ClinicUser, Patient, Clinic } from "@/shared/api-client/api";
import { defineComponent } from "vue";
import { fieldExists, getField, setField } from "@/shared/utils";

export default defineComponent({
  data() {
    return {
      THIRTY_MINUTES_EPOCH_SEC: 1800,
      HOUR_EPOCH_SEC: 3600,
      DAY_EPOCH_SEC: 86400,
      WEEK_EPOCH_SEC: 604800,
      MONTH_EPOCH_SEC: 2629746,
    };
  },
  methods: {
    round: (num: number, precision: number): number => {
      const modifier = 10 ** precision;
      return Math.round(num * modifier) / modifier;
    },
    arrayExistsWithData: function (array: any): boolean {
      if (array && Array.isArray(array) && array.length > 0) {
        return true;
      }

      return false;
    },
    fieldExists: fieldExists,
    getField: getField,
    setField: setField,
    getFieldPhi(jsonObject: any, key: string) {
      if (!jsonObject || !key) {
        return "";
      }

      if (!this.fieldExists(jsonObject, key)) {
        return "******";
      }

      const value = this.getField(jsonObject, key);
      if (!value) {
        return "-";
      }

      return value;
    },
    formatPatientName: function (patient: Patient) {
      if (!patient) {
        return "-";
      }

      const lastName = this.getFieldPhi(patient, "lastname");
      const firstName = this.getFieldPhi(patient, "firstname");

      return `${firstName} ${lastName}`;
    },
    formatUserName: function (user: ClinicUser) {
      // The only user type currently supported is the clinicUser, so return that
      // However in the future this could support different user types.
      return this.formatClinicUserName(user);
    },
    formatClinicUserName: function (clinicUser: ClinicUser) {
      if (!clinicUser) {
        return "-";
      }

      const nameElements = [
        clinicUser.title,
        clinicUser.firstname,
        clinicUser.lastname,
        clinicUser.suffix,
      ];
      const filteredNameElements = nameElements.filter(function (e) {
        return e;
      });

      return filteredNameElements.join(" ");
    },
    isClinicMetric: function (clinic: Clinic) {
      if (clinic !== null && clinic.tempdisplayunits === "celsuis") {
        return true;
      }

      return false;
    },
    isClinicUserAdmin: function (clinicUser: ClinicUser, clinicId: number) {
      if (
        clinicUser &&
        clinicUser.adminofclinics &&
        Array.isArray(clinicUser.adminofclinics) &&
        clinicId &&
        clinicUser.adminofclinics.includes(clinicId)
      ) {
        return true;
      }

      return false;
    },
    isSupportUserAdmin: function (user: ClinicUser) {
      if (user && user.groups && user.groups.includes("gwadmin")) {
        return true;
      }

      return false;
    },
    isClinician: function (user: ClinicUser) {
      if (user && user.groups && user.groups.includes("clinician")) {
        return true;
      }

      return false;
    },
    isOnlyClinician: function (user: ClinicUser) {
      if (
        user &&
        user.groups &&
        user.groups.length === 1 &&
        user.groups.includes("clinician")
      ) {
        return true;
      }

      return false;
    },
    hasPhiAccess: function (user: ClinicUser) {
      if (
        user &&
        user.groups &&
        (user.groups.includes("phicustomerservice") ||
          user.groups.includes("clinician"))
      ) {
        return true;
      }

      return false;
    },
    isOrdinalPotassium: function (clinic: Clinic) {
      return clinic && clinic.ordinal_potassium;
    },
    hasPotassiumFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurepotassium) {
        return true;
      }

      return false;
    },
    hasHeartRateFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featureheartrate) {
        return true;
      }

      return false;
    },
    hasHrvFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurehrv) {
        return true;
      }

      return false;
    },
    hasAuscultationFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featureauscultation) {
        return true;
      }

      return false;
    },
    hasSpo2FeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurespo2) {
        return true;
      }

      return false;
    },
    hasRBVFeatureSupport: function (clinic: Clinic) {
      return clinic && clinic.feature_rbv;
    },
    hasHctFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurehct) {
        return true;
      }

      return false;
    },
    hasHgbFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurehgb) {
        return true;
      }

      return false;
    },
    hasLogisticsFeatureSupport: function (clinic: Clinic) {
      if (clinic && clinic.featurelogistics) {
        return true;
      }

      return false;
    },
    getLastNonNullYData: function (data: any[]) {
      if (!data) {
        return null;
      }

      for (let i = data.length - 1; i >= 0; i--) {
        const entry = data[i];

        if (entry.y !== null) {
          return entry;
        }
      }
    },
    getDaysSince: function (epochS: number) {
      if (!epochS) {
        return 0;
      }

      const nowEpochS = new Date().getTime() / 1000;
      let value = Math.floor((nowEpochS - epochS) / 86400);

      // Note that it is possible this to return a negative number as sometimes
      // data points come in the future because of time issues. So making sure
      // that zero is the bottom
      if (value < 0) {
        value = 0;
      }

      return value;
    },
    createDateInLocalTz: function (dateString: string) {
      const date = new Date(dateString);
      return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    },
    getLocaleDateString: function (date: any) {
      if (isNaN(date)) {
        return "";
      }
      return this.$d(date, "date");
    },
    getIsoDateString: function (date: any) {
      if (isNaN(date)) {
        return "";
      }
      const year = date.getFullYear();
      let month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;
      let day = date.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return year + "-" + month + "-" + day;
    },
    dateFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        const formattedDate = this.dateOrEpochFormat(value, true);
        if (formattedDate) {
          return formattedDate;
        }
      }

      return "-";
    },
    dateOrEpochFormat: function (date: Date, todayCapitalized: boolean) {
      let dateToFormat = null;

      if (date instanceof Date) {
        dateToFormat = date;
      } else if (typeof date === "number") {
        // This is expected to be the epoch in seconds, but date expects ms
        dateToFormat = new Date(date * 1000);
      } else if (typeof date === "string") {
        if (date === "?" || date === "-") {
          // if the string is set any of these values, just return
          // them directly
          return date;
        }

        dateToFormat = new Date(date);
      }

      if (dateToFormat) {
        return this.$d(dateToFormat, "table");
      }

      return "";
    },
    formatSecondsToTime: function (timeS: number) {
      if (!timeS) {
        return "0:00";
      }

      // Hours, minutes and seconds
      const hrs = ~~(timeS / 3600);
      const mins = ~~((timeS % 3600) / 60);
      const secs = ~~timeS % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      let formattedValue = "";

      if (hrs > 0) {
        formattedValue += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      formattedValue += "" + mins + ":" + (secs < 10 ? "0" : "");
      formattedValue += "" + secs;
      return formattedValue;
    },
    toString: function (value: any): string {
      if (value === null || typeof value === "undefined") {
        return "";
      } else if (value instanceof Object) {
        return Object.keys(value)
          .sort()
          .map((key) => this.toString(value[key]))
          .join(" ");
      } else {
        return String(value);
      }
    },
    convertTemperature: function (
      value: number,
      fromUnits: string,
      toUnits: string
    ) {
      if (fromUnits === toUnits) {
        return value;
      } else if (fromUnits === "celsius" && toUnits === "fahrenheit") {
        return value * (9 / 5) + 32;
      } else if (fromUnits === "fahrenheit" && toUnits === "celsius") {
        return (value - 32) * (5 / 9);
      }

      return null;
    },
    convertAndFormatTemperature: function (
      value: number,
      fromUnits: string,
      toUnits: string
    ) {
      const convertedTemperature = this.convertTemperature(
        value,
        fromUnits,
        toUnits
      );

      if (convertedTemperature) {
        if (toUnits === "celsius") {
          // Celsius is displayed with a single decimal place
          return this.round(convertedTemperature, 1);
        } else if (toUnits === "fahrenheit") {
          // Fahrenheit is displayed as an integer
          return this.round(convertedTemperature, 0);
        } else {
          return convertedTemperature;
        }
      } else {
        return convertedTemperature;
      }
    },
    getUnitsString: function (units: string) {
      if (units === "celsius") {
        return "°C";
      } else if (units === "fahrenheit") {
        return "°F";
      } else if (units === "bpm") {
        return " BPM";
      } else if (units === "ms") {
        return " MS";
      } else if (units === "spo2") {
        return " SPO2";
      } else if (units === "%") {
        return "%";
      } else if (units === "g/dL") {
        return " g/dL";
      } else if (units === "mEq/L") {
        return " mEq/L";
      }
    },
    withinGoodRange(value: number, chartData: any) {
      return (
        value >= chartData?.goodrangefloor &&
        value <= chartData?.goodrangeceiling
      );
    },
    hubPatchDataFromPatient: function (patient: any) {
      if (!patient) {
        return null;
      }

      let combinedData = [];
      const processedPatches: any = [];

      if (patient.patches) {
        // Order patches in reverse as the latest state is the most important
        const sortedPatches = patient.patches.sort((a: any, b: any) => {
          return (
            new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
          );
        });

        sortedPatches.forEach((item: any) => {
          const patchAlreadyExists = processedPatches.find(
            (processedItem: any) => processedItem.device_id === item.device_id
          );

          if (!patchAlreadyExists) {
            const status = item.status;
            const startDate = item.date_start;
            const endDate = item.date_end;

            // Last status is current start
            if (status === "expired") {
              // Find active state for this patch, this will be used as the startdate
              const activeItem: any = sortedPatches.find((activeItem: any) => {
                return (
                  activeItem.device_id === item.device_id &&
                  activeItem.status === "active"
                );
              });

              processedPatches.push({
                device_id: item.device_id,
                configurations: item.configurations,
                status: "inactive",
                start_date: activeItem ? activeItem.date_start : "?",
                end_date: item.date_start,
              });
            } else if (status === "active") {
              processedPatches.push({
                device_id: item.device_id,
                configurations: item.configurations,
                status: "active",
                start_date: item.date_start,
                end_date: null,
              });
            } else if (status === "registered") {
              if (startDate != null && endDate != null) {
                // Ignore this patch as this means it has been reassigned. Any patch
                // with both a non-null start date and end date - BUT no active state should
                // not be shown as it has been reassigned. Note that we should never
                // get here if the patch has an 'active' state.
              } else {
                processedPatches.push({
                  device_id: item.device_id,
                  configurations: item.configurations,
                  status: "registered",
                  start_date: null,
                  end_date: null,
                });
              }
            }
          }
        });
      }

      // Add in the version information to the patches from the patchinfo
      if (patient.patchinfo && patient.patchinfo.length) {
        patient.patchinfo.forEach(function (entry: any) {
          for (let i = 0; i < processedPatches.length; i++) {
            const patch = processedPatches[i];

            // Note that the device_id is actually the device_id in the patch info table
            if (patch.device_id === entry.device_id) {
              patch.patch_id = entry.patch_id;
              patch.hardware_version = entry.hardware_version;
              patch.firmware_version = entry.firmware_version;
            }
          }
        });
      }

      if (patient.hubs !== null) {
        // It is important to note that they have different fields,
        // that'll be handled by the list processors
        combinedData = patient.hubs.concat(processedPatches);
      } else {
        combinedData = processedPatches;
      }

      return combinedData;
    },
    getSupportInfoLinkTag(supportInfo: any, key: any, item: any, label: any) {
      if (supportInfo !== null && this.fieldExists(supportInfo, key)) {
        const urlRaw = this.getField(supportInfo, key);

        if (urlRaw) {
          let urlResult = urlRaw;

          const regex = /{(.*?)}/g;
          let regexResult;
          while ((regexResult = regex.exec(urlRaw)) !== null) {
            let value = "";
            const element = regexResult[1];

            switch (element) {
              case "hub_mac":
                value = this.getField(item, "device_id");
                urlResult = urlResult.replace("{" + element + "}", value);
                break;

              case "patch_mac":
                value = this.getField(item, "device_id");
                urlResult = urlResult.replace("{" + element + "}", value);
                break;

              case "patient_id":
                value = this.getField(item, "patient_id");
                if (value === null) {
                  // If the item passed in is patient, then just use this id if patient_id is null
                  value = this.getField(item, "id");
                }
                urlResult = urlResult.replace("{" + element + "}", value);
                break;

              case "start_date_iso":
                const dateStart = new Date();
                dateStart.setDate(dateStart.getDate() - 28);
                value = dateStart.toISOString();
                urlResult = urlResult.replace("{" + element + "}", value);
                break;

              case "end_date_iso":
                const dateEnd = new Date();
                value = dateEnd.toISOString();
                urlResult = urlResult.replace("{" + element + "}", value);
                break;
            }
          }

          return (
            '<a href="' +
            urlResult +
            '" target="_blank" rel="noopener noreferrer">' +
            label +
            "</a>"
          );
        }
      }

      return "<a>" + this.$t("common.errorLinkError") + "</a>";
    },
  },
});
