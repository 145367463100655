<template>
  <b-modal :id="modalId" centered hide-header hide-footer>
    <section-header :header-text="$t('endMonitoring.title')" />
    <p>
      <span>{{ $t("endMonitoring.prompt.beginning") }}</span>
      <span
        ><strong>{{ $t("endMonitoring.prompt.middle") }}</strong></span
      >
      <span>{{ $t("endMonitoring.prompt.end") }}</span>
    </p>
    <b-form @submit.prevent="endEarly">
      <b-form-group class="input-container">
        <b-form-textarea
          id="end-monitoring-reason"
          v-model="v$.inputFields.reason.$model"
          :state="validateState('reason')"
          type="text"
          :placeholder="$t('endMonitoring.reason')"
          tabindex="2"
          rows="4"
        />
      </b-form-group>
      <div class="buttons-container">
        <button
          id="end-early-button"
          :disabled="!buttonsEnabled || !isEndEnabled"
          type="submit"
        >
          {{ $t("common.buttonEnd") }}
        </button>
        <button
          id="stop-monitoring-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    patientId: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      buttonsEnabled: true,
      inputFields: {
        reason: null,
      },
    };
  },
  validations() {
    return {
      inputFields: {
        reason: {
          required,
        },
      },
    };
  },
  computed: {
    isEndEnabled() {
      const formInvalid = this.v$.$invalid;
      if (formInvalid) return false;

      return true;
    },
  },
  methods: {
    show() {
      this.v$.$reset();
      this.inputFields.reason = null;
    },
    endEarly() {
      this.buttonsEnabled = false;

      this.api
        .v1PatientPatientidEndmonitoringPut(this.patientId, {
          monitoring_end_reason: this.inputFields.reason,
        })
        .then(() => {
          // On success hide the modal
          this.$bvModal.hide(this.modalId);

          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("patient.successMonitoringEnd")
          );

          // On success indicate that an update was made
          this.$emit("updated");

          // Renable the buttons
          this.buttonsEnabled = true;
        })
        .catch((error) => {
          this.showApiResponseError(
            error,
            this.$t("patient.errorPlaceholderMonitoringEnd")
          );

          // Renable the buttons
          this.buttonsEnabled = true;
        });
    },
    cancel() {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
