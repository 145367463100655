<template>
  <b-modal
    :id="modalId"
    centered
    hide-header
    hide-footer
    @shown="onShown"
    @hidden="onHidden"
  >
    <section-header header-text="Success!" />
    <p class="modal-success-message">{{ successMessage }}</p>
    <div class="buttons-container">
      <button
        id="general-success-close"
        ref="generalSuccessClose"
        @click="onClose"
      >
        {{ $t("common.buttonClose") }}
      </button>
    </div>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      shown: false,
      queuedMessages: [],
      successMessage: null,
    };
  },
  mounted() {
    this.$bus.on("show-general-success", this.queueGeneralSuccess);
  },
  beforeDestroy() {
    this.$bus.off("show-general-success", this.queueGeneralSuccess);
  },
  methods: {
    onClose: function () {
      this.$bvModal.hide(this.modalId);
    },
    queueGeneralSuccess: function (successMessage) {
      this.queuedMessages.push({
        successMessage: successMessage,
      });

      this.showNextGeneralSuccess();
    },
    showNextGeneralSuccess: function (successMessage) {
      if (!this.shown) {
        var item = this.queuedMessages.shift();
        if (item) {
          this.shown = true;

          if (item.successMessage) {
            this.successMessage = item.successMessage;
          } else {
            // This should never happen, just indicate unspecified
            // to flag to the developer that this did happen.
            this.successMessage = this.$t("common.errorMessageNotSpecified");
          }

          this.$bvModal.show(this.modalId);
        }
      }
    },
    onShown: function () {
      this.$refs.generalSuccessClose.focus();
    },
    onHidden: function () {
      this.shown = false;
      this.showNextGeneralSuccess();
    },
  },
};
</script>
