<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="showModal">
    <section-header :header-text="$t('passwordChange.title')" />
    <p id="password-reset-info">{{ $t("passwordChange.info") }}</p>
    <b-form @submit.prevent="submit">
      <b-form-group class="input-container">
        <b-form-input
          id="password-reset-password"
          v-model="v$.inputFields.password.$model"
          :state="validateState('password')"
          type="password"
          :placeholder="$t('passwordChange.fieldPlaceholderNewPassword')"
          :maxlength="getInputMaxChar('password')"
          aria-describedby="new-change-password-live-feedback"
          autocomplete="new-password"
          @blur.native="v$.inputFields.password.$touch()"
        />
        <b-form-invalid-feedback id="new-change-password-live-feedback">
          {{ formattedPasswordRequirements }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="input-container">
        <b-form-input
          id="password-reset-password-verify"
          v-model="v$.inputFields.passwordVerify.$model"
          :state="validateState('passwordVerify')"
          type="password"
          :placeholder="$t('passwordChange.fieldPlaceholderReTypePassword')"
          :maxlength="getInputMaxChar('password')"
          aria-describedby="change-verify-password-live-feedback"
          autocomplete="new-password"
          @blur.native="v$.inputFields.passwordVerify.$touch()"
        />
        <b-form-invalid-feedback id="change-verify-password-live-feedback">
          {{ $t("validations.passwordVerify") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div id="password-reset-buttons-container" class="buttons-container">
        <button
          id="password-reset-submit-button"
          type="submit"
          :disabled="!submitEnabled || !buttonsEnabled"
        >
          {{ $t("common.buttonSubmit") }}
        </button>
        <button
          id="password-reset-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, sameAs } from "@vuelidate/validators";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { confirmSignIn } from "aws-amplify/auth";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      buttonsEnabled: true,
      inputFields: {
        password: null,
        passwordVerify: null,
      },
    };
  },
  validations() {
    return {
      inputFields: {
        password: {
          required,
          validPassword: this.validPassword,
        },
        passwordVerify: {
          required,
          sameAsPassword: sameAs(this.inputFields.password),
        },
      },
    };
  },
  computed: {
    formattedPasswordRequirements: function () {
      return this.getInputPasswordRequirements();
    },
    submitEnabled() {
      return !this.v$.$invalid;
    },
  },
  methods: {
    async submit() {
      this.buttonsEnabled = false;

      try {
        const { isSignedIn } = await confirmSignIn({
          challengeResponse: this.inputFields.password,
        });

        if (isSignedIn) {
          // On success hide the modal and show a success message
          this.$bvModal.hide(this.modalId);
          this.$bus.emit(
            "show-general-success",
            this.$t("passwordChange.success")
          );
        } else {
          throw new Error("Failed to confirm sign in with new password");
        }
      } catch (error) {
        // Do not hide the modal on error - allow the user to try again
        this.showCognitoResponseError(
          error,
          this.$t("passwordChange.errorPlaceholder")
        );
      } finally {
        // Renable the buttons
        this.buttonsEnabled = true;
        this.$emit("completed");
      }
    },
    cancel: function () {
      // close the modal
      this.$bvModal.hide(this.modalId);

      // Emit the following event if the new password was not entered,
      // technically the user should be logged out
      this.$emit("password-change-cancelled");
    },
    showModal: function () {
      this.v$.$reset();
      // Anytime this is shown, clear the input fields. This is so they
      // do not hold state from previous showings
      this.inputFields.password = null;
      this.inputFields.passwordVerify = null;
    },
  },
};
</script>
