import Vue from "vue";
import VueI18n from "vue-i18n";
import dateTimeFormats from "@/shared/i18n/dtf.js";
import getBrowserLocale from "@/shared/i18n/getBrowserLocale";
import supportedLocales from "@/shared/i18n/supportedLocales";

Vue.use(VueI18n);

function loadLocaleMessages() {
  // @ts-ignore
  const locales = import.meta.glob("@/shared/i18n/locales/*.json");
  const messages = {};

  const loadingPromises = Object.entries(locales).map(([path, importFn]) =>
    // @ts-ignore
    importFn().then((module) => {
      const matched = path.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        const locale = matched[1];
        messages[locale] = module.default;
      }
    })
  );

  return Promise.all(loadingPromises).then(() => messages);
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: false });
  if (Object.keys(supportedLocales).includes(browserLocale)) {
    return browserLocale;
  } else {
    return "en-GB";
  }
}

function createI18n() {
  return Promise.all([loadLocaleMessages(), getStartingLocale()]).then(
    ([messages, startingLocale]) => {
      return new VueI18n({
        silentFallbackWarn: true,
        silentTranslationWarn: true,
        locale: startingLocale,
        // @ts-ignore
        dateTimeFormats,
        fallbackLocale: "en-GB",
        messages,
      });
    }
  );
}

export default createI18n;
