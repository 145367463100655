<template>
  <b-input-group @click="emitClick">
    <b-form-input v-model="localValue" readonly class="range-input tw-w-auto" />
    <b-input-group-append>
      <b-input-group-text class="range-input-append">
        <b-icon-calendar></b-icon-calendar>
      </b-input-group-text>
    </b-input-group-append>
  </b-input-group>
</template>

<script>
import { BIconCalendar } from "bootstrap-vue";
import { ref, watch } from "vue";

export default {
  components: {
    BIconCalendar,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const localValue = ref(props.value);

    watch(
      () => props.value,
      (newValue) => {
        localValue.value = newValue;
      }
    );

    const emitClick = () => {
      emit("click");
    };

    return {
      localValue,
      emitClick,
    };
  },
};
</script>

<style scoped lang="scss">
.range-input {
  display: flex;
  border: none;
  border-radius: 4px;
}
.range-input:hover {
  cursor: pointer;
}
.range-input-append {
  border: none;
}
.range-input-append {
  border: none;
  cursor: pointer;
}
</style>
