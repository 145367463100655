<template>
  <div ref="chartdiv" :class="chartClass"></div>
</template>

<script>
import ChartMixin from "@/shared/mixins/ChartMixin";
import {
  Bullet,
  Circle,
  color,
  Container,
  Graphics,
  p50,
  Root,
  Template,
  Tooltip,
} from "@amcharts/amcharts5";
import {
  AxisRendererY,
  LineSeries,
  ValueAxis,
  XYChart,
} from "@amcharts/amcharts5/xy";

export default {
  name: "AvailabilityChart",
  mixins: [ChartMixin],
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartId: {
      type: String,
      required: true,
    },
    acute: {
      type: Boolean,
      default: false,
    },
    acuteMode: {
      type: String,
      default: "noChange",
    },
    upperBound: {
      type: Number,
    },
    lowerBound: {
      type: Number,
    },
    chart: {
      type: Object || null,
    },
    chartType: {
      validator: function (value) {
        return ["availability"].indexOf(value) !== -1;
      },
      required: true,
    },
    clickHandler: {
      type: Function,
    },
  },
  data() {
    return {
      chartLoaded: false,
      label: null,
      unitsRange: null,
      unitsRangeDisplay: null,
      rangeUnitMapping: {
        hct: {
          "%": this.$t("chart.labelRangeHematocrit"),
        },
        hgb: {
          "g/dL": this.$t("chart.labelRangeHemoglobin"),
        },
      },
    };
  },
  computed: {
    chartClass() {
      if (!this.chartLoaded) return "hidden-chart";
      return "availability-chart";
    },
    padFactor() {
      if (this.chartType === "hct") {
        return 0.5;
      } else {
        return 0.25;
      }
    },
    paddedMaxRange() {
      return this.chartData?.maxrange - this.padFactor;
    },
    paddedMinRange() {
      return this.chartData?.minrange + this.padFactor;
    },
  },
  watch: {
    chartData: function () {
      if (this.root) {
        this.root.dispose();
      }
      this.createChart();
    },
  },
  mounted() {
    this.createChart();
  },

  beforeDestroy() {
    if (this.root) {
      this.root.dispose();
    }
  },
  methods: {
    createChart() {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      this.root = Root.new(this.$refs.chartdiv);

      this.root.setThemes([this.getTheme(this.root)]);

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      const chart = this.root.container.children.push(
        XYChart.new(this.root, {
          maxTooltipDistance: 0,
          paddingRight: 42,
        })
      );
      chart.zoomOutButton.set("forceHidden", true);

      const yAxis = this.setupYAxis(chart, this.root);
      const xAxis = this.setupXAxis(
        chart,
        this.root,
        this.upperBound,
        this.lowerBound,
        this.lowerBound
      );

      const series = this.setupSeries(chart, this.root, xAxis, yAxis);
      this.createSeriesRanges(yAxis, series);
      this.setupAxisRanges(yAxis);

      series.data.setAll(this.setupData());

      series.appear(1000);
      chart.appear(1000, 100);

      this.chartLoaded = true;
    },
    setupHCTAndHGBBullets(root, series) {
      const bulletTemplate = Template.new(root, {});

      bulletTemplate.events.on("click", (ev) => {
        this.clickHandler(this.chartType, ev);
      });
      const bullet = series.bullets.push((root) => {
        const container = Container.new(
          root,
          {
            centerX: p50,
            centerY: p50,
          },
          bulletTemplate
        );

        container.states.create("hover", {}).setAll({
          scale: 1.3,
        });

        const circle = Circle.new(root, {
          radius: 6,
          strokeWidth: 2,
          stroke: this.ALIO_PURPLE,
          fill: this.ALIO_PURPLE,
        });

        circle.adapters.add("fill", (fill, target) => {
          if (!target.dataItem) {
            return fill;
          }
          if (
            this.withinGoodRange(target?.dataItem.get("valueY"), this.chartData)
          ) {
            return this.ALIO_SUCCESS_GREEN;
          } else {
            return fill;
          }
        });
        circle.adapters.add("stroke", (stroke, target) => {
          if (!target.dataItem) {
            return stroke;
          }
          if (
            this.withinGoodRange(target?.dataItem.get("valueY"), this.chartData)
          ) {
            return this.ALIO_SUCCESS_GREEN;
          } else {
            return stroke;
          }
        });

        container.children.push(circle);

        const downArrow = Graphics.new(root, {
          stroke: color("#ffffff"),
          strokeWidth: 2,
          strokeOpacity: 1,
          draw: function (display) {
            display.lineTo(-2, -1);
            display.lineTo(0, 1);
            display.lineTo(2, -1);
          },
        });

        downArrow.adapters.add("rotation", (rotation, target) => {
          if (!target.dataItem) {
            return rotation;
          }
          if (target.dataItem.get("valueY") === this.paddedMaxRange) {
            return 180;
          } else {
            return 0;
          }
        });

        downArrow.adapters.add("strokeOpacity", (strokeOpacity, target) => {
          if (!target.dataItem) {
            return strokeOpacity;
          }
          if (
            target.dataItem.get("valueY") !== this.paddedMaxRange &&
            target.dataItem.get("valueY") !== this.paddedMinRange
          ) {
            return 0;
          } else {
            return strokeOpacity;
          }
        });

        container.children.push(downArrow);

        return Bullet.new(root, {
          sprite: container,
        });
      });

      return bullet;
    },
    createSeriesRanges(yAxis, series) {
      const rdi = yAxis.makeDataItem({
        value: this?.chartData?.minrange,
        endValue: this?.chartData?.maxrange,
      });
      const range = series.createAxisRange(rdi);
      range.strokes.template.setAll({
        stroke: this.ALIO_SUCCESS_GREEN,
        strokeWidth: 2,
      });
      range.fills.template.setAll({
        fill: this.ALIO_SUCCESS_GREEN,
        fillOpacity: 1,
        visible: true,
      });
    },
    setupAxisRanges(yAxis) {
      this.createGrid(yAxis, this.chartData.minrange, true, false, true);
      this.createGrid(yAxis, this.chartData.maxrange, true, false, true);
    },
    setupSeries(chart, root, xAxis, yAxis) {
      const settings = {
        name: "Series",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: Tooltip.new(root, {
          labelText: this.getTooltipText(),
        }),
        showTooltipOn: "always",
      };

      settings.stroke = this.ALIO_SUCCESS_GREEN;
      settings.fill = this.ALIO_SUCCESS_GREEN;
      settings.connect = this.getLineConnect();
      settings.autoGapCount = this.getAutoGapCount(
        this.chartType,
        this.acute,
        this.acuteMode
      );

      const series = chart.series.push(LineSeries.new(root, settings));
      series.strokes.template.setAll({
        strokeWidth: 2,
      });
      return series;
    },
    setupYAxis(chart, root) {
      const yAxis = chart.yAxes.push(
        ValueAxis.new(root, {
          renderer: AxisRendererY.new(root, {
            opposite: true,
            minGridDistance: 20,
          }),
          strictMinMax: true,
          max: this.chartData.maxrange,
          min: this.chartData.minrange,
        })
      );

      const yRenderer = yAxis.get("renderer");
      yRenderer.labels.template.set("forceHidden", true);
      yRenderer.grid.template.set("forceHidden", true);
      yRenderer.setAll({
        minWidth: 35,
        opposite: true,
      });

      return yAxis;
    },
    getTooltipText() {
      return `{realValue.formatNumber('#,###.0')}${this.getUnitsString(
        this.unitsRangeDisplay
      )}`;
    },
    setupData() {
      return this.chartData.data.map((entry) => {
        const date = entry.x * 1000; // turn to ms
        const chartEntry = { date };

        chartEntry.value = entry.y;
        return chartEntry;
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.availability-chart {
  flex: 1;
  height: 128px;
}
</style>
