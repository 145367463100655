import { defineComponent } from "vue";
import { isSupportApp, isClinicianApp } from "@/shared/utils";

export default defineComponent({
  name: "AppMixin",
  computed: {
    isLowerEnv() {
      return (
        import.meta.env.VITE_APP_MODE === "DEV" ||
        import.meta.env.VITE_APP_MODE === "QA" ||
        import.meta.env.VITE_APP_MODE === "PREPROD"
      );
    },
    isSPO2Supported() {
      return !!this.$store.getters?.features?.SUPPORT_SPO2;
    },
    isRBVSupported() {
      return !!this.$store.getters?.features?.SUPPORT_RBV;
    },
    isOrdinalPotassiumSupported() {
      return !!this.$store.getters?.features?.SUPPORT_ORDINAL_POTASSIUM;
    },
    isReportsSupported() {
      return !!this.$store.getters?.features?.SUPPORT_REPORTS;
    },
  },
  methods: {
    isSupportApp() {
      return isSupportApp(this);
    },
    isClinicianApp() {
      return isClinicianApp(this);
    },
    verifyClinicId(clinicId: number) {
      if (this.isClinicianApp()) {
        const savedClinicId = parseInt(this.$store.getters.clinicId, 10);

        if (savedClinicId !== clinicId) {
          this.$bus.emit(
            "show-general-error",
            this.$t("clinic.errorInvalidSpecified")
          );
          this.navToLogin(null);

          return false;
        }
      }

      return true;
    },
    navToLogin(next: any) {
      this.$store.commit("updateLastRequest", null);
      const result = this.$router.resolve({ path: this.$route.path });

      if (result && result.resolved && result.resolved.name === "login") {
        // If it is currently the login page, no need to navigate - just exit
        return;
      }

      if (next !== null) {
        // If next is specified - make sure it not the login page. This ensures
        // it does not get into a loop. This can happen if multiple API errors
        // are seen
        const nextResult = this.$router.resolve({ path: next });
        if (
          nextResult &&
          nextResult.resolved &&
          nextResult.resolved.name === "login"
        ) {
          next = null;
        }
      }

      if (this.isClinicianApp()) {
        this.$router.push({
          name: "login",
          params: { site: this.$store.getters.site },
          query: next !== null ? { next: next } : null,
        });
      } else {
        this.$router.push({
          name: "login",
          query: next !== null ? { next: next } : null,
        });
      }
    },
    navToHome() {
      this.$router.push({ name: "find-home" });
    },
    showConfirmationPrompt(
      confirmationMessage: any,
      eventId: any,
      callback: any
    ) {
      const eventLabel =
        "confirmation-callback-event-" + (eventId ? eventId : "");
      const handler = function (state: any) {
        // Handler function for the return callback, ensures that confirmation event
        // is deregistered upon completion
        if (callback) {
          callback(state);
        }

        // Deregister the callback handler
        this.$bus.off(eventLabel, handler);
      };

      // Register the callback handler
      this.$bus.on(eventLabel, handler);
      this.$bus.emit(
        "show-confirmation-prompt",
        confirmationMessage,
        eventLabel
      );
    },
    isMicrosoftExplorer() {
      const userAgent = window.navigator.userAgent;
      const msie = userAgent.indexOf("MSIE ");
      if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
        return true;
      }

      return false;
    },
  },
});
