<script lang="ts">
import { defineComponent } from "vue";

const IMPERIAL = Symbol("imperial");
const METRIC = Symbol("metric");

export default defineComponent({
  name: "HeightWeightSelect",
  props: {
    height: {
      type: Number,
      default: null,
    },
    weight: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      IMPERIAL: IMPERIAL,
      METRIC: METRIC,
      isWeightFocused: false,
      isInchesFocused: false,
      isFeetFocused: false,
      feet: null,
      inches: null,
      pounds: null,
      feetOptions: [
        { text: this.$t("height.feet"), value: null, disabled: true },
        { text: "3'", value: 3 },
        { text: "4'", value: 4 },
        { text: "5'", value: 5 },
        { text: "6'", value: 6 },
        { text: "7'", value: 7 },
      ],
      inchOptions: [
        { text: this.$t("height.inches"), value: null, disabled: true },
        { text: '0"', value: 0 },
        { text: '1"', value: 1 },
        { text: '2"', value: 2 },
        { text: '3"', value: 3 },
        { text: '4"', value: 4 },
        { text: '5"', value: 5 },
        { text: '6"', value: 6 },
        { text: '7"', value: 7 },
        { text: '8"', value: 8 },
        { text: '9"', value: 9 },
        { text: '10"', value: 10 },
        { text: '11"', value: 11 },
      ],
      selectedUnit: IMPERIAL,
      availableUnits: [
        { text: "Imperial (ft/lbs)", value: IMPERIAL },
        { text: "Metric (m/kg)", value: METRIC },
      ],
    };
  },
  watch: {
    height() {
      this.convertHeightToFeetAndInches();
    },
    weight(newVal) {
      if (!this.isWeightFocused) {
        this.convertKGToPounds(newVal);
      }
    },
  },
  mounted() {
    this.setDefaultUnitBasedOnLocale();
    this.convertKGToPounds(this.weight);
    this.convertHeightToFeetAndInches();
  },
  methods: {
    metersFormatter(value: string | number | null) {
      if (!value) return null;
      if (typeof value === "string") {
        return parseFloat(value).toFixed(4);
      }
      return value ? value.toFixed(4) : null;
    },
    toFiveDigits(value: string | number | null) {
      if (value == null) return null;
      const num = typeof value === "string" ? parseFloat(value) : value;
      return num.toFixed(4).replace(/\.?0+$/, "");
    },
    setDefaultUnitBasedOnLocale() {
      const browserLocale = navigator.language;
      this.selectedUnit = this.getUnitSystemForLocale(browserLocale);
    },
    getUnitSystemForLocale(locale) {
      // Default to metric for most cases
      const imperialCountries = ["US", "LR", "MM"]; // Corrected variable name
      const countryCode = locale.split("-")[1]; // Get country code from locale

      // If the country code is in our list of imperial countries, return 'imperial'
      if (imperialCountries.includes(countryCode)) {
        return IMPERIAL;
      }

      // Otherwise, return 'metric'
      return METRIC;
    },
    convertKGToPounds(kg) {
      if (kg !== null) {
        let pounds = kg * 2.20462262185;
        // Check if the difference between the rounded number and its integer part is less than 0.01
        if (Math.abs(pounds - Math.round(pounds)) < 0.01) {
          pounds = Math.round(pounds); // Round to nearest integer
        } else {
          pounds = parseFloat(pounds.toFixed(2)); // Round to two decimal places
        }
        this.pounds = pounds;
      }
    },
    updateWeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.$emit("updateWeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateWeight", null);
        }
      }
    },
    updateHeight(newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.$emit("updateHeight", Number.parseFloat(newVal));
        } else {
          this.$emit("updateHeight", null);
        }
      }
    },
    updatePounds(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.convertPoundsToMeters(newVal);
      }
    },
    updateFeet(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.feet = newVal;
        this.convertFeetAndInchesToMeters();
      }
    },
    updateInches(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.inches = newVal;
        this.convertFeetAndInchesToMeters();
      }
    },
    convertPoundsToMeters(pounds) {
      this.pounds = pounds;
      if (pounds !== null) {
        const kg = pounds * 0.45359237;
        this.$emit("updateWeight", kg.toFixed(5));
      }
    },
    convertHeightToFeetAndInches() {
      if (this.height !== null) {
        const heightInFeet = this.height * 3.28084; // Convert meters to feet
        this.feet = Math.floor(heightInFeet);
        this.inches = Math.round((heightInFeet - this.feet) * 12);
      }
    },
    convertFeetAndInchesToMeters() {
      const totalFeet = this.feet + this.inches / 12;
      const meters = totalFeet * 0.3048;
      this.$emit("updateHeight", meters.toFixed(4));
    },
  },
});
</script>

<template>
  <div class="height-weight-container">
    <b-form-row>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="selectedUnit"
          :options="availableUnits"
          :aria-describedby="ariaDescribedby"
          name="radio-inline"
        ></b-form-radio-group>
      </b-form-group>
    </b-form-row>
    <b-form-row no-gutters>
      <b-col sm="7">
        <label>{{ $t("patient.height") }}</label>
      </b-col>
      <b-col sm="5">
        <label>{{ $t("patient.weight") }}</label>
      </b-col>
    </b-form-row>
    <b-form-row>
      <b-col sm="6">
        <b-row v-if="selectedUnit === IMPERIAL" class="height-row" no-gutters>
          <b-col>
            <b-form-select
              :value="feet"
              :placeholder="$t('height.feet')"
              class="form-select"
              tabindex="0"
              :options="feetOptions"
              @change="updateFeet"
              @focus="() => (isFeetFocused = true)"
              @blur="() => (isFeetFocused = false)"
            />
          </b-col>
          <b-col>
            <b-form-select
              :value="inches"
              :placeholder="$t('height.inches')"
              class="form-select"
              tabindex="0"
              :options="inchOptions"
              @change="updateInches"
              @focus="() => (isInchesFocused = true)"
              @blur="() => (isInchesFocused = false)"
            />
          </b-col>
        </b-row>
        <b-row v-else class="height-row" no-gutters>
          <b-col>
            <b-input-group>
              <b-form-input
                type="number"
                class="form-input"
                :value="height"
                step="any"
                :formatter="metersFormatter"
                :placeholder="$t('height.meters')"
                tabindex="0"
                @update="updateHeight"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("height.m") }}</span>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="6">
        <b-row class="weight-row">
          <b-col>
            <b-input-group v-if="selectedUnit === IMPERIAL">
              <b-form-input
                type="number"
                class="form-input"
                :value="pounds"
                :formatter="toFiveDigits"
                :placeholder="$t('weight.pounds')"
                tabindex="0"
                step="any"
                @update="updatePounds"
                @focus="() => (isWeightFocused = true)"
                @blur="() => (isWeightFocused = false)"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("weight.lbs") }}</span>
              </b-input-group-append>
            </b-input-group>
            <b-input-group v-else>
              <b-form-input
                type="number"
                class="form-input"
                step="any"
                :value="weight"
                :formatter="toFiveDigits"
                :placeholder="$t('weight.kg')"
                tabindex="0"
                @update="updateWeight"
              />
              <b-input-group-append>
                <span class="input-group-text">{{ $t("weight.kg") }}</span>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </b-col>
    </b-form-row>
  </div>
</template>

<style scoped lang="scss">
.weight-row {
  padding-right: 15px;
}
.height-row {
  gap: 8px;
}
.form-select {
  padding-left: 0px !important;
}
.form-input {
  padding-left: 0px !important;
}
</style>
