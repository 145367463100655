<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :action-buttons="actionButtons"
      :show-last-viewed="false"
      @view-clinic-list-event="navViewClinicList"
      @dashboard-button-event="navViewPatientAllList"
    >
    </sub-header>

    <div class="container-fluid main-section">
      <b-tabs class="settings" justified>
        <b-tab :title="$t('kitHubPatch.tabKits')">
          <div class="tab-container">
            <device-list-table device-type="kits" :clinics="clinics || []">
            </device-list-table>
          </div>
        </b-tab>
        <b-tab :title="$t('kitHubPatch.tabHubs')">
          <div class="tab-container">
            <device-list-table device-type="hubs" :clinics="clinics || []">
            </device-list-table>
          </div>
        </b-tab>
        <b-tab :title="$t('kitHubPatch.tabPatches')">
          <div class="tab-container">
            <device-list-table device-type="patches" :clinics="clinics || []">
            </device-list-table>
          </div>
        </b-tab>
        <b-tab :title="$t('kitHubPatch.tabPatchboxes')">
          <div class="tab-container">
            <device-list-table
              device-type="patchboxes"
              :clinics="clinics || []"
            >
            </device-list-table>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      // Subheader Configuration
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewClinicListWithBack"),
          id: "view-clinic-list-sub-header-button",
          event: "view-clinic-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("navigation.patientList"),
          id: "dashboard-sub-header-button",
          event: "dashboard-button-event",
        },
      ],
      // Clinics Data
      clinicsResult: false,
      clinics: null,
    };
  },
  created() {
    this.getClinics();
  },
  methods: {
    navViewClinicList: function () {
      this.$router.push({ name: "clinic-list" });
    },
    navViewPatientAllList: function () {
      this.$router.push({ name: "patient-list-all" });
    },
    getClinics: function () {
      this.clinics = null;
      this.clinicsResult = null;

      this.api
        .v1ClinicsGet()
        .then((response) => {
          this.clinics = response.data.clinics;
          this.clinicsResult = true;
        })
        .catch((error) => {
          this.clinicsResult = false;
          // Get the error message and display it
          this.showApiResponseError(error, this.$t("clinic.errorLoadList"));
        });
    },
  },
};
</script>
