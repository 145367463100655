<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormButtonsLayout",
});
</script>

<template>
  <div class="form-buttons-container">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.form-buttons-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;

  padding-top: 40px;
}
</style>
