<template>
  <div>
    <b-modal
      :id="modalId"
      centered
      hide-header
      hide-footer
      @shown="onShown"
      @hidden="onHidden"
    >
      <p class="modal-error-message">{{ errorMessage }}</p>
      <div class="buttons-container">
        <button id="general-error-ok" ref="generalErrorOk" @click="onOk">
          {{ $t("common.buttonOk") }}
        </button>
      </div>
      <span class="modal-debug-info">{{ debugInfo }}</span>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      shown: false,
      queuedMessages: [],
      errorMessage: null,
      debugInfo: null,
    };
  },
  mounted() {
    this.$bus.on("show-general-error", this.queueGeneralError);
  },
  beforeDestroy() {
    this.$bus.off("show-general-error", this.queueGeneralError);
  },
  methods: {
    onOk: function () {
      this.$bvModal.hide(this.modalId);
    },
    queueGeneralError: function (errorMessage, debugInfo) {
      this.queuedMessages.push({
        errorMessage: errorMessage,
        debugInfo: debugInfo,
      });

      this.showNextGeneralError();
    },
    showNextGeneralError: function () {
      if (!this.shown) {
        var item = this.queuedMessages.shift();
        if (item) {
          this.shown = true;

          if (item.errorMessage) {
            this.errorMessage = item.errorMessage;
            this.debugInfo = item.debugInfo;
          } else {
            this.errorMessage = this.$t("common.errorMessageNotSpecified");
            this.debugInfo = "";
          }

          this.$bvModal.show(this.modalId);
        }
      }
    },
    onShown: function () {
      this.$refs.generalErrorOk.focus();
    },
    onHidden: function () {
      this.shown = false;
      this.showNextGeneralError();
    },
  },
};
</script>
