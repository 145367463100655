<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default {
  components: {
    SectionHeader,
  },
  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    modalId: {
      type: String,
      default: "",
    },
  },
  data: function () {
    return {
      newUserGroup: {
        name: null,
      },
    };
  },
  methods: {
    addUserGroup: function () {
      // Create user group
      this.api
        .v1ClinicianGroupPost({
          name: this.newUserGroup.name,
          clinic_id: this.clinicId,
          description: " ",
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit("show-general-success", "User Group Created");
          this.$emit("added");

          // Clear all the clinic user inputs on success.
          this.newUserGroup.name = null;

          if (this.isModal) {
            this.close();
          }
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(error, "User Group Creation Failed");
        });
    },
    show: function () {
      // Anytime this is shown, clear the input fields. This is so they
      // do not hold state over
      this.newUserGroup.name = null;
    },
    close: function () {
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
<template>
  <div>
    <b-modal
      v-if="isModal"
      :id="modalId"
      centered
      hide-header
      hide-footer
      @show="show"
    >
      <section-header header-text="Add New User Group" />
      <div class="setting-section">
        <form @submit.prevent="addUserGroup">
          <div
            id="clinic-group-name-container"
            class="input-container-settings"
          >
            <input
              id="user-group-name"
              v-model="newUserGroup.name"
              type="text"
              placeholder="User Group Name"
              required
              minlength="2"
              maxlength="30"
            />
          </div>
          <div class="buttons-container">
            <button type="submit">
              {{ $t("common.buttonAdd") }}
            </button>
            <button @click.prevent="close">Cancel</button>
          </div>
        </form>
      </div>
    </b-modal>
    <div v-else>
      <section-header header-text="Add New User Group" />
      <div class="setting-section">
        <form @submit.prevent="addUserGroup">
          <div
            id="clinic-group-name-container"
            class="input-container-settings"
          >
            <input
              id="user-group-name"
              v-model="newUserGroup.name"
              type="text"
              placeholder="User Group Name"
              required
              minlength="2"
              maxlength="30"
            />
          </div>
          <div class="buttons-container">
            <button type="submit">
              {{ $t("common.buttonAdd") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style>
</style>
