<script>
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2.js";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
export default {
  components: {
    Multiselect,
    SectionHeader,
  },
  props: {
    clinicUsers: {
      type: Array,
      default: () => [],
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      clinicGroups: null,
      editedGroupId: null,
      userGroupSelect: {
        mode: "tags",
        value: [],
        closeOnSelect: false,
        options: [],
        searchable: true,
        breakTags: true,
      },
      groupNameEditInput: "",
    };
  },
  computed: {
    validateGroupName: function () {
      return this.groupNameEditInput.trim().length > 3;
    },
  },
  watch: {
    clinicUsers: function (newValue) {
      this.updateClinicUsers(newValue);
    },
  },
  mounted: function () {
    this.updateClinicUsers(this.clinicUsers);
    this.getClinicGroups();
  },
  methods: {
    updateClinicUsers: function (newValue) {
      this.userGroupSelect.options = newValue
        .filter((u) => u.active)
        .map((u) => ({
          label: u.firstname + " " + u.lastname,
          value: u.id,
        }));
    },
    getClinicGroups: function (clearOnSuccess) {
      this.clinicGroupsResult = null;
      if (!clearOnSuccess) {
        this.clinicGroups = [];
      }

      this.api
        .v1ClinicClinicIdClinicianGroupsGet(this.getCurrentClinicId())
        .then((response) => {
          this.clinicGroupsResult = true;
          this.clinicGroups = response.data.clinician_groups ?? [];
          this.clinicGroups.sort((a, b) => (a.id > b.id ? -1 : 1));
        })
        .catch((error) => {
          this.clinicGroupsResult = false;

          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadList")
          );
        });
    },
    onEditUserGroup: function (clinicGroup) {
      this.editedGroupId = clinicGroup.id;
      this.userGroupSelect.value =
        clinicGroup?.clinician?.map((c) => c.id) || [];
      this.groupNameEditInput = clinicGroup.name;
    },
    onDeleteUserGroup: function (group) {
      this.api
        .v1ClinicianGroupGroupIdDelete(group.id)
        .then((response) => {
          this.getClinicGroups(true);

          // Show a general success message
          this.$bus.emit("show-general-success", "User Group Deleted");
        })
        .catch((error) => {
          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadList")
          );
        });
    },
    onSaveUserGroup: function (group) {
      if (!this.validateGroupName) {
        return false;
      }
      this.api
        .v1ClinicianGroupGroupIdPut(group.id, {
          name: this.groupNameEditInput,
          clinician_ids: this.userGroupSelect.value,
        })
        .then((response) => {
          this.getClinicGroups(true);
        })
        .catch((error) => {
          // Get the error and show it
          this.showApiResponseError(
            error,
            this.$t("clinicalUser.errorLoadList")
          );
        });
      this.resetUserGroupEdit();
    },
    resetUserGroupEdit: function () {
      this.editedGroupId = null;
      this.userGroupSelect.value = [];
      this.groupNameEditInput = null;
    },
  },
};
</script>
<template>
  <div class="userGroups">
    <section-header v-if="!hideHeader" header-text="User Groups" />
    <div class="setting-section-list">
      <div
        v-if="clinicGroups === null"
        id="clinic-users-loading"
        class="data-loading-container"
      >
        <span>No User Groups Found</span>
      </div>
      <div v-else-if="clinicGroups">
        <div
          v-for="(clinicGroup, index) in clinicGroups"
          :id="'clinicGroup-' + clinicGroup.id"
          :key="index"
          class="settings-list"
        >
          <div
            v-if="editedGroupId == clinicGroup.id"
            class="settings-list-wrapper"
          >
            <div>
              <b-form-input
                v-model="groupNameEditInput"
                class="group-name-edit"
                type="text"
                required
                :state="validateGroupName"
                aria-describedby="group-name-edit-feedback"
              ></b-form-input>
              <b-form-invalid-feedback id="group-name-edit-feedback"
                >Enter at least 3 alphanumeric letters</b-form-invalid-feedback
              >
            </div>
            <Multiselect
              v-model="userGroupSelect.value"
              v-bind="userGroupSelect"
            >
              <template #tag="{ option, disabled, handleTagRemove }">
                <div class="multiselect-tag">
                  {{ option.label }}
                  <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @mousedown.prevent="handleTagRemove(option, $event)"
                  >
                    <span class="multiselect-tag-remove-icon"></span>
                  </span>
                </div>
              </template>
            </Multiselect>
            <button
              type="button"
              class="button-link"
              @click.prevent="onSaveUserGroup(clinicGroup)"
            >
              SAVE
            </button>
            <button
              type="button"
              class="button-link button-negative"
              @click.prevent="resetUserGroupEdit"
            >
              {{ $t("common.buttonExit") }}
            </button>
          </div>
          <div v-else class="settings-list-wrapper">
            <div class="groupName">{{ clinicGroup.name }}</div>
            <div class="groupSize">
              {{ clinicGroup?.clinician?.length || 0 }} Clinicians
            </div>
            <button
              type="button"
              class="button-link"
              :disabled="!!editedGroupId"
              @click.prevent="onEditUserGroup(clinicGroup)"
            >
              EDIT
            </button>
            <button
              type="button"
              class="button-link"
              :disabled="!!editedGroupId"
              @click.prevent="onDeleteUserGroup(clinicGroup)"
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <span>No User Groups Found</span>
      </div>
    </div>
  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
.settings-list {
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: space-between;
  word-break: break-word;
  width: 100%;

  .settings-list-wrapper {
    display: flex;
    flex: 0 1 auto;
    align-items: center;
    justify-content: space-between;
    word-break: break-word;
    width: 100%;

    @media (max-width: 500px) {
      flex-direction: column;
      border-bottom: 1px;
    }
  }

  > * {
    margin-top: 16px;

    &:not(:first-child) {
      margin-left: 8px;
    }
  }

  .clinician-name-label {
    max-width: 200px;
  }

  span {
    display: block;
  }
}
</style>
<style lang="scss">
.userGroups {
  flex-grow: 1;

  .multiselect {
    margin: 20px 0;
    width: inherit;
    max-width: 50%;
  }

  .groupName {
    font-weight: bold;
    flex-grow: 1;
  }

  input {
    border: 0px;
    position: relative;
    padding: 0px;
  }

  .group-name-edit {
    margin: 5px 10px;
    border: 1px solid #9999;
    border-radius: 2px;
    padding: 5px 3px;
  }

  button {
    min-width: 50px;
  }
}
</style>
