<template>
  <div>
    <b-modal :id="modalId" centered hide-footer hide-header @show="resetModal">
      <section-header :header-text="$t('simulatorV2.title')" />
      <form @submit.prevent="submit">
        <b-form-select
          id="scenario-select"
          v-model="chosenScenario"
          :options="formattedScenarios"
        >
        </b-form-select>
        <b-form-select
          id="apm-select"
          v-model="chosenAPM"
          :options="formattedAcuteModes"
        >
        </b-form-select>
        <button
          :disabled="isSubmitDisabled"
          class="submit-button"
          type="submit"
        >
          {{ $t("simulatorV2.save") }}
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { getScenarios } from "@/shared/data-simulator-v2-api.js";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default defineComponent({
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
    simulatorPatient: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      shown: false,
      scenarios: [],
      acutePatientModes: [],
      chosenScenario: null,
      chosenAPM: null,
    };
  },
  computed: {
    formattedScenarios() {
      const formattedScenarios = [
        {
          value: null,
          text: this.$t("simulatorV2.scenarios.select"),
        },
      ];
      return [
        ...formattedScenarios,
        ...this.scenarios.map((scenario) => ({
          value: scenario.id,
          text: this.$t(scenario.translation_string),
        })),
      ];
    },
    isSubmitDisabled() {
      return !this.chosenScenario || !this.chosenAPM;
    },
    formattedAcuteModes() {
      const formattedAcuteModes = [
        {
          value: null,
          text: this.$t("simulatorV2.apm.select"),
        },
      ];
      return [
        ...formattedAcuteModes,
        ...this.acutePatientModes.map((apm) => ({
          value: apm.id,
          text: this.$t(`simulatorV2.apm.${apm.translation_string}`),
        })),
      ];
    },
  },
  mounted() {
    this.getScenarios();
  },
  methods: {
    getScenarios() {
      if (this.isLowerEnv) {
        getScenarios().then((response) => {
          this.scenarios = response.data.scenarios;
          this.acutePatientModes = response.data.acute_patient_modes;
        });
      }
    },

    resetModal() {
      if (this.simulatorPatient) {
        this.chosenScenario = this.simulatorPatient.scenario_id;
        this.chosenAPM = this.simulatorPatient.acute_patient_mode_id;
      } else {
        this.chosenScenario = null;
        this.chosenAPM = null;
      }
    },

    submit() {
      console.log("submit", this.chosenScenario, this.chosenAPM);
      this.$emit("create-or-update-simulator-v2", {
        scenario_id: this.chosenScenario,
        acute_patient_mode_id: this.chosenAPM,
      });
      this.$bvModal.hide(this.modalId);
    },
  },
});
</script>

<style scoped lang="scss">
.submit-button {
  margin-top: 20px;
}
#scenario-select {
  margin-top: 10px;
}
</style>
