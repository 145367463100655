<template>
  <div>
    <b-modal :id="modalId" centered hide-footer hide-header @show="resetModal">
      <section-header :header-text="$t('notificationsModal.title')" />
      <form @submit.prevent="submit">
        <b-form-select id="msg-select" v-model="chosenMsg" :options="msgkinds">
        </b-form-select>
        <b-form-select
          id="route-select"
          v-model="chosenRoute"
          :options="routes"
        >
        </b-form-select>
        <b-form-checkbox
          id="clinician-only"
          v-model="clinicianOnly"
          name="clinician-only"
        >
          {{ $t("notificationsModal.clinicanonly") }}
        </b-form-checkbox>
        <button
          :disabled="isSubmitDisabled"
          class="submit-button"
          type="submit"
        >
          {{ $t("notificationsModal.send") }}
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default defineComponent({
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      shown: false,
      clinicianOnly: false,
      chosenRoute: null,
      routes: [
        {
          value: null,
          text: this.$t("notificationsModal.selectRoute"),
        },
        {
          value: "email",
          text: this.$t("notificationsModal.email"),
        },
        {
          value: "sms",
          text: this.$t("notificationsModal.sms"),
        },
      ],
      chosenMsg: null,
      msgkinds: [
        {
          value: null,
          text: this.$t("notificationsModal.selectMsgKind"),
        },
        {
          value: "patchstale",
          text: this.$t("notificationsModal.patchstale"),
        },
        {
          value: "patchfirstdata",
          text: this.$t("notificationsModal.patchfirstdata"),
        },
        {
          value: "patchnodata",
          text: this.$t("notificationsModal.patchnodata"),
        },
        {
          value: "monitoringend",
          text: this.$t("notificationsModal.monitoringend"),
        },
        {
          value: "highhct",
          text: this.$t("notificationsModal.highhct"),
        },
        {
          value: "lowhct",
          text: this.$t("notificationsModal.lowhct"),
        },
        {
          value: "highhgb",
          text: this.$t("notificationsModal.highhgb"),
        },
        {
          value: "lowhgb",
          text: this.$t("notificationsModal.lowhgb"),
        },
        {
          value: "dyskalemia",
          text: this.$t("notificationsModal.dyskalemia"),
        },
        {
          value: "highpotassium",
          text: this.$t("notificationsModal.highpotassium"),
        },
        {
          value: "lowpotassium",
          text: this.$t("notificationsModal.lowpotassium"),
        },
      ],
    };
  },

  computed: {
    isSubmitDisabled() {
      return !this.chosenMsg || !this.chosenRoute;
    },
  },

  methods: {
    resetModal() {
      this.chosenMsg = null;
      this.chosenRoute = null;
      this.clinicianOnly = false;
    },
    submit() {
      this.$emit("send-notification", {
        msgkind: this.chosenMsg,
        clinicianonly: this.clinicianOnly,
        acceptableroutes: [this.chosenRoute],
      });
      this.$bvModal.hide(this.modalId);
    },
  },
});
</script>

<style scoped lang="scss">
.submit-button {
  margin-top: 20px;
}
#scenario-select {
  margin-top: 10px;
}
</style>
