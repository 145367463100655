<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormLayout",
});
</script>

<template>
  <div class="form-container">
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.form-container {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
