<template>
  <b-modal :id="modalId" hide-header hide-footer @show="onShow">
    <section-header :header-text="$t('clinicalActionModal.title')" />
    <b-tabs class="settings" justified>
      <b-tab :title="$t('clinicalActionModal.tabAdd')">
        <b-form @submit.prevent="submit">
          <p v-if="!patient?.intl_timezone">
            <strong>
              <i>{{ $t("clinicalActionModal.tzRequired") }}</i>
            </strong>
          </p>
          <b-form-group v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="action-choice"
              v-model="inputFields.action_choice"
              :disabled="!patient?.intl_timezone"
              stacked
              :aria-describedby="ariaDescribedby"
              name="radio-options"
              class="tw-text-left"
            >
              <b-form-radio value="dialysis">
                <SVGIconWrapper>
                  <img :src="DialysisIcon" alt="Dialysis Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.dialysis") }}
                </span>
              </b-form-radio>
              <b-form-radio value="lab_draw">
                <SVGIconWrapper>
                  <img :src="LabDrawIcon" alt="Lab Draw Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.lab_draw") }}
                </span>
              </b-form-radio>
              <b-form-radio value="esa_dose">
                <SVGIconWrapper>
                  <img :src="RXIcon" alt="RX Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.esa_dose") }}
                </span>
              </b-form-radio>
              <b-form-radio value="iv_iron_dose">
                <SVGIconWrapper>
                  <img :src="RXIcon" alt="RX Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.iv_iron_dose") }}
                </span>
              </b-form-radio>
              <b-form-radio value="vascular_access_intervention">
                <SVGIconWrapper>
                  <img :src="VascularIcon" alt="Vascular Access Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.vascular_access_intervention") }}
                </span>
              </b-form-radio>
              <b-form-radio value="transfusion">
                <SVGIconWrapper>
                  <img :src="TransfusionIcon" alt="Transfusion Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.transfusion") }}
                </span>
              </b-form-radio>
              <b-form-radio value="emergency_room">
                <SVGIconWrapper>
                  <img :src="ERIcon" alt="ER Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.emergency_room") }}
                </span>
              </b-form-radio>
              <b-form-radio value="hospitalization_admission">
                <SVGIconWrapper>
                  <img :src="InHospitalIcon" alt="In Hospital Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.hospitalization_admission") }}
                </span>
              </b-form-radio>
              <b-form-radio value="hospitalization_discharge">
                <SVGIconWrapper>
                  <img :src="OutHospitalIcon" alt="Out Hospital Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.hospitalization_discharge") }}
                </span>
              </b-form-radio>
              <b-form-radio value="other">
                <SVGIconWrapper>
                  <img :src="OtherIcon" alt="Other Icon" />
                </SVGIconWrapper>
                <span class="tw-pl-2">
                  {{ $t("clinicalActionModal.other") }}
                </span>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
          <b-form-group
            :label="$t('clinicalActionModal.notesLabel')"
            label-align="left"
          >
            <b-form-textarea
              id="action-notes"
              v-model="inputFields.notes"
              :disabled="!patient?.intl_timezone"
              :placeholder="$t('clinicalActionModal.notePlaceholder')"
              rows="3"
              max-rows="6"
            />
          </b-form-group>
          <b-form-group
            :label="$t('clinicalActionModal.placeholderDate')"
            label-align="left"
          >
            <date-picker
              :date="inputFields.action_taken_date"
              :disabled="!patient?.intl_timezone"
              @date-change="
                (date) => {
                  inputFields.action_taken_date = date;
                }
              "
            />
          </b-form-group>
          <b-form-group
            :label="`${$t('clinicalActionModal.timeLabel')} (${
              patient?.intl_timezone || 'Edit patient to select timezone'
            })`"
            label-align="left"
            label-for="action-timepicker"
            class="tw-justify-items-start"
          >
            <b-form-timepicker
              id="action-timepicker"
              :disabled="!patient?.intl_timezone"
              :value="inputFields.time_of_day"
              @input="changeTOD"
            />
          </b-form-group>
          <button :disabled="!isSubmitEnabled" type="submit">
            {{ $t("clinicalActionModal.submit") }}
          </button>
        </b-form>
      </b-tab>
    </b-tabs>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { DateTime } from "luxon";
import SVGIconWrapper from "@/shared/components/Primitives/SVGIconWrapper.vue";
import DialysisIcon from "@/shared/public/dialysis-icon-kidneys.svg";
import TransfusionIcon from "@/shared/public/transfusion-icon.svg";
import OtherIcon from "@/shared/public/other-icon.svg";
import RXIcon from "@/shared/public/rx-icon.svg";
import OutHospitalIcon from "@/shared/public/out-hospital-icon.svg";
import InHospitalIcon from "@/shared/public/in-hospital-icon.svg";
import ERIcon from "@/shared/public/er-icon.svg";
import LabDrawIcon from "@/shared/public/blood-draw-icon.svg";
import VascularIcon from "@/shared/public/vascular-access-icon.svg";

export default {
  name: "ClinicalActionModal",
  components: {
    SVGIconWrapper,
    SectionHeader,
  },
  props: {
    patient: {
      type: Object,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate({ $scope: false }),
    };
  },
  data: function () {
    return {
      // icons
      DialysisIcon,
      TransfusionIcon,
      OtherIcon,
      RXIcon,
      OutHospitalIcon,
      InHospitalIcon,
      ERIcon,
      LabDrawIcon,
      VascularIcon,
      currentPage: 1,
      perPage: 10,
      total_rows: 0,
      clinicalActionChoices: [
        { text: this.$t("clinicalActionModal.dialysis"), value: "dialysis" },
        { text: this.$t("clinicalActionModal.lab_draw"), value: "lab_draw" },
        { text: this.$t("clinicalActionModal.esa_dose"), value: "esa_dose" },
        {
          text: this.$t("clinicalActionModal.iv_iron_dose"),
          value: "iv_iron_dose",
        },
        {
          text: this.$t("clinicalActionModal.vascular_access_intervention"),
          value: "vascular_access_intervention",
        },
        {
          text: this.$t("clinicalActionModal.transfusion"),
          value: "transfusion",
        },
        {
          text: this.$t("clinicalActionModal.emergency_room"),
          value: "emergency_room",
        },
        {
          text: this.$t("clinicalActionModal.hospitalization_admission"),
          value: "hospitalization_admission",
        },
        {
          text: this.$t("clinicalActionModal.hospitalization_discharge"),
          value: "hospitalization_discharge",
        },
        { text: this.$t("clinicalActionModal.other"), value: "other" },
      ],
      options: [
        { text: this.$t("clinicalActionModal.yes"), value: true },
        { text: this.$t("clinicalActionModal.no"), value: false },
      ],
      inputFields: {
        notes: null,
        action_choice: null,
        action_taken_date: new Date().toISOString().split("T")[0],
        time_of_day: "09:00:00",
      },
    };
  },
  validations() {
    return {
      inputFields: {
        action_choice: {
          required,
        },
        action_taken_date: {
          required,
        },
        time_of_day: {
          required,
        },
      },
      action_ts: {
        required,
      },
    };
  },
  computed: {
    action_ts() {
      if (this.patient?.intl_timezone && this.inputFields.action_taken_date) {
        const date = DateTime.fromISO(this.inputFields.action_taken_date, {
          zone: this.patient.intl_timezone,
        });
        const [hour, minute, second] = this.inputFields.time_of_day
          .split(":")
          .map(Number);
        return date.set({ hour, minute, second }).toUTC().toISO();
      } else {
        return null;
      }
    },
    isSubmitEnabled() {
      // if timezone is not set, we can't submit
      if (!this.patient?.intl_timezone) return false;
      return !this.v$.$invalid;
    },
  },
  methods: {
    changeTOD(time) {
      this.inputFields.time_of_day = time;
    },
    onShow: function () {
      this.inputFields.notes = null;
      this.inputFields.action_choice = null;
    },
    submit() {
      this.$emit("add", {
        notes: this.inputFields.notes,
        action_choice: this.inputFields.action_choice,
        action_ts: this.action_ts,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  text-align: start;
}
</style>
