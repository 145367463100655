<template>
  <b-modal :id="modalId" centered hide-header hide-footer>
    <section-header :header-text="$t('timeoutModal.title')" />
    <div class="buttons-container">
      <button class="button-negative" @click="logout">
        {{ $t("timeoutModal.logout") }}
      </button>
      <button @click="stayConnected">
        {{ $t("timeoutModal.stayConnected") }}
      </button>
    </div>
  </b-modal>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppMixin from "@/shared/mixins/AppMixin";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";

export default defineComponent({
  components: {
    SectionHeader,
  },
  mixins: [AppMixin],
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },

  methods: {
    stayConnected() {
      this.api.v1SysteminfoGet().then(() => {
        this.$bvModal.hide(this.modalId);
      });
    },

    logout() {
      this.$bvModal.hide(this.modalId);
      this.navToLogin(null);
    },
  },
});
</script>
