<template>
  <b-table
    id="patch-locations-table"
    class="basic-table"
    no-sort-reset
    :fields="tableFieldsProcessed"
    :items="patchLocationsProcessed"
    :sort-by="sortKey"
  >
    <template #cell(manage)="data">
      <span v-if="data.item.active">
        <button
          type="button"
          class="button-link button-remove"
          @click.prevent="deactivate(data.item)"
        >
          X
        </button>
      </span>
      <span v-else-if="!data.item.active">
        <button
          type="button"
          class="button-link"
          @click.prevent="activate(data.item)"
        >
          &#10003;
        </button>
      </span>
    </template>
  </b-table>
</template>
<script>
export default {
  props: {
    clinic: {
      type: Object,
      required: true,
    },
    showInactive: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    sortKey: {
      type: String,
      default: "locationname",
    },
    showRowState: {
      type: Boolean,
      default: true,
    },
    maxRows: {
      type: Number,
      default: null,
    },
  },
  data: function () {
    return {
      tableFields: [
        {
          key: "locationname",
          label: this.$t("patchLocation.tableHeaderName"),
          formatter: this.nameFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
          thClass: "column-stretch-70",
        },
        {
          key: "location_type",
          label: this.$t("patchLocation.algorithm"),
          formatter: this.algorithmFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "active",
          label: this.$t("patchLocation.tableHeaderActive"),
          formatter: this.activeFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        { key: "manage", label: this.$t("patchLocation.tableHeaderManage") },
      ],
      clinicUsersLoadResult: null,
      clinicUsers: null,
    };
  },
  computed: {
    tableFieldsProcessed: function () {
      if (!this.showInactive) {
        return this.tableFields.filter(function (el) {
          return el.key !== "active";
        });
      } else {
        return this.tableFields;
      }
    },
    patchLocationsProcessed: function () {
      if (!this.clinic || !this.clinic.patchlocations) {
        return [];
      }

      var patchLocationsProcessed = this.clinic.patchlocations;

      if (!this.showInactive) {
        patchLocationsProcessed = patchLocationsProcessed.filter(function (el) {
          return el.active;
        });
      }

      if (this.maxRows !== null) {
        // Sort the rows first, based on the default sort key
        patchLocationsProcessed = patchLocationsProcessed.sort((a, b) => {
          return a[this.sortKey] - b[this.sortKey];
        });

        patchLocationsProcessed = patchLocationsProcessed.slice(
          0,
          this.maxRows
        );
      }

      if (!this.showRowState) {
        return patchLocationsProcessed;
      }

      return patchLocationsProcessed.map((item) => {
        item._rowVariant = this.getRowVariants(item);
        return item;
      });
    },
  },
  methods: {
    nameFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        return value;
      }

      return "-";
    },
    algorithmFormatter(value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        if (item[key] === "access") {
          return this.$t("patchLocation.algoAccess");
        } else if (item[key] === "calf") {
          return this.$t("patchLocation.algoCalf");
        }
      }

      return "-";
    },
    activeFormatter: function (value, key, item) {
      if (this.fieldExists(item, key) && item[key] !== null) {
        return value ? this.$t("common.yes") : this.$t("common.no");
      }

      return "-";
    },
    getRowVariants: function (item) {
      if (!item.active) {
        return "row-inactive";
      }

      return "";
    },
    deactivate: function (item) {
      const eventId = "patch-location-" + (item ? item.id : "");
      this.showConfirmationPrompt(
        this.$t("patchLocation.promptDeactivate"),
        eventId,
        (state) => {
          if (state) {
            // Make the patch location inactive
            this.updateActive(item, false);
          }
        }
      );
    },
    activate: function (item) {
      this.updateActive(item, true);
    },
    updateActive: function (item, state) {
      // Copy the array using slice, and remove the specified index from the new array
      var newPatchLocationsList = this.clinic.patchlocations.slice();

      // Copy the item so not to change the one in the list
      var newItem = Object.assign({}, item);
      newItem["active"] = state;

      // Update the item in the array
      for (var index = 0; index < newPatchLocationsList.length; index++) {
        if (newPatchLocationsList[index].id === newItem.id) {
          newPatchLocationsList[index] = newItem;
          break;
        }
      }

      // Send the update
      this.updatePatchPlacementList(newPatchLocationsList);
    },
    updatePatchPlacementList(patchLocationsList) {
      this.api
        .v1ClinicClinicidPut(this.clinic.id, {
          updateclinic: {
            patchlocations: patchLocationsList,
          },
        })
        .then(() => {
          // Show a general success message
          this.$bus.emit(
            "show-general-success",
            this.$t("patchLocation.successUpdate")
          );

          // On success send out a event to indicate that a patch location has changed
          this.$emit("refresh-list");
        })
        .catch((error) => {
          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("patchLocation.errorUpdate")
          );
        });
    },
  },
};
</script>
