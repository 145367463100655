<script lang="ts">
import { defineComponent } from "vue";
import PatientForm from "@/shared/components/PatientForm.vue";
import FormLayout from "@/shared/layouts/FormLayout.vue";

export default defineComponent({
  name: "PatientAdd",
  components: { FormLayout, PatientForm },
  methods: {
    cancel() {
      this.$router.push({
        name: "patient-list",
        params: { clinicId: this.$route.params.clinicId },
      });
    },
    showToast(formPatient) {
      if (formPatient?.addresses?.length) {
        this.$root.$bvToast.toast(this.$t("patient.clickAddNew"), {
          to: `/clinics/${this.$route.params.clinicId}/patients/add`,
          title: this.$t("patient.addedSuccessfully"),
          toaster: "b-toaster-bottom-right",
        });
      } else {
        this.$root.$bvToast.toast(this.$t("patient.addressReminder"), {
          title: this.$t("patient.addedSuccessfully"),
          toaster: "b-toaster-bottom-right",
        });
      }
    },
    addPatient: function ({ formPatient }) {
      this.api
        .v1PatientPost({
          createpatient: formPatient,
        })
        .then((response) => {
          this.showToast(formPatient);
          this.$router.push({
            name: "patient-detail",
            params: {
              clinicId: this.$route.params.clinicId,
              patientId: response.data.id,
            },
          });
        })
        .catch((error) => {
          // Get and show the error
          this.showApiResponseError(error, this.$t("patient.errorAdd"));
        });
    },
  },
});
</script>

<template>
  <FormLayout>
    <PatientForm
      :clinic-id="parseInt($route.params.clinicId)"
      @submit="addPatient"
      @cancel="cancel"
    />
  </FormLayout>
</template>

<style scoped lang="scss">
</style>
