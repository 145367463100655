<template>
  <div ref="range-date-picker">
    <DateInput v-model="formattedDate" @click="showPicker = !showPicker" />
    <div v-if="showPicker" class="date-picker">
      <div class="left-options">
        <div>
          <button class="button-link" @click="setToday">Today</button>
        </div>
        <div>
          <button class="button-link" @click="setLastWeek">Past Week</button>
        </div>
        <div>
          <button class="button-link" @click="setLastMonth">Past Month</button>
        </div>
      </div>
      <div>
        <div class="month">
          <h4>{{ monthNames[viewMonth] }} {{ viewYear }}</h4>
          <div class="next-month-buttons">
            <b-button
              variant="link"
              :class="monthButtonClassBackward"
              @click="goBackMonth"
            >
              <b-icon-caret-left-fill />
            </b-button>
            <b-button
              variant="link"
              :class="monthButtonClassForward"
              @click="goForwardMonth"
            >
              <b-icon-caret-right-fill />
            </b-button>
          </div>
        </div>
        <div class="calendar-body">
          <div class="weekdays">
            <div v-for="day in weekdays" :key="day" class="weekday">
              {{ day }}
            </div>
          </div>
          <div v-for="week in weeksInMonth" :key="week.toString()" class="week">
            <div
              v-for="(day, index) in week"
              :key="index"
              :class="dayClass(day)"
              class="day"
              @click="selectDay(day)"
            >
              {{ day || "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BIconCaretLeftFill, BIconCaretRightFill } from "bootstrap-vue";
import DateInput from "@/shared/components/Primitives/DateInput.vue";

export default {
  components: {
    DateInput,
    BIconCaretLeftFill,
    BIconCaretRightFill,
  },
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
      validator: (dates) => dates.every((date) => date instanceof Date),
    },
    monitoringStart: {
      type: Date,
      default: null,
    },
    monitoringEnd: {
      type: Date,
      default: null,
    },
  },
  data() {
    return {
      localDates: this.value,
      showPicker: false,
      viewMonth: this.value[1].getMonth(),
      viewYear: this.value[1].getFullYear(),
      weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      monthNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  computed: {
    hasMonthForward() {
      let nextMonth = new Date(this.viewYear, this.viewMonth);
      nextMonth.setMonth(nextMonth.getMonth() + 1);
      return this.isDateInMonitoringPeriod(nextMonth);
    },
    monthButtonClassForward() {
      let monthClass = "button-link";
      if (!this.hasMonthForward) {
        monthClass += " disabled-arrow";
      }
      return monthClass;
    },
    hasMonthBackward() {
      let pastMonth = new Date(this.viewYear, this.viewMonth, 0);
      return this.isDateInMonitoringPeriod(pastMonth);
    },
    monthButtonClassBackward() {
      let monthClass = "button-link";
      if (!this.hasMonthBackward) {
        monthClass += " disabled-arrow";
      }
      return monthClass;
    },
    weeksInMonth() {
      const weeks = [];
      const firstDay = new Date(this.viewYear, this.viewMonth, 1).getDay();
      const totalDays = new Date(
        this.viewYear,
        this.viewMonth + 1,
        0
      ).getDate();

      let day = 1;
      for (let i = 0; i < 6; i++) {
        weeks[i] = [];
        for (let j = 0; j < 7; j++) {
          if ((i === 0 && j < firstDay) || day > totalDays) {
            weeks[i][j] = 0;
          } else {
            weeks[i][j] = day++;
          }
        }
      }

      return weeks;
    },
    formattedDate() {
      if (this.localDates.length === 1) {
        return this.$d(this.localDates[0], "date");
      }
      if (
        this.localDates[0].getFullYear() === this.localDates[1].getFullYear() &&
        this.localDates[0].getMonth() === this.localDates[1].getMonth() &&
        this.localDates[0].getDate() === this.localDates[1].getDate()
      ) {
        return this.$d(this.localDates[0], "date");
      }
      // Sort the dates in ascending order
      const [startDate, endDate] = [...this.localDates].sort((a, b) => a - b);
      return `${this.$d(startDate, "date")} - ${this.$d(endDate, "date")}`;
    },
  },
  watch: {
    value(newVal) {
      this.localDates = newVal;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    this.$bus.on("open-range-date-picker", () => {
      console.log("open-range-date-picker");
      this.showPicker = true;
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    this.$bus.off("open-range-date-picker");
  },
  methods: {
    handleClickOutside(event) {
      if (
        this.showPicker &&
        this.$refs["range-date-picker"] &&
        !this.$refs["range-date-picker"].contains(event.target)
      ) {
        this.showPicker = false;
      }
    },
    goBackMonth() {
      if (!this.hasMonthBackward) return;
      if (this.viewMonth === 0) {
        this.viewMonth = 11;
        this.viewYear--;
      } else {
        this.viewMonth--;
      }
    },
    goForwardMonth() {
      if (!this.hasMonthForward) return;
      if (this.viewMonth === 11) {
        this.viewMonth = 0;
        this.viewYear++;
      } else {
        this.viewMonth++;
      }
    },
    hideShow() {
      this.showPicker = false;
    },
    selectDay(day) {
      let date = new Date(this.viewYear, this.viewMonth, day);
      if (!this.isDateInMonitoringPeriod(date)) return;
      if (this.localDates.length === 2) {
        this.localDates = [date];
        return;
      }
      if (date.getTime() === this.localDates[0].getTime()) {
        // Set the time to the end of the day when the same date is selected again
        date.setHours(23, 59, 59, 999);
        this.hideShow();
        return this.$emit("input", [this.localDates[0], date]);
      }
      if (date > this.localDates[0]) {
        // Set the time to the end of the day for the second date in the range
        date.setHours(23, 59, 59, 999);
        const startDate = this.localDates[0];
        startDate.setHours(0, 0, 0, 0);
        this.hideShow();
        this.$emit("input", [startDate, date]);
      } else {
        // Set the time to the start of the day for the first date in the range
        date.setHours(0, 0, 0, 0);
        this.hideShow();
        const endDate = this.localDates[0];
        endDate.setHours(23, 59, 59, 999);
        this.$emit("input", [date, endDate]);
      }
    },
    dayClass(day) {
      if (day === 0) return "empty";
      if (
        !this.isDateInMonitoringPeriod(
          new Date(this.viewYear, this.viewMonth, day)
        ) ||
        !this.isDateWithin31Days(new Date(this.viewYear, this.viewMonth, day))
      )
        return "unavailable-day";
      if (this.isSelectedDay(day)) return "selected";
      if (this.isDateInRange(new Date(this.viewYear, this.viewMonth, day))) {
        return "in-range-day";
      }
    },
    isDateWithin31Days(date) {
      if (this.localDates.length === 2) return true;
      // localDates[0] should be within 31 days of this date
      return (
        Math.abs(
          Math.floor(
            (date.getTime() - this.localDates[0].getTime()) /
              (1000 * 60 * 60 * 24)
          )
        ) <= 31
      );
    },
    isDateInMonitoringPeriod(date) {
      const start = new Date(this.monitoringStart);
      start.setHours(0, 0, 0, 0); // set start of the day

      const end = new Date(this.monitoringEnd);
      end.setHours(23, 59, 59, 999); // set end of the day

      return date >= start && date <= end;
    },
    isDateInRange(date) {
      // Sort the dates in ascending order
      const [startDate, endDate] = [...this.localDates].sort((a, b) => a - b);

      // Check if the date is within the range
      return date >= startDate && date <= endDate;
    },
    isSelectedDay(day) {
      return this.localDates.some(
        (date) =>
          day === date.getDate() &&
          this.viewMonth === date.getMonth() &&
          this.viewYear === date.getFullYear()
      );
    },
    rangeSelectedHandler() {
      this.showPicker = false;
    },
    setToday() {
      const beginningOfDay = new Date();
      // make new variable for start of today
      beginningOfDay.setHours(0, 0, 0, 0);
      this.$emit("input", [beginningOfDay, new Date()]);
      this.rangeSelectedHandler();
    },
    setLastWeek() {
      let lastWeekStart = new Date();
      lastWeekStart.setDate(lastWeekStart.getDate() - 7);
      if (this.isDateInMonitoringPeriod(lastWeekStart)) {
        this.$emit("input", [lastWeekStart, new Date()]);
        this.rangeSelectedHandler();
      } else {
        this.$emit("input", [this.monitoringStart, this.monitoringEnd]);
        this.rangeSelectedHandler();
      }
    },
    setLastMonth() {
      const today = new Date();
      const thirtyOneDaysAgo = new Date();
      thirtyOneDaysAgo.setDate(today.getDate() - 31);
      if (this.isDateInMonitoringPeriod(thirtyOneDaysAgo)) {
        this.$emit("input", [thirtyOneDaysAgo, today]);
        this.rangeSelectedHandler();
      } else {
        this.$emit("input", [this.monitoringStart, this.monitoringEnd]);
        this.rangeSelectedHandler();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.left-options {
  padding-left: 8px;
  padding-top: 8px;
}
.date-picker {
  z-index: 9999;
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  border-radius: 4px;
}
.month {
  padding: 8px;
}

.week {
  padding-top: 8px;
}

.month,
.weekdays,
.week {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
.day {
  width: 14%;
  text-align: center;
  cursor: pointer; /* Changes the cursor to a hand when hovering over a day */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}
.day:hover {
  background-color: $purple-light; /* Change the background color when a day is hovered over */
}
.disabled-arrow {
  cursor: not-allowed !important;
  color: $gray-light !important;
}
.unavailable-day {
  cursor: default;
  border-radius: 4px;
  background-color: $gray-light; /* Change the background color for days that are not in the monitoring period */
  color: $gray-mid;
}
.unavailable-day:hover {
  background-color: $gray-light; /* Change the background color when a day that is not in the monitoring period is hovered over */
  cursor: not-allowed;
}
.in-range-day {
  background-color: darken($purple-lighter, 10%);
  border-radius: 4px;
}
.selected {
  background-color: $purple-light;
  border-radius: 4px;
}
.empty {
  visibility: hidden;
}
h4 {
  margin: 0;
}
.calendar-body {
  padding: 16px;
}
.next-month-buttons {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}
</style>
