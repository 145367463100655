<template>
  <div>
    <b-table
      id="patient-all-table"
      class="basic-table"
      :class="{ 'clickable-table': rowClickSupported }"
      no-sort-reset
      no-local-sorting
      :fields="filteredTableFields"
      :items="patientsFiltered"
      sort-by="status"
      :sort-desc="false"
      :tbody-tr-attr="rowAttributes"
      @sort-changed="onSort"
      @row-clicked="onRowClick"
    >
      <template #cell(status)="data">
        <span v-html="statusHtmlFormatter(data.item)"></span>
      </template>
    </b-table>
    <div v-if="message" class="data-loading-container">
      <span class="table-message">{{ message }}</span>
    </div>
  </div>
</template>
<script>
// WARNING: This is not a drop in replacement solution and
// it might not work for some edge cases. Test your code!
const debounce = (func, delay, { leading } = {}) => {
  let timerId;

  return (...args) => {
    if (!timerId && leading) {
      func(...args);
    }
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export const LOAD_TYPE_INITIAL = 1;
export const LOAD_TYPE_MORE = 2;
export const LOAD_TYPE_SORT = 3;
export const LOAD_TYPE_RELOAD = 4;

export default {
  props: {
    searchText: {
      type: String,
      default: null,
    },
    hideCompleted: {
      type: Boolean,
      default: true,
    },
    clinics: {
      type: Array,
      default: null,
    },
    singlePatient: {
      // Specified if only single patient is to be viewed
      type: Object,
      default: null,
    },
    fieldsAllowedFilter: {
      type: Array,
      default: null,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
    showRowState: {
      type: Boolean,
      default: true,
    },
    rowClickSupported: {
      type: Boolean,
      default: true,
    },
    reloadPatients: {
      // Changing this number by the parent will cause the patients to reload here
      type: Number,
      default: 0,
    },
  },
  data: function () {
    return {
      tableFields: [
        {
          key: "clinicpatientidentifier",
          label: this.$t("patient.tableHeaderPatientId"),
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "ordering_clinician",
          label: this.$t("patient.tableHeaderPhysician"),
          formatter: this.clinicianNameFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "name",
          label: this.$t("patient.tableHeaderPatient"),
          formatter: this.patientNameFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "clinic",
          label: this.$t("patient.tableHeaderClinic"),
          formatter: this.clinicNameFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
        },
        {
          key: "dob",
          label: this.$t("patient.tableHeaderDateOfBirth"),
          formatter: this.dobFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "monitoring",
          label: this.$t("patient.tableHeaderMonitoring"),
          formatter: this.monitoringFormatter,
          sortDirection: "desc",
          sortable: this.sortable,
        },
        {
          key: "patchplacement",
          label: this.$t("patient.tableHeaderPlacement"),
          formatter: this.patchPlacementFormatter,
          sortDirection: "desc",
          sortable: this.sortable,
          tdClass: "column-max-125px",
        },
        {
          key: "lastdataseen",
          label: this.$t("patient.tableHeaderLastData"),
          formatter: this.lastDataFormatter,
          sortDirection: "asc",
          sortable: this.sortable,
          tdClass: "no-wrap",
        },
        {
          key: "notifiable_events",
          label: this.$t("patient.tableHeaderNumberNotifiableEvents"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("patient.tableHeaderNotifications"),
          sortDirection: "asc",
          sortable: this.sortable,
        },
      ],
      message: null,
      loading: null,
      fullyLoaded: false,
      rowLoadCount: 0,
      lastScollHeightLoad: null,
      currentSortField: "status",
      currentSortDesc: false,
      // Patients
      patients: null,
      maxPatientId: null,
    };
  },
  computed: {
    filteredTableFields: function () {
      var filteredFieldKeys = [];
      var optionalFieldKeys = [];

      // Find the optional fields
      this.tableFields.forEach((field) => {
        if (
          this.fieldsAllowedFilter === null ||
          this.fieldsAllowedFilter.includes(field.key)
        ) {
          if (field.optional) {
            optionalFieldKeys.push(field.key);
          } else {
            filteredFieldKeys.push(field.key);
          }
        }
      });

      // Loop through the patients looking for data for the
      // optional fields to see if we should use them
      if (optionalFieldKeys.length > 0) {
        if (this.patients) {
          for (var i = 0; i < this.patients.length; i++) {
            var patient = this.patients[i];
            var fieldKeysAdded = [];
            optionalFieldKeys.forEach((key) => {
              if (
                key in patient &&
                patient[key] !== null &&
                patient[key] !== ""
              ) {
                filteredFieldKeys.push(key);
                fieldKeysAdded.push(key);
              }
            });

            // Remove any fields that have been added from the optionalTableFields
            // and if that list become empty, stop the loop
            if (fieldKeysAdded.length > 0) {
              fieldKeysAdded.forEach((key) => {
                optionalFieldKeys = optionalFieldKeys.filter(function (
                  optionalFieldKey
                ) {
                  if (optionalFieldKey === key) {
                    return false;
                  }

                  return true;
                });
              });
            }

            if (optionalFieldKeys.length === 0) {
              break;
            }
          }
        }
      }

      // Go through table fields and filter out any fields that should
      // not be shown. Returned these filtered fields.
      var filteredFields = [];
      this.tableFields.forEach((field) => {
        var key = field.key;
        if (filteredFieldKeys.includes(key)) {
          filteredFields.push(field);
        }
      });

      return filteredFields;
    },
    patientsFiltered: function () {
      if (!this.patients || this.patients?.length === 0) {
        return this.patients;
      }

      if (!this.showRowState) {
        return this.patients;
      }

      return this.patients.map((item) => {
        item._rowVariant = this.getRowVariants(item);
        return item;
      });
    },
  },
  watch: {
    reloadPatients: function () {
      this.getPatients(
        LOAD_TYPE_RELOAD,
        this.currentSortField,
        this.currentSortDesc
      );
    },
  },
  created() {
    // Delay 100 ms between resize events. Note that we have to manually create
    // a debounce function, otherwise if there are multiple instances of this
    // component it'll end up not working correctly.
    this.debouncedOnWindowResize = debounce(this.onWindowResize, 100);
  },
  mounted: function () {
    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.debouncedOnWindowResize);

    // Seed the patients
    this.getPatients(
      LOAD_TYPE_INITIAL,
      this.currentSortField,
      this.currentSortDesc
    );
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    window.removeEventListener("resize", this.debouncedOnWindowResize);
  },
  methods: {
    rowAttributes(item, type) {
      if (type === "row") {
        return {
          "data-testid": `id:${item.clinicpatientidentifier}`,
        };
      }
      return {};
    },
    clinicianNameFormatter: function (value) {
      return this.formatClinicUserName(value);
    },
    patientNameFormatter: function (value, key, item) {
      return this.formatPatientName(item);
    },
    clinicNameFormatter: function (value, key, item) {
      for (let clinic of this.clinics) {
        if (clinic.id === value) {
          return clinic.name;
        }
      }

      return "";
    },
    dobFormatter: function (value, key, item) {
      if (!this.fieldExists(item, key)) {
        return this.getFieldPhi(item, key);
      } else {
        let dob = this.getFieldPhi(item, key);

        if (dob) {
          let date = null;
          try {
            date = this.createDateInLocalTz(dob);

            if (date) {
              return this.$d(date, "date");
            }
          } catch (e) {
            // Ignore any conversion errors, just return the string as is.
          }
        }

        return dob;
      }
    },
    monitoringFormatter: function (value, key, item) {
      if (!this.fieldExists(item, "monitoring_status")) {
        return this.getFieldPhi(item, "monitoring_status");
      } else {
        if (item.monitoring_status) {
          switch (item.monitoring_status) {
            case "active":
              return this.$t("patient.labelMonitoringActive");

            case "completed":
              return this.$t("patient.labelMonitoringComplete");

            case "pending":
            default:
              return this.$t("patient.labelMonitoringStartingSoon");
          }
        } else {
          return this.$t("patient.labelMonitoringStartingSoon");
        }
      }
    },
    getUnitsLabel: function (units) {
      switch (units) {
        case "day":
          return this.$t("patient.labelMonitoringDay");

        case "week":
          return this.$t("patient.labelMonitoringWeek");

        case "month":
          return this.$t("patient.labelMonitoringMonth");
      }

      return "-";
    },
    getCurrentTimeUnits: function (startDate, duration, units) {
      if (!duration) {
        return String(0);
      }

      const now = new Date();
      var secondsSinceEpoch = Math.round(now.getTime() / 1000);
      // Make sure we do not go over the limit
      if (secondsSinceEpoch > startDate + duration) {
        secondsSinceEpoch = startDate + duration;
      }

      switch (units) {
        case "day":
          return String(Math.ceil((secondsSinceEpoch - startDate) / 86400));

        case "week":
          return String(Math.ceil((secondsSinceEpoch - startDate) / 604800));

        case "month":
          return String(Math.ceil((secondsSinceEpoch - startDate) / 2628288));
      }

      return "-";
    },
    getTotalTimeUnits: function (duration, units) {
      if (!duration) {
        return 0;
      }

      switch (units) {
        case "day":
          return String(Math.ceil(duration / 86400));

        case "week":
          return String(Math.ceil(duration / 604800));

        case "month":
          return String(Math.ceil(duration / 2628288));
      }

      return "-";
    },
    patchPlacementFormatter: function (value, key, item) {
      if (!this.fieldExists(item, key)) {
        return this.getFieldPhi(item, key);
      } else {
        var patchLocations = null;

        for (let clinic of this.clinics) {
          if (clinic.id === item.clinic) {
            patchLocations = clinic.patchlocations;
          }
        }

        if (patchLocations) {
          for (let location of patchLocations) {
            if (location.id === value) {
              return location.locationname;
            }
          }
        }

        return "-";
      }
    },
    lastDataFormatter: function (value, key, item) {
      if (this.fieldExists(item, "lastdataseen")) {
        return this.dateOrEpochFormat(item.lastdataseen, true);
      } else {
        return "-";
      }
    },
    statusHtmlFormatter: function (item) {
      if (!this.fieldExists(item, "statusdesc")) {
        return this.getFieldPhi(item, "statusdesc");
      } else {
        switch (item.statusdesc) {
          case "dyskalemia":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusDyskalemia") +
              "</span></div>"
            );
          case "highpotassium":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighPotassium") +
              "</span></div>"
            );
          case "lowpotassium":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowPotassium") +
              "</span></div>"
            );
          case "highhct":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighHCT") +
              "</span></div>"
            );
          case "lowhct":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowHCT") +
              "</span></div>"
            );
          case "highhgb":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighHGB") +
              "</span></div>"
            );
          case "lowhgb":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowHGB") +
              "</span></div>"
            );
          case "noactivepatchesyet":
          case "juststarted":
            return "<span></span>";

          case "ok":
            return '<div class="image-span-container"><img class="status-icon" src="/icon-active.svg"/></div>';

          case "finished":
            return "<span></span>";

          case "tempmissing":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-warning.svg"/><span>' +
              this.$t("patient.labelStatusMissingSkinTemp") +
              "</span></div>"
            );

          case "overtemp":
            return (
              "<span>" + this.$t("patient.labelStatusHighSkinTemp") + "</span>"
            );

          case "staledata":
            return (
              "<span>" +
              this.$tc(
                "patient.labelStatusDaysOld",
                this.getDaysSince(item.lastdataseen)
              ) +
              "</span>"
            );

          case "nostatusyet":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-warning.svg"/><span>' +
              this.$t("patient.labelStatusNoStatusYet") +
              "</span></div>"
            );

          default:
            return item.statusdesc;
        }
      }
    },
    getRowVariants: function (item) {
      if (
        item.monitoring_status !== "monitoring" &&
        item?.statusdesc === "finished"
      ) {
        return "row-inactive";
      } else if (
        [
          "staledata",
          "nostatusyet",
          "juststarted",
          "noactivepatchesyet",
        ].includes(item?.statusdesc)
      ) {
        return "row-stale";
      }

      return "";
    },
    getPatients: function (loadType, sortField, sortDesc) {
      var loadMessage = null;
      var skip = 0;
      var count = 25; // Default incremental count

      // If there is patient specified, then return this as the only one
      if (this.singlePatient) {
        this.patients = [this.singlePatient];
        return false;
      }

      switch (loadType) {
        case LOAD_TYPE_INITIAL:
        case LOAD_TYPE_MORE:
          loadMessage = this.$t("common.loading");
          skip = this.rowLoadCount;
          break;

        case LOAD_TYPE_SORT:
        case LOAD_TYPE_RELOAD:
          loadMessage =
            loadType === LOAD_TYPE_SORT
              ? this.$t("common.sorting")
              : this.$t("common.reloading");
          skip = 0;

          // Reset the all the variables
          this.rowLoadCount = 0;
          this.fullyLoaded = false;
          this.maxPatientId = null;
          this.patients = null;
          break;
      }

      if (this.fullyLoaded || this.loading) {
        // All loaded, or it is currently loading
        return false;
      }

      // Reset the scroll height
      this.lastScollHeightLoad = null;

      this.loading = true;
      this.message = loadMessage;

      var sortFieldProcessed = null;
      var sortDirProcessed = null;
      switch (sortField) {
        case "clinicpatientidentifier":
          sortFieldProcessed = "clinicpatientidentifier";
          break;

        case "ordering_clinician":
          sortFieldProcessed = "ordering_clinician";
          break;

        case "name":
          sortFieldProcessed = "lastname,firstname";
          break;

        case "clinic":
          sortFieldProcessed = "clinic";
          break;

        case "lastdataseen":
          sortFieldProcessed = "lastdataseen";
          break;

        case "status":
          sortFieldProcessed = "statusrank";
          sortDirProcessed = `DESC,${sortDesc ? "DESC" : "ASC"}`;
          break;

        default:
          sortFieldProcessed = sortField;
      }

      const queryParameters = {
        limit: count,
        skip: skip,
        sort: sortFieldProcessed,
        dir: sortDirProcessed || (sortDesc ? "DESC" : "ASC"),
      };

      if (this.searchText) {
        queryParameters.search = this.searchText;
      }

      if (!this.hideCompleted) {
        queryParameters.include = "completed";
      }

      if (this.maxPatientId !== null) {
        queryParameters["expectedmaxpatientid"] = this.maxPatientId;
      }

      if (this.clinics.length === 1) {
        let clinicId = this.clinics[0].id;

        this.api
          .v1ClinicClinicidPatientsGet(
            clinicId,
            queryParameters.limit,
            queryParameters.skip,
            queryParameters.sort,
            queryParameters.dir,
            queryParameters.expectedmaxpatientid
              ? queryParameters.expectedmaxpatientid
              : undefined,
            queryParameters.search ? queryParameters.search : undefined,
            queryParameters.include ? queryParameters.include : undefined
          )
          .then((response) => {
            this.handleGetPatientsSuccess(response, loadType, count);

            if (this.isClinician(this.$store.getters.user)) {
              // Log that this has been viewed.
              this.api
                .v1ClinicClinicidViewedPost(clinicId)
                .then(() => {
                  // Do nothing on success
                })
                .catch((error) => {
                  this.logApiResponseError(
                    "Clinic Viewed",
                    error,
                    this.$t("clinic.errorLoggingView")
                  );
                });
            }
          })
          .catch((error) => {
            this.handleGetPatientsError(error);
          });
      } else {
        this.api
          .v1PatientsGet(
            queryParameters.limit,
            queryParameters.skip,
            queryParameters.sort,
            queryParameters.dir,
            queryParameters.expectedmaxpatientid
              ? queryParameters.expectedmaxpatientid
              : undefined,
            queryParameters.search ? queryParameters.search : undefined,
            queryParameters.include ? queryParameters.include : undefined
          )
          .then((response) => {
            this.handleGetPatientsSuccess(response, loadType, count);
          })
          .catch((error) => {
            this.handleGetPatientsError(error);
          });
      }
    },
    handleGetPatientsSuccess: function (response, loadType, count) {
      this.message = null;
      this.maxPatientId = response.data.maxpatientid;

      if (this.patients === null) {
        this.patients = response.data.patients;
      } else {
        this.patients = this.patients.concat(response.data.patients);
      }

      if (this.patients.length < this.rowLoadCount + count) {
        this.fullyLoaded = true;

        if (this.patients.length === 0) {
          this.message = this.$t("patient.errorNoData");
        }
      } else {
        this.message = this.$t("common.more");
      }

      this.rowLoadCount = this.patients.length;
      this.loading = false;
      this.$emit(
        "patients-updated",
        loadType !== LOAD_TYPE_MORE,
        this.rowLoadCount,
        this.fullyLoaded,
        response.data.lastviewer,
        response.data.lastviewtime
      );

      this.$nextTick(() => {
        this.checkForLoad();
      });
    },
    handleGetPatientsError: function (error) {
      this.loading = false;

      // Get the error message and display it
      var backendChanged =
        (error.response && error.response.status) === 412 ? true : false;
      if (backendChanged) {
        // Reload the patients as there is a change in the backend
        this.getPatients(LOAD_TYPE_RELOAD, sortField, sortDesc);
      } else {
        this.showApiResponseError(error, this.$t("patient.errorLoadList"));
      }
    },
    onSort: function (context) {
      if (
        this.currentSortField !== context.sortBy ||
        this.currentSortDesc !== context.sortDesc
      ) {
        // Update the list if the sort has changed.
        this.currentSortField = context.sortBy;
        this.currentSortDesc = context.sortDesc;
        this.getPatients(
          LOAD_TYPE_SORT,
          this.currentSortField,
          this.currentSortDesc
        );
      }
    },
    onRowClick: function (item) {
      if (this.rowClickSupported) {
        this.$router.push({
          name: "patient-detail",
          params: {
            clinicId: item.clinic,
            patientId: item.id,
          },
        });
      }
    },
    onScroll: function (event) {
      var node = event.target.scrollingElement || document.body;

      if (node.scrollHeight - node.scrollTop >= node.clientHeight * 0.8) {
        // Start loading anytime we hit above the 80% full
        if (this.lastScollHeightLoad !== node.scrollHeight) {
          this.lastScollHeightLoad = node.scrollHeight;
          this.getPatients(
            LOAD_TYPE_MORE,
            this.currentSortField,
            this.currentSortDesc
          );
        }
      }
    },
    onWindowResize() {
      this.checkForLoad();
    },
    checkForLoad() {
      // check if we need to load more data based on how big the window is and how large our table is
      // not a perfect comparison, but it seems to work in my testing
      if (
        window.innerHeight >
        document.querySelector("#patient-all-table").offsetHeight
      ) {
        this.getPatients(
          LOAD_TYPE_MORE,
          this.currentSortField,
          this.currentSortDesc
        );
      }
    },
  },
};
</script>

<style scoped>
.status-icon {
  margin-right: 8px;
}
</style>
