export const chartProps = {
  events: {
    type: Array,
    required: false,
    default: () => [],
  },
  chartData: {
    type: Object,
    required: true,
  },
  chartId: {
    type: String,
    required: true,
  },
  acute: {
    type: Boolean,
    default: false,
  },
  acuteMode: {
    type: String,
    default: "noChange",
  },
  upperBound: {
    type: Number,
  },
  lowerBound: {
    type: Number,
  },
  ordinal: {
    default: false,
    type: Boolean,
  },
  clickHandler: {
    type: Function,
  },
};

export const calcFixedPosition = (chartData, min = null, max = null) => {
  let chartMin = min || chartData.minrange;
  let chartMax = max || chartData.maxrange;

  const chartRange = chartMax - chartMin;
  return chartMax - chartRange * 0.05; // 5% from the top
};
