function isRTL(locale) {
  const rtlLangs = [
    "ar",
    "arc",
    "dv",
    "fa",
    "ha",
    "he",
    "khw",
    "ks",
    "ku",
    "ps",
    "ur",
    "yi",
  ];
  for (const rtlLang of rtlLangs) {
    if (locale.split("-")[0] === rtlLang) return true;
  }
}

export function setDocumentDirectionPerLocale(locale) {
  if (isRTL(locale)) {
    document.dir = "rtl";
  } else {
    document.dir = "ltr";
  }
}

export function setDocumentLang(locale) {
  let lang = locale;
  if (locale.includes("-")) {
    lang = locale.split("-")[0];
  }
  document.documentElement.lang = lang;
}
