<template>
  <b-modal
    :id="modalId"
    centered
    hide-header
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    @shown="shown"
  >
    <p class="modal-confirmation-message">{{ confirmationMessage }}</p>
    <div class="buttons-container">
      <button
        id="confirmation-prompt-yes"
        ref="confirmationPromptYes"
        @click="onYes"
      >
        {{ $t("common.buttonYes") }}
      </button>
      <button
        id="confirmation-prompt-no"
        ref="confirmationPromptNo"
        class="button-negative"
        @click="onNo"
      >
        {{ $t("common.buttonNo") }}
      </button>
    </div>
  </b-modal>
</template>
<script>
export default {
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      confirmationMessage: null,
      confirmationEvent: null,
    };
  },
  mounted() {
    this.$bus.on("show-confirmation-prompt", this.showConfirmationPrompt);
  },
  beforeDestroy() {
    this.$bus.off("show-confirmation-prompt", this.showConfirmationPrompt);
  },
  methods: {
    onYes: function () {
      this.$bvModal.hide(this.modalId);

      this.$bus.emit(this.confirmationEvent, true);
    },
    onNo: function () {
      this.$bvModal.hide(this.modalId);

      this.$bus.emit(this.confirmationEvent, false);
    },
    showConfirmationPrompt: function (confirmationMessage, confirmationEvent) {
      this.confirmationEvent = confirmationEvent;

      if (confirmationMessage) {
        this.confirmationMessage = confirmationMessage;
      } else {
        this.confirmationMessage = this.$t("common.errorMessageNotSpecified");
      }

      this.$bvModal.show(this.modalId);
    },
    shown: function () {
      // Default is "No"
      this.$refs.confirmationPromptNo.focus();
    },
  },
};
</script>
