import { MutationTree } from "vuex";
import { State } from "./state";
import { User } from "@/shared/types/user";
import { Clinic } from "@/shared/types/clinic";

enum MutationTypes {
  UPDATE_USER_ID = "updateUserId",
  UPDATE_USER = "updateUser",
  UPDATE_CLINIC_ID = "updateClinicId",
  UPDATE_CLINIC = "updateClinic",
  UPDATE_LAST_REQUEST = "updateLastRequest",
  UPDATE_RELEASE = "updateRelease",
  UPDATE_UDI = "updateUdi",
  UPDATE_ENVIRONMENT = "updateEnvironment",
  UPDATE_COMPONENTS = "updateComponents",
  UPDATE_FEATURES = "updateFeatures",
}

export type Mutations<S = State> = {
  [MutationTypes.UPDATE_USER_ID](state: S, payload: number): void;
  [MutationTypes.UPDATE_USER](state: S, payload: User): void;
  [MutationTypes.UPDATE_CLINIC_ID](state: S, payload: number): void;
  [MutationTypes.UPDATE_CLINIC](state: S, payload: Clinic): void;
  [MutationTypes.UPDATE_LAST_REQUEST](state: S, payload: Date | null): void;
  [MutationTypes.UPDATE_RELEASE](state: S, payload: string | null): void;
  [MutationTypes.UPDATE_UDI](state: S, payload: string | null): void;
  [MutationTypes.UPDATE_ENVIRONMENT](state: S, payload: string | null): void;
  [MutationTypes.UPDATE_COMPONENTS](state: S, payload: object | null): void;
  [MutationTypes.UPDATE_FEATURES](state: S, payload: object | null): void;
};

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.UPDATE_USER_ID](state, payload: number) {
    state.userId = payload;
  },
  [MutationTypes.UPDATE_USER](state, payload: User) {
    state.user = payload;
  },
  [MutationTypes.UPDATE_CLINIC_ID](state, payload: number) {
    state.clinicId = payload;
  },
  [MutationTypes.UPDATE_CLINIC](state, payload: Clinic) {
    state.clinic = payload;
  },
  [MutationTypes.UPDATE_LAST_REQUEST](state, payload: Date | null) {
    state.lastRequest = payload;
  },
  [MutationTypes.UPDATE_RELEASE](state, payload: string | null) {
    state.release = payload;
  },
  [MutationTypes.UPDATE_UDI](state, payload: string | null) {
    state.udi = payload;
  },
  [MutationTypes.UPDATE_ENVIRONMENT](state, payload: string | null) {
    state.environment = payload;
  },
  [MutationTypes.UPDATE_COMPONENTS](state, payload: object | null) {
    state.components = payload;
  },
  [MutationTypes.UPDATE_FEATURES](state, payload: object | null) {
    state.features = payload;
  },
};
