<template>
  <div>
    <sub-header
      v-if="isClinicianApp()"
      :last-refresh-date="lastRefreshDate"
      :action-buttons="actionButtons"
      :last-viewed-date="lastViewTime"
      :last-viewed-clinician="lastViewer"
      @refresh="reloadPatients"
      @add-patient-button-event="goAddPatient"
      @timeline-button-event="goEventsPage"
    >
    </sub-header>
    <sub-header
      v-else-if="isSupportApp()"
      :show-last-viewed="false"
      :navigation-buttons-left="navigationButtonsSupport"
      :navigation-buttons-right="navigationButtonsSupportRight"
      :action-buttons="actionButtons"
      @view-clinic-details-event="navViewClinicDetails"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
      @add-patient-button-event="goAddPatient"
      @import-patients-button-event="showImportPatients"
      @timeline-button-event="goEventsPage"
    >
      <template #last-viewed>
        {{ formattedPatientCount }}
      </template>
    </sub-header>
    <PatientSearch @hide-completed="hideCompletedUpdate" @search="search" />
    <div class="container-fluid main-section patient-list-container">
      <div
        v-if="clinicResult === null"
        id="patient-list-loading"
        class="data-loading-container"
      >
        <span>{{ $t("common.loading") }}</span>
      </div>
      <div v-else-if="clinic" id="patient-list">
        <patient-list-table
          :clinics="[clinic]"
          :fields-allowed-filter="[
            'clinicpatientidentifier',
            'ordering_clinician',
            'name',
            'dob',
            'monitoring',
            'patchplacement',
            'notifiable_events',
            'status',
          ]"
          :search-text="searchText"
          :hide-completed="hideCompleted"
          :reload-patients="patientReloadTrigger"
          @patients-updated="patientsUpdated"
        >
        </patient-list-table>
      </div>
      <div v-else-if="clinicResult === false" class="data-empty-container">
        <span>{{ $t("clinic.errorLoadClinics") }}</span>
      </div>
    </div>
    <import-patients-modal modal-id="import-patients-modal" />
  </div>
</template>
<script>
import PatientSearch from "@/shared/components/PatientSearch.vue";
export default {
  components: {
    PatientSearch,
  },
  data: function () {
    return {
      // Subheader
      lastRefreshDate: null,
      navigationButtonsSupport: [
        {
          label: this.$t("navigation.viewClinicDetailsWithBack"),
          id: "view-clinic-details-sub-header-button",
          event: "view-clinic-details-event",
        },
      ],
      navigationButtonsSupportRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      actionButtons: [
        {
          label: this.$t("action.addPatient"),
          id: "add-patient-sub-header-button",
          event: "add-patient-button-event",
        },
        {
          label: "Timeline",
          id: "timeline-sub-header-button",
          event: "timeline-button-event",
        },
      ],
      // Clinic and Patients Data
      clinicResult: null,
      clinic: null,
      lastViewer: null,
      lastViewTime: null,
      patientsCount: null,
      patientReloadTrigger: 0,
      searchText: null,
      hideCompleted: true,
    };
  },
  computed: {
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    formattedPatientCount: function () {
      if (this.patientsCount) {
        return this.$t("patient.labelTotalPatients", {
          count: this.patientsCount,
        });
      }

      return "";
    },
  },
  mounted: function () {
    // Make sure the clinic ID is valid
    if (this.verifyClinicId(this.clinicIdParameter)) {
      this.getClinic();
    }

    if (this.isSupportApp()) {
      this.actionButtons.push({
        label: this.$t("action.importPatients"),
        id: "import-patients-sub-header-button",
        event: "import-patients-button-event",
      });
    }
  },
  methods: {
    goEventsPage() {
      this.$router.push({
        name: "events",
        params: {
          clinicId: this.$route.params.clinicId,
        },
        query: {
          patientId: this.$route.params.patientId,
        },
      });
    },
    hideCompletedUpdate(hideCompleted) {
      this.hideCompleted = hideCompleted;
      this.reloadPatients();
    },
    search(searchText) {
      this.searchText = searchText;
      this.reloadPatients();
    },
    getClinic: function () {
      this.clinicResult = null;
      this.clinic = null;

      this.getClinicInfo(
        this.clinicIdParameter,
        false,
        (clinic) => {
          this.clinic = clinic;
          this.clinicResult = true;
        },
        (error) => {
          this.clinicResult = false;
          this.showApiResponseError(error, this.$t("clinic.errorLoadDetails"));
        }
      );
    },
    navViewClinicDetails: function () {
      this.$router.push({
        name: "clinic-detail",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    goAddPatient: function () {
      this.$router.push({
        name: "add-patient",
        params: {
          clinicId: this.clinicIdParameter,
        },
      });
    },
    showImportPatients: function () {
      this.$bvModal.show("import-patients-modal");
    },
    reloadPatients: function () {
      this.patientReloadTrigger++;
    },
    patientsUpdated: function (
      reload,
      count,
      allLoaded,
      lastViewer,
      lastViewTime
    ) {
      this.patientsCount = count;

      if (reload) {
        this.lastViewer = lastViewer;
        this.lastViewTime = lastViewTime;
        this.lastRefreshDate = new Date();
      }
    },
  },
};
</script>
