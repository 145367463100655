<template>
  <div>
    <sub-header
      :navigation-buttons-left="navigationButtonsLeft"
      :navigation-buttons-right="navigationButtonsRight"
      :show-last-viewed="false"
      @view-clinic-details-event="navViewClinicList"
      @view-kit-hub-patch-list-event="navViewKitHubPatchList"
    >
    </sub-header>
    <div class="main-section container-fluid">
      <section-header :header-text="$t('settingsClinician.notifications')" />
      <b-form class="settings" @submit.prevent="saveSettings">
        <potassium-settings
          :number-low-potassium.sync="
            inputFields.potassiumSettings.numberLowPotassium
          "
          :number-high-potassium.sync="
            inputFields.potassiumSettings.numberHighPotassium
          "
          :notify-low-potassium.sync="
            inputFields.potassiumSettings.notifyLowPotassium
          "
          :notify-high-potassium.sync="
            inputFields.potassiumSettings.notifyHighPotassium
          "
          :notify-dyskalemia.sync="
            inputFields.potassiumSettings.notifyDyskalemia
          "
          :number-dyskalemia.sync="
            inputFields.potassiumSettings.numberDyskalemia
          "
          :disabled="isNotClinicAdmin"
          :clinic-id-parameter="clinicIdParameter"
          :ordinal="clinic.ordinal_potassium"
        />
        <hct-or-hgb-settings
          class="mt-4"
          type="hct"
          :notify.sync="inputFields.hctSettings.notify"
          :threshold-low.sync="inputFields.hctSettings.thresholdLow"
          :threshold-high.sync="inputFields.hctSettings.thresholdHigh"
          :number-low.sync="inputFields.hctSettings.numberLow"
          :number-high.sync="inputFields.hctSettings.numberHigh"
          :disabled="isNotClinicAdmin"
          :clinic-id-parameter="clinicIdParameter"
        />
        <hct-or-hgb-settings
          class="mt-4"
          type="hgb"
          :notify.sync="inputFields.hgbSettings.notify"
          :threshold-low.sync="inputFields.hgbSettings.thresholdLow"
          :threshold-high.sync="inputFields.hgbSettings.thresholdHigh"
          :number-low.sync="inputFields.hgbSettings.numberLow"
          :number-high.sync="inputFields.hgbSettings.numberHigh"
          :disabled="isNotClinicAdmin"
          :clinic-id-parameter="clinicIdParameter"
        />
        <section-header
          :header-text="$t('settingsClinician.advisories')"
          class="mt-4 mb-2"
        />
        <sub-section-header
          class="mb-1"
          :header-text="$t('settingsClinician.headerAcoustic')"
        />
        <div class="setting-section">
          <div class="input-container-settings-select">
            <b-form-checkbox
              v-model="inputFields.acousticSettings.notify"
              name="acoustic"
            >
              {{ $t("settingsClinician.notifyAcoustic") }}
            </b-form-checkbox>
          </div>
        </div>
        <div id="settings-button-container" class="buttons-container">
          <button
            id="settings-save"
            type="submit"
            :disabled="!hasClinicSettingsChanged"
          >
            {{ $t("common.buttonSave") }}
          </button>
          <button
            id="settings-reset"
            type="button"
            class="button-negative"
            @click.prevent="resetSettings"
          >
            {{ $t("common.buttonReset") }}
          </button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PotassiumSettings from "@/shared/components/PotassiumSettings.vue";
import HctOrHgbSettings from "@/shared/components/HCTOrHGBSettings.vue";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import SubSectionHeader from "@/shared/components/Primitives/SubSectionHeader.vue";

export default defineComponent({
  name: "ClinicNotificationSettings",
  components: {
    SubSectionHeader,
    HctOrHgbSettings,
    PotassiumSettings,
    SectionHeader,
  },
  data() {
    return {
      // subheader
      navigationButtonsLeft: [
        {
          label: this.$t("navigation.viewClinicDetailsWithBack"),
          id: "view-clinic-details-sub-header-button",
          event: "view-clinic-details-event",
        },
      ],
      navigationButtonsRight: [
        {
          label: this.$t("navigation.viewKitHubPatchList"),
          id: "view-kit-hub-patch-list-sub-header-button",
          event: "view-kit-hub-patch-list-event",
        },
      ],
      currentClinicLoadResult: null,
      updateClinicSettingsResult: null,
      clinic: null,
      inputFields: {
        potassiumSettings: {
          notifyLowPotassium: false,
          numberLowPotassium: null,
          notifyHighPotassium: false,
          numberHighPotassium: null,
          notifyDyskalemia: false,
          numberDyskalemia: null,
        },
        hgbSettings: {
          notify: false,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
        hctSettings: {
          notify: false,
          thresholdLow: null,
          thresholdHigh: null,
          numberLow: null,
          numberHigh: null,
        },
        acousticSettings: {
          notify: false,
        },
      },
    };
  },
  computed: {
    isNotClinicAdmin() {
      return !this.isClinicUserAdmin(this.currentUser, this.clinicIdParameter);
    },
    clinicIdParameter: function () {
      return parseInt(this.$route.params.clinicId, 10);
    },
    currentUser: function () {
      return this.$store.getters.user;
    },
    hasClinicSettingsChanged: function () {
      if (this.currentClinicLoadResult === true) {
        if (
          this.inputFields.acousticSettings.notify !==
          this.clinic.notify_acoustic
        ) {
          return true;
        }
      }

      return false;
    },
  },
  mounted() {
    this.refreshCurrentClinic();
  },
  methods: {
    navViewClinicList: function () {
      this.$router.push({
        name: "clinic-detail",
        params: { clinicId: this.clinicIdParameter },
      });
    },
    navViewKitHubPatchList: function () {
      this.$router.push({ name: "kit-hub-patch-list" });
    },
    saveSettings() {
      if (this.hasClinicSettingsChanged) {
        this.updateClinicSettings();
      }
    },
    updateClinicSettings() {
      this.updateClinicSettingsResult = null;

      this.api
        .v1ClinicClinicidPut(this.getCurrentClinicId(), {
          updateclinic: {
            notify_acoustic: this.inputFields.acousticSettings.notify,
          },
        })
        .then(() => {
          this.updateClinicSettingsResult = true;
          this.refreshCurrentClinic();
          this.$bus.emit(
            "show-general-success",
            this.$t("clinic.successUpdateSettings")
          );
        })
        .catch((error) => {
          this.updateClinicSettingsResult = false;

          // Get the error and display it
          this.showApiResponseError(
            error,
            this.$t("clinic.errorUpdateSettings")
          );
        });
    },
    resetSettings: function () {
      if (this.currentClinicLoadResult === true) {
        this.inputFields.potassiumSettings.notifyLowPotassium =
          this.clinic.notify_low_potassium;
        this.inputFields.potassiumSettings.numberLowPotassium =
          this.clinic.number_low_potassium;
        this.inputFields.potassiumSettings.notifyHighPotassium =
          this.clinic.notify_high_potassium;
        this.inputFields.potassiumSettings.numberHighPotassium =
          this.clinic.number_high_potassium;
        this.inputFields.potassiumSettings.notifyDyskalemia =
          this.clinic.notify_dyskalemia;
        this.inputFields.potassiumSettings.numberDyskalemia =
          this.clinic.number_dyskalemia;
        this.inputFields.hctSettings.notify = this.clinic.notify_hct;
        this.inputFields.hctSettings.thresholdLow =
          this.clinic.threshold_low_hct;
        this.inputFields.hctSettings.thresholdHigh =
          this.clinic.threshold_high_hct;
        this.inputFields.hgbSettings.notify = this.clinic.notify_hgb;
        this.inputFields.hgbSettings.thresholdLow =
          this.clinic.threshold_low_hgb;
        this.inputFields.hgbSettings.thresholdHigh =
          this.clinic.threshold_high_hgb;
        this.inputFields.acousticSettings.notify = this.clinic.notify_acoustic;
        this.inputFields.hgbSettings.numberLow = this.clinic.number_low_hgb;
        this.inputFields.hgbSettings.numberHigh = this.clinic.number_high_hgb;
        this.inputFields.hctSettings.numberLow = this.clinic.number_low_hct;
        this.inputFields.hctSettings.numberHigh = this.clinic.number_high_hct;
      }
    },
    refreshCurrentClinic() {
      // Get the latest Clinic information
      this.currentClinicLoadResult = null;
      this.clinic = null;

      this.getClinicInfo(
        this.clinicIdParameter,
        true,
        (clinic) => {
          this.clinic = clinic;
          this.currentClinicLoadResult = true;
          this.resetSettings();
        },
        (error) => {
          this.currentClinicLoadResult = false;

          // Get the error and show it
          this.showApiResponseError(error, this.$t("clinic.errorLoadDetails"));
        }
      );
    },
  },
});
</script>

<style scoped lang="scss">
.notification-section-header {
  font-size: 24px;
  font-weight: 700;
}
</style>
