// @ts-nocheck
const defaultFormat = {
  // displays as our common format when displayed in a table
  dayOfYear: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  nHourMinute: {
    hour: "numeric",
    minute: "numeric",
  },
  monitoring: {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  },
  nTable: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  table: {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  },
  date: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  },
};

const dateTimeFormats = {
  "en-US": defaultFormat,
  "en-GB": defaultFormat,
};

export default dateTimeFormats;
