<template>
  <div>
    <div>
      <div id="login-main" class="container-narrow">
        <div id="login-logo-container" class="main-logo">
          <img id="login-logo" src="/alio_rms_logo.png" alt="main-logo" />
        </div>
        <div id="login-title">{{ $t("common.supportPortal") }}</div>
        <div id="login-logging-in">
          <span>{{ $t("login.loggingIn") }}</span>
        </div>
      </div>
    </div>
    <privacy-policy-modal
      modal-id="privacy-policy-modal"
      :require-accept="privacy.requireAccept"
      :last-accepted-version="privacy.lastAcceptedVersion"
      @last-accepted-version="updateLastAcceptedVersion"
      @policy-accepted="acceptPrivacyPolicy"
      @policy-not-accepted="declinePrivacyPolicy"
    >
    </privacy-policy-modal>
  </div>
</template>
<script>
import { getCurrentUser } from "aws-amplify/auth";

export default {
  data: function () {
    return {
      privacy: {
        requireAccept: true,
        lastAcceptedVersion: null,
      },
    };
  },
  mounted: function () {
    getCurrentUser()
      .then(() => {
        this.getUserInfo();
      })
      .catch(() => {
        this.$router.replace({ name: "login" });
      });
  },
  methods: {
    acceptPrivacyPolicy: function (version) {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfAcceptprivacypolicyPut({
          acceptance: {
            privacypolicyacceptedversion: version,
          },
        })
        .then((response) => {
          this.completeLogin();
        })
        .catch((error) => {
          // Get and display the error message
          this.showApiResponseError(
            error,
            this.$t("privacyPolicy.errorAccept")
          );
          this.$router.replace({ name: "login" });
        });
    },
    saveLastLoginTime: function () {
      // Accept Privacy Policy not currently support, so just shortcut it
      this.api
        .v1ClinicuserSelfLoginPost()
        .then((response) => {
          // Do nothing on success
        })
        .catch((error) => {
          // Get and log any error message
          this.logApiResponseError(
            "Login Time",
            error,
            this.$t("login.errorLoginTracking")
          );
        });
    },
    declinePrivacyPolicy: function (version) {
      // Renable the buttons
      this.$router.replace({ name: "login" });
    },
    updateLastAcceptedVersion: function (version) {
      this.privacy.lastAcceptedVersion = version;
    },
    showPrivacyPolicyModal: function (requireAccept, lastAcceptedVersion) {
      this.privacy.requireAccept = requireAccept;
      if (
        lastAcceptedVersion === null ||
        this.privacy.lastAcceptedVersion !== lastAcceptedVersion
      ) {
        this.$bvModal.show("privacy-policy-modal");
      } else {
        this.completeLogin();
      }
    },
    completeLogin: function () {
      const clinicUser = this.$store.getters.user;

      if (
        !clinicUser ||
        clinicUser.clinics.length === 0 ||
        clinicUser.clinics[0] === null
      ) {
        this.$bus.emit(
          "show-general-error",
          this.$t("login.errorNotAssociated")
        );
        this.$router.replace({ name: "login" });
      } else {
        // Send an event for successful login
        this.$bus.emit("login-complete");
        this.$router.replace({ name: "find-home" });
      }
    },
    getUserInfo: function () {
      this.api
        .v1WhoamiGet()
        .then((response) => {
          const userId = response.data.clinicuserid;
          this.setCurrentUserId(userId);
          this.saveLastLoginTime();
          const groups = response.data.groups;
          this.refreshCurrentUserInfoFromApi(
            (response) => {
              const user = response.data.clinicuser;

              if (!user) {
                this.$bus.emit(
                  "show-general-error",
                  this.$t("login.errorNoUser")
                );
                this.$router.replace({ name: "login" });
              } else {
                // Add the groups to the user
                user.groups = groups;
                this.$store.commit("updateUser", user);
                console.log(user);
                this.showPrivacyPolicyModal(
                  true,
                  user.privacypolicyacceptedversion
                );
              }
            },
            (error) => {
              // Get and show the error message
              this.showApiResponseError(
                error,
                this.$t("login.errorLoadDetails")
              );
              this.$router.replace({ name: "login" });
            }
          );
        })
        .catch((error) => {
          this.showApiResponseError(error, this.$t("login.errorLoadDetails"));
          this.$router.replace({ name: "login" });
        });
    },
  },
};
</script>
<style lang="scss">
#login-logo {
  width: 200px;
}

#login-title {
  color: $gray-mid;
  margin-top: 24px;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  text-transform: uppercase;
}

#login-logging-in {
  margin-top: 40px;

  span {
    font-size: 18px;
  }
}
</style>
