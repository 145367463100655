<template>
  <b-modal :id="modalId" centered hide-header hide-footer @show="showModal">
    <section-header :header-text="$t('forgotPasswordReset.title')" />
    <b-form @submit.prevent="submit">
      <b-form-group class="input-container">
        <b-form-input
          id="forgot-password-reset-username"
          v-model="inputFields.username"
          :state="validateState('username')"
          type="email"
          :placeholder="$t('forgotPasswordReset.fieldPlaceholderEmail')"
          :maxlength="getInputMaxChar('email')"
          aria-describedby="forgot-username-live-feedback"
          autocomplete="username"
          @blur.native="v$.inputFields.username.$touch()"
        />
        <b-form-invalid-feedback id="forgot-username-live-feedback">
          {{ $t("validations.usernameFeedback") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="input-container">
        <b-form-input
          id="forgot-password-reset-code"
          v-model="inputFields.resetCode"
          :state="validateState('resetCode')"
          type="text"
          :placeholder="$t('forgotPasswordReset.fieldPlaceholderResetCode')"
          :maxlength="getInputMaxChar('password-reset-code')"
          aria-describedby="forgot-reset-code-live-feedback"
          @blur.native="v$.inputFields.resetCode.$touch()"
        />
        <b-form-invalid-feedback id="forgot-reset-code-live-feedback">
          {{ $t("validations.resetCodeFeedback") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="input-container">
        <b-form-input
          id="forgot-password-reset-password"
          v-model="v$.inputFields.password.$model"
          :state="validateState('password')"
          type="password"
          :placeholder="$t('forgotPasswordReset.fieldPlaceholderNewPassword')"
          :maxlength="getInputMaxChar('password')"
          aria-describedby="forgot-reset-password-live-feedback"
          autocomplete="new-password"
          @blur.native="v$.inputFields.password.$touch()"
        />
        <b-form-invalid-feedback id="forgot-reset-password-live-feedback">
          {{ formattedPasswordRequirements }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group class="input-container">
        <b-form-input
          id="forgot-password-reset-password-verify"
          v-model="v$.inputFields.passwordVerify.$model"
          :state="validateState('passwordVerify')"
          type="password"
          :placeholder="
            $t('forgotPasswordReset.fieldPlaceholderReTypePassword')
          "
          :maxlength="getInputMaxChar('password')"
          aria-describedby="forgot-reset-verify-password-live-feedback"
          autocomplete="new-password"
          @blur.native="v$.inputFields.passwordVerify.$touch()"
        />
        <b-form-invalid-feedback
          id="forgot-reset-verify-password-live-feedback"
        >
          {{ $t("validations.passwordVerify") }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div
        id="forgot-password-reset-buttons-container"
        class="buttons-container"
      >
        <button
          id="forgot-password-reset-submit-button"
          type="submit"
          :disabled="!submitEnabled || !buttonsEnabled"
        >
          {{ $t("common.buttonSubmit") }}
        </button>
        <button
          id="forgot-password-reset-cancel-button"
          type="button"
          class="button-negative"
          :disabled="!buttonsEnabled"
          @click.prevent="cancel"
        >
          {{ $t("common.buttonCancel") }}
        </button>
      </div>
    </b-form>
  </b-modal>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, email, sameAs } from "@vuelidate/validators";
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
import { confirmResetPassword } from "aws-amplify/auth";

export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data: function () {
    return {
      buttonsEnabled: true,
      inputFields: {
        username: null,
        resetCode: null,
        password: null,
        passwordVerify: null,
      },
    };
  },
  validations() {
    return {
      inputFields: {
        username: {
          required,
          email,
        },
        resetCode: {
          required,
        },
        password: {
          required,
          validPassword: this.validPassword,
        },
        passwordVerify: {
          required,
          sameAsPassword: sameAs(this.inputFields.password),
        },
      },
    };
  },
  computed: {
    formattedPasswordRequirements: function () {
      return this.getInputPasswordRequirements();
    },
    submitEnabled() {
      return !this.v$.$invalid;
    },
  },
  methods: {
    submit: async function () {
      this.buttonsEnabled = false;

      try {
        await confirmResetPassword({
          username: this.inputFields.username,
          confirmationCode: this.inputFields.resetCode,
          newPassword: this.inputFields.password,
        });

        // On success hide the modal and show a success message
        this.$bvModal.hide(this.modalId);
        this.$bus.emit(
          "show-general-success",
          this.$t("forgotPasswordReset.success")
        );
      } catch (error) {
        this.showCognitoResponseError(
          error,
          this.$t("forgotPasswordReset.errorPlaceholder")
        );
      } finally {
        // Re-enable the buttons
        this.buttonsEnabled = true;
      }
    },
    cancel: function () {
      this.$bvModal.hide(this.modalId);
    },
    showModal: function () {
      this.v$.$reset();
      // Anytime this is shown, clear the input fields. This is so they
      // do not hold state when it might be shown a second time
      this.inputFields.username = null;
      this.inputFields.resetCode = null;
      this.inputFields.password = null;
      this.inputFields.passwordVerify = null;
    },
  },
};
</script>
