<template>
  <div>
    <b-table
      v-if="isDesktop"
      id="patient-detail-table"
      class="basic-table"
      no-sort-reset
      no-local-sorting
      :fields="filteredTableFields"
      :items="items"
    >
      <template #cell(status)="data">
        <span v-html="statusHtmlFormatter(data.item)"></span>
      </template>
    </b-table>
    <div v-else class="mobile-view">
      <div v-for="(item, index) in items" :key="index" class="item-details">
        <div
          v-for="field in filteredTableFields"
          :key="field.key"
          class="detail"
        >
          <strong class="mobile-label">{{ field.label }}:</strong>
          <span
            v-if="field.key === 'status'"
            v-html="statusHtmlFormatter(item)"
          ></span>
          <span v-else>{{ item[field.key] }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PatientDetailTable",
  props: {
    ordinal: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
  },
  data: function () {
    return {
      windowWidth: window.innerWidth,
      tableFields: [
        {
          key: "status",
          label: this.$t("patient.tableHeaderNotifications"),
        },
        {
          key: "k_abnormal_reads_percent",
          label: this.$t("patient.tableHeaderPercentK"),
          formatter: this.percentFormatter,
        },
        {
          key: "k_high_reads_percent",
          label: this.$t("patient.tableHeaderPercentHighK"),
          formatter: this.percentFormatter,
        },
        {
          key: "k_low_reads_percent",
          label: this.$t("patient.tableHeaderPercentLowK"),
          formatter: this.percentFormatter,
        },
        {
          key: "hgb_high_reads_percent",
          label: this.$t("patient.tableHeaderPercentHighHGB"),
          formatter: this.percentFormatter,
        },
        {
          key: "hgb_low_reads_percent",
          label: this.$t("patient.tableHeaderPercentLowHGB"),
          formatter: this.percentFormatter,
        },
        {
          key: "hct_high_reads_percent",
          label: this.$t("patient.tableHeaderPercentHighHCT"),
          formatter: this.percentFormatter,
        },
        {
          key: "hct_low_reads_percent",
          label: this.$t("patient.tableHeaderPercentLowHCT"),
          formatter: this.percentFormatter,
        },
        {
          key: "number_notifiable_events",
          label: this.$t("patient.tableHeaderNumberNotifiableEvents"),
        },
      ],
    };
  },
  computed: {
    isDesktop() {
      return this.windowWidth >= 600; // Example breakpoint for desktop
    },
    filteredTableFields() {
      const localFilteredFields = this.tableFields.filter((field) => {
        return field.key in this.items[0];
      });

      if (this.ordinal) {
        return localFilteredFields.filter((field) => {
          return !field.key.includes("abnormal");
        });
      } else {
        return localFilteredFields.filter((field) => {
          return !field.key.includes("k_low") && !field.key.includes("k_high");
        });
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
  methods: {
    percentFormatter: function (value) {
      if (value === null) {
        return "";
      }
      return value + "%";
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    },
    statusHtmlFormatter: function (item) {
      if (!this.fieldExists(item, "status")) {
        return this.getFieldPhi(item, "status");
      } else {
        switch (item.status) {
          case "dyskalemia":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusDyskalemia") +
              "</span></div>"
            );
          case "highpotassium":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighPotassium") +
              "</span></div>"
            );
          case "lowpotassium":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowPotassium") +
              "</span></div>"
            );
          case "highhct":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighHCT") +
              "</span></div>"
            );
          case "lowhct":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowHCT") +
              "</span></div>"
            );
          case "highhgb":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusHighHGB") +
              "</span></div>"
            );
          case "lowhgb":
            return (
              '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-alert.svg"/><span>' +
              this.$t("patient.labelStatusLowHGB") +
              "</span></div>"
            );
          case "noactivepatchesyet":
          case "juststarted":
            return '<span data-testid="patient-status"></span>';

          case "ok":
            return '<div data-testid="patient-status" class="image-span-container"><img class="status-icon" src="/icon-active.svg"/></div>';

          case "finished":
            return '<span data-testid="patient-status"></span>';

          case "tempmissing":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-warning.svg"/><span>' +
              this.$t("patient.labelStatusMissingSkinTemp") +
              "</span></div>"
            );

          case "overtemp":
            return (
              "<span>" + this.$t("patient.labelStatusHighSkinTemp") + "</span>"
            );

          case "staledata":
            return (
              "<span>" +
              this.$tc(
                "patient.labelStatusDaysOld",
                this.getDaysSince(item.lastdataseen)
              ) +
              "</span>"
            );

          case "nostatusyet":
            return (
              '<div class="image-span-container"><img class="status-icon" src="/icon-warning.svg"/><span>' +
              this.$t("patient.labelStatusNoStatusYet") +
              "</span></div>"
            );

          default:
            return item.statusdesc;
        }
      }
    },
  },
};
</script>

<style scoped>
.mobile-label {
  text-transform: uppercase;
  padding-right: 8px;
}
.status-icon {
  margin-right: 8px;
}
</style>
