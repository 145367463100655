<template>
  <b-modal :id="modalId" centered hide-header hide-footer>
    <section-header :header-text="$t('ifu.title')" />
    <div class="document-link-container">
      <a target="_blank" class="document-link" href="/docs/en/PortalIFU.pdf">{{
        $t("ifu.portalIFU")
      }}</a>
    </div>
    <div class="document-link-container">
      <a
        target="_blank"
        class="document-link"
        href="/docs/en/Alio-SmartPatch-QuickStartGuide.pdf"
        >{{ $t("ifu.quickstartGuide") }}</a
      >
    </div>
    <div class="document-link-container">
      <a
        target="_blank"
        class="document-link"
        href="/docs/en/Alio-SmartPatch-SystemIFU.pdf"
        >{{ $t("ifu.systemIFU") }}</a
      >
    </div>

    <div id="instructions-for-use-button-container" class="buttons-container">
      <button id="instructions-for-use-close-button" @click="onClose">
        {{ $t("common.buttonClose") }}
      </button>
    </div>
  </b-modal>
</template>
<script>
import SectionHeader from "@/shared/components/Primitives/SectionHeader.vue";
export default {
  components: {
    SectionHeader,
  },
  props: {
    modalId: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClose: function () {
      // On success hide the modal
      this.$bvModal.hide(this.modalId);

      // On success indicate the instructions for use was closed
      this.$emit("instructions-for-use-closed");
    },
  },
};
</script>
<style lang="scss">
.document-link-container {
  text-align: center;
  margin: 10px;
}
.document-link {
  font-size: 16px;
}
</style>
