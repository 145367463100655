import { isClinicianApp, fieldExists } from "@/shared/utils";
import { defineComponent } from "vue";
import { clinicianCognitoConfig } from "@/shared/aws-exports";
import { ResourcesConfig } from "aws-amplify";

export default defineComponent({
  name: "ApiMixin",
  methods: {
    getAwsConfig() {
      if (isClinicianApp(this)) {
        const siteConfig = this.$store.getters.siteConfig;
        const site = this.$store.getters.site;
        if (
          siteConfig &&
          fieldExists(siteConfig, "Id") &&
          fieldExists(siteConfig, "ClientId")
        ) {
          const region = siteConfig.Id.split("_")[0];

          return {
            Auth: {
              Cognito: {
                userPoolId: siteConfig.Id,
                userPoolClientId: siteConfig.ClientId,
                loginWith: {
                  oauth: {
                    domain: `${siteConfig.Domain}.auth.us-west-2.amazoncognito.com`,
                    scope: [
                      "phone",
                      "email",
                      "profile",
                      "openid",
                      "aws.cognito.signin.user.admin",
                    ],
                    redirectSignIn: [
                      `${
                        import.meta.env.VITE_CLINICIAN_USER_REDIRECT_LOGIN_URI
                      }${site}/sso`,
                    ],
                    redirectSignOut: [
                      `${
                        import.meta.env.VITE_CLINICIAN_USER_REDIRECT_LOGIN_URI
                      }${site}`,
                    ],
                    responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
                  },
                },
              },
            },
          } as ResourcesConfig;
        } else {
          return clinicianCognitoConfig;
        }
      }

      return null;
    },
    getCognitoResponseError(error: any, defaultError: any) {
      if (error) {
        if (error.message) {
          return error.message;
        } else {
          return error;
        }
      }

      return defaultError;
    },
    showCognitoResponseError(error: any, defaultError: any) {
      const errorMsg = this.getCognitoResponseError(error, defaultError);
      this.$bus.emit("show-general-error", errorMsg);
    },
    getApiResponseError(error: any, defaultError: any) {
      if (error.response && error.response.status == 401) {
        // With a 401 reply, force to log back in and redirect
        this.navToLogin(this.$route.path);
      }

      if (error) {
        if (error.response) {
          if (error.response.data.msg) {
            if (error.response.data.msg.en) {
              return error.response.data.msg.en;
            } else {
              return error.response.data.msg;
            }
          } else if (error.response.statusText) {
            return error.response.statusText;
          } else if (error.response.status) {
            return "Error: " + error.response.status;
          } else {
            return error.response;
          }
        } else if (error.statusText) {
          return error.statusText;
        } else if (error.status) {
          return "Error: " + error.status;
        } else {
          return error;
        }
      }

      return defaultError;
    },
    showApiResponseError(error: any, defaultError: any) {
      if (!this.axios.isCancel(error)) {
        // Only show the error if it is not a cancel
        const errorMsg = this.getApiResponseError(error, defaultError);
        this.$bus.emit(
          "show-general-error",
          errorMsg,
          this.getApiRequestId(error)
        );
      } else {
        console.log("Command cancelled: " + error.message);
      }
    },
    logApiResponseError(contentString: any, error: any, defaultError: any) {
      if (!this.axios.isCancel(error)) {
        // Only show the error if it is not a cancel
        const errorMsg = this.getApiResponseError(error, defaultError);
        console.log(
          contentString +
            ": " +
            errorMsg +
            " (" +
            this.getApiRequestId(error) +
            ")"
        );
      }
    },
    getApiRequestId(errorOrResponse: any) {
      try {
        if (errorOrResponse.response && errorOrResponse.response.data) {
          return errorOrResponse.response.data.request_id;
        } else if (errorOrResponse.data && errorOrResponse.data.request_id) {
          return errorOrResponse.data.request_id;
        }
      } catch (err) {
        // Ignore any errors
      }

      return null;
    },
    setCurrentUserId(userId: number) {
      this.$store.commit("updateUserId", userId);
    },
    getCurrentUserId() {
      return this.$store.getters.userId;
    },
    setCurrentClinicId(clinicId: number) {
      this.$store.commit("updateClinicId", clinicId);

      this.$bus.emit("current-clinic-id-updated");
    },
    getCurrentClinicId() {
      return this.$store.getters.clinicId;
    },
    clearAllSavedAuthInfoApi() {
      // Clear all the stored information
      this.$store.commit("updateUserId", null);
      this.$store.commit("updateUser", {});

      this.$store.commit("updateClinicId", null);
      this.$store.commit("updateClinic", {});
    },
    refreshCurrentUserInfoFromApi(successFn: any, errorFn: any) {
      const userId = this.getCurrentUserId();

      if (userId === null) {
        if (errorFn) {
          errorFn(this.$t("common.errorUserNotSpecified"));
        }
      }

      this.api
        .v1ClinicuserClinicuseridGet(userId)
        .then((response: any) => {
          // Make sure to transfer the groups over to the new user, these
          // are only available on login, so we need to keep track
          const oldUser = this.$store.getters.user;
          const updatedUser = response.data.clinicuser;
          if (oldUser && updatedUser) {
            updatedUser.groups = oldUser.groups;
          }

          this.$store.commit("updateUser", updatedUser);

          if (successFn) {
            successFn(response);
          }
        })
        .catch((error: any) => {
          if (errorFn) {
            errorFn(error);
          }
        });
    },
    getVersionInformation: function () {
      this.axios({
        method: "get",
        url: import.meta.env.VITE_VERSION_TRACKER_URL,
      }).then((response: any) => {
        this.$store.commit("updateRelease", response.data?.release);
        this.$store.commit("updateUdi", response.data?.udi);
        this.$store.commit("updateEnvironment", response.data?.environment);
        this.$store.commit("updateComponents", response.data?.components);
        this.$store.commit("updateFeatures", response.data?.features);
      });
    },
    getClinicInfo(
      clinicId: number,
      forceRefresh: any,
      successFn: any,
      errorFn: any
    ) {
      if (clinicId === null) {
        if (errorFn) {
          errorFn(this.$t("clinic.errorUnspecified"));
        }
      }

      let clinic = null;
      if (!forceRefresh) {
        clinic = this.$store.getters.clinic;

        if (clinic && clinic.id !== clinicId) {
          // Not a matching clinic, force a query
          clinic = null;
        }
      }

      if (clinic) {
        if (successFn) {
          successFn(clinic);
        }
      } else {
        // Query for the clinic information
        this.api
          .v1ClinicClinicidGet(clinicId)
          .then((response: any) => {
            // Save the clinic
            this.$store.commit("updateClinic", response.data.clinic);

            if (successFn) {
              successFn(response.data.clinic);
            }
          })
          .catch((error: any) => {
            if (errorFn) {
              errorFn(error);
            }
          });
      }
    },
    getClinicInfoPromise(
      clinicId: number,
      forceRefresh: boolean
    ): Promise<any> {
      return new Promise((resolve, reject) => {
        this.getClinicInfo(
          clinicId,
          forceRefresh,
          (clinic: any) => {
            resolve(clinic);
          },
          (error: any) => {
            reject(error);
          }
        );
      });
    },
    refreshCurrentClinicInfoFromApi(successFn: any, errorFn: any) {
      const clinicId = this.getCurrentClinicId();

      if (clinicId === null) {
        if (errorFn) {
          errorFn(this.$t("clinic.errorUnspecified"));
        }
      }

      this.api
        .v1ClinicClinicidGet(clinicId)
        .then((response: any) => {
          this.$store.commit("updateClinic", response.data.clinic);

          if (successFn) {
            successFn(response);
          }
        })
        .catch((error: any) => {
          if (errorFn) {
            errorFn(error);
          }
        });
    },
  },
});
